export const REGIOSN = ['SG', 'EU', 'AF', 'NA', 'SA', 'JP'];
export const SHOP = [
  {
    name: 'Amazon',
    code: 'AMZ',
  },
  {
    name: 'Cold Storage',
    code: 'CSG',
  },
  {
    name: 'Fair Price',
    code: 'FP',
  },
  {
    name: 'Giant',
    code: 'GT',
  },
  {
    name: 'Gurdian',
    code: 'GUR',
  },
  {
    name: 'Lazada',
    code: 'AMZ',
  },
  {
    name: 'Readmart',
    code: 'RDM',
  },
  {
    name: 'Pandamart',
    code: 'PDM',
  },
  {
    name: 'Qoo10',
    code: 'Q10',
  },
  {
    name: 'Qoo Life',
    code: 'QL',
  },
  {
    name: 'Shopee',
    code: 'SHP',
  },
  {
    name: 'Shopee Smart',
    code: 'SHS',
  },
  {
    name: 'Watson',
    code: 'WT',
  },
];

export const SLOTS = ['MORNING', 'EVENING', 'NIGHT'];
export const SEARCH_BASED_SHOP = ['FP', 'GT', 'CSG', 'GUR', 'PDM', 'WT'];
export const DARK_STORE_ENABLED_SHOPS = ['PDM', 'FP'];

export const DARK_STORES = {
  PDM: [
    { name: 'Punggol', identifier: 'https://www.foodpanda.sg/darkstore/b8ds/pandamart-punggol' },
    {
      name: 'Serangoon',
      identifier: 'https://www.foodpanda.sg/darkstore/d3nr/pandamart-serangoon',
    },
    { name: 'Amk', identifier: 'https://www.foodpanda.sg/darkstore/e8jn/pandamart-amk' },
    { name: 'Bedok', identifier: 'https://www.foodpanda.sg/darkstore/i1cb/pandamart-bedok' },
    { name: 'Mandai', identifier: 'https://www.foodpanda.sg/darkstore/n7no/pandamart-mandai' },
    {
      name: 'Woodlands-11',
      identifier: 'https://www.foodpanda.sg/darkstore/x1sy/pandamart-woodlands-11',
    },
    {
      name: 'Geminiastsims',
      identifier: 'https://www.foodpanda.sg/darkstore/x5dx/pandamart-geminiatsims',
    },
    { name: 'Yishun', identifier: 'https://www.foodpanda.sg/darkstore/x8yk/pandamart-yishun' },
    { name: 'Outram', identifier: 'https://www.foodpanda.sg/darkstore/y2xz/pandamart-outram' },
    { name: 'Tampines', identifier: 'https://www.foodpanda.sg/darkstore/y5qw/pandamart-tampines' },
    { name: 'Redhill', identifier: 'https://www.foodpanda.sg/darkstore/y9zp/pandamart-redhill' },
    { name: 'Whampoa', identifier: 'https://www.foodpanda.sg/darkstore/x1lt/pandamart-whampoa' },
    {
      name: 'Bukit Batok',
      identifier: 'https://www.foodpanda.sg/darkstore/x6qu/pandamart-bukit-batok',
    },
    { name: 'Boon Lay', identifier: 'https://www.foodpanda.sg/darkstore/wmgo/pandamart-boon-lay' },
  ],
  FP: [
    { name: 'Parkway Parade', identifier: '449269' },
    { name: 'Sports Hub', identifier: '397628' },
    { name: 'Nex Mall', identifier: '556083' },
    { name: 'Vivo City', identifier: '098585' },
    { name: 'Changi Business Park', identifier: '486018' },
    { name: 'Ang Mo Kio Hub', identifier: '569933' },
    { name: 'Hougang', identifier: '538692' },
    { name: 'Jurong Point', identifier: '648331' },
    { name: 'JEM', identifier: '608549' },
  ],
};
