import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { SIGN_IN } from '../../constants/routerUrls';

const ProtectedPage = ({ children }) => {
  const location = useLocation();

  const isAuth = useSelector((state) => state.auth.isAuth);

  if (!isAuth) {
    return <Navigate to={SIGN_IN} state={{ from: location.pathname }} />;
  } else {
    return children;
  }
};

export default ProtectedPage;
