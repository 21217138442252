import { FETCH_COMPANIES, PATCH_COMPANY, POST_COMPANY } from '../constants/apiUrls';
import { Delete, Get, Patch, Post } from './request';

export const fetchAllCompanies = async (query) => {
  const response = await Get(FETCH_COMPANIES, query);

  if (response) {
    return {
      totalEntries: response.total,
      companies: response.companies,
    };
  } else {
    return {
      totalEntries: 0,
      companies: [],
    };
  }
};

export const patchCompany = async (body) => {
  const { name, region, status, key, value, uid } = body;
  const response = await Patch(PATCH_COMPANY, { name, region, status, key, value, uid });
  return response;
};

export const addNewCompnay = async (body) => {
  const { name, region, shops } = body;

  const response = await Post(POST_COMPANY, { company: { name, region, shops } });

  return response;
};

export const addShopToCompany = async (companyUid, shops) => {
  const response = await Post(`/company/shop/${companyUid}`, { shops });
  return response;
};

export const removeShop = async (companyUid, shopCode) => {
  const response = await Delete(`/company/shop/${companyUid}/${shopCode}`);
  return response;
};

export const addShopToCompanyExtended = async ({ companyUid, darkStores, keywords, shopCode }) => {
  const response = await Post(`/company/shop/extended/${companyUid}`, {
    shop: { companyUid, darkStores, keywords, shopCode },
  });
  return response;
};
