import React from 'react';
import Page from '../../components/global/Page';
import { Box, CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CompanyStat from '../../components/dashboard/CompanyStat';
import AtpStat from '../../components/dashboard/AtpStat';
import { EventAvailable, FactCheck, Inventory } from '@mui/icons-material';
import BarChart from '../../components/dashboard/BarChart';
import PolarChart from '../../components/dashboard/PolarChart';

const Dashboard = () => {
  const company = useSelector((state) => state.company.selectedCompany);

  return (
    <Page>
      <Grid container width="100%" spacing={2} my="15px">
        <Grid item xs={12} md={3}>
          <Stack spacing={2}>
            <Paper sx={{ width: '100%', boxSizing: 'border-box', px: 2, py: 3 }}>
              {company ? <CompanyStat companyUid={company?.uid} /> : <CircularProgress />}
            </Paper>
            <Stack direction="row" flexWrap="wrap">
              <Box
                sx={{
                  bgcolor: '#272739',
                  color: 'white',
                  maxWidth: '100%',
                  flex: '1 0 21%',
                  margin: '5px 5px 5px 0',
                  px: 1,
                  py: 1,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'ponter',
                }}>
                <Inventory sx={{ mb: '5px' }} />
                <Typography>MasterList</Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: '#272739',
                  color: 'white',
                  maxWidth: '100%',
                  flex: '1 0 21%',
                  margin: '5px',
                  px: 1,
                  py: 1,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'ponter',
                }}>
                <EventAvailable sx={{ mb: '5px' }} />
                <Typography>Schedule</Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: '#272739',
                  color: 'white',
                  maxWidth: '100%',
                  flex: '1 0 21%',
                  margin: '5px',
                  px: 1,
                  py: 1,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'ponter',
                }}>
                <FactCheck sx={{ mb: '5px' }} />
                <Typography>OOS</Typography>
              </Box>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <Paper sx={{ width: '100%', boxSizing: 'border-box', px: 2, py: 3 }}>
            <AtpStat />
          </Paper>
          <Grid container spacing={2} sx={{ mt: '5px' }}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ width: '100%', boxSizing: 'border-box', px: 2, py: 3 }}>
                <BarChart />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ width: '100%', boxSizing: 'border-box', px: 2, py: 3 }}>
                <PolarChart />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Dashboard;
