import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Page from '../../components/global/Page';
import { useForm, useWatch } from 'react-hook-form';
import { fetchAllShops } from '../../api/shop';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { yupResolver } from '@hookform/resolvers/yup';
import { companyAddSchema } from '../../validation/company';
import { addNewCompnay } from '../../api/company';
import LoadingButton from '../../components/global/LoadingButton';
import Form from '../../components/global/Form';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddNewCompany = () => {
  const resolver = yupResolver(companyAddSchema);
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      region: 'SG',
      shops: null,
    },
    resolver,
    mode: 'onChange',
  });

  const region = useWatch({ control, name: 'region' });
  const name = useWatch({ control, name: 'name' });

  const [shopList, setShopList] = useState([]);
  const [selectedShops, setSelectedShops] = useState([]);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successAlertMessage, setSuccessAlertMessage] = useState('');

  const [isLoading, setIsloading] = useState(false);

  /**
   * Fetch Shop on region change
   */
  useEffect(() => {
    (async () => {
      const shops = await fetchAllShops({ region });
      setShopList(shops);
      setSelectedShops([]);
    })();
  }, [region]);

  const isShopsAdded = () => {
    if (selectedShops.length <= 0) {
      setError('shops', { type: 'required', message: 'Must add atleast one shop' });
      return false;
    }
    return true;
  };

  const handleSubmission = () => {
    setShowAlertDialog(true);
  };

  const createCompany = async (data) => {
    setIsloading(true);

    const shopsAdded = isShopsAdded();
    if (!shopsAdded) {
      setShowAlertDialog(false);
    } else {
      const response = await addNewCompnay({
        name: data.name,
        region: data.region,
        shops: selectedShops,
      });

      if (response) {
        reset({ name: '', shops: null, region: 'SG' });
        setSelectedShops([]);
        setSuccessAlertMessage(`${data.name} added successfully`);
        setShowSuccessAlert(true);
      } else {
        setShowAlertDialog(false);
      }
    }
    setShowAlertDialog(false);
    setIsloading(false);
  };

  return (
    <Page>
      {showSuccessAlert && (
        <Alert
          severity="success"
          onClose={() => setShowSuccessAlert(false)}
          sx={{ marginTop: '10px', boxShadow: 1 }}>
          <AlertTitle>Company Added</AlertTitle>
          {successAlertMessage}
        </Alert>
      )}
      <Dialog open={showAlertDialog}>
        <DialogTitle>Are You Sure?</DialogTitle>
        <DialogContent>Are sure you want to add {name}?</DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAlertDialog(false)}>No</Button>
          <Button onClick={handleSubmit(createCompany)}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Form>
        <Typography variant="h5" fontSize="20px" mb="15px">
          Add Details
        </Typography>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
          <TextField
            {...register('name')}
            error={errors?.name}
            sx={{ flexGrow: 1 }}
            helperText={errors?.name ? errors?.name?.message : ''}
            placeholder="Enter company name. ie - Reckitt"
            label="Company Name"
          />
          <FormControl sx={{ flexGrow: 1 }}>
            <InputLabel>Region</InputLabel>
            <Select
              error={errors?.region}
              helperText={errors?.region ? errors?.region?.message : ''}
              label="Region"
              placeholder="Singapore"
              {...register('region')}
              name="region"
              defaultValue="SG">
              <MenuItem value="SG">Singapore</MenuItem>
              <MenuItem value="EU">Europe</MenuItem>
              <MenuItem value="US">USA</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Box my="15px" />
        <Divider variant="middle" />
        <Box my="15px" />
        <Typography variant="h5" fontSize="20px" mb="15px">
          Add Shops
        </Typography>
        <Autocomplete
          multiple
          value={selectedShops}
          onChange={(event, value) => setSelectedShops(value)}
          options={shopList}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.shopCode === value.shopCode}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Shops"
              placeholder="Search Shops"
              error={errors?.shops && selectedShops.length <= 0}
              helperText={errors?.shops?.message}
            />
          )}
        />
        <Stack width="100%" justifyContent="flex-end" alignItems="flex-end">
          <Box width="max-content">
            <LoadingButton
              loading={isLoading}
              sx={{ marginTop: '20px' }}
              variant="contained"
              onClick={handleSubmission}>
              Add Company
            </LoadingButton>
          </Box>
        </Stack>
      </Form>
    </Page>
  );
};

export default AddNewCompany;
