import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { Cancel, Download, FileUpload } from '@mui/icons-material';
import { Box, Button, colors, IconButton, Stack, Typography } from '@mui/material';

import { generateAndDownloadCsv } from '../../../utils/csv';
import { uploadProductStockCsv } from '../../../api/product';
import { feedbackActions } from '../../../store/slice/feedbackReducer';

const CsvFileFileUpload = ({ toggleStep, saveParsedData, products }) => {
  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState(null);
  const company = useSelector((state) => state.company.selectedCompany);

  const onDropFIleHandle = useCallback((acceptedFiles) => setUploadedFile(acceptedFiles[0]), []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFIleHandle,
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
  });

  const previous = () => toggleStep(0);

  const next = async () => {
    const response = await uploadProductStockCsv({
      file: uploadedFile,
      companyUid: company.uid,
      fileName: uploadedFile.name,
    });

    if (response) {
      saveParsedData(response.products);
      toggleStep(1);
    } else {
      dispatch(feedbackActions.NOTIFY({ status: 'error', message: 'Failed to parse data' }));
    }
  };

  const downloadMasterlistCsv = () => {
    const rows = [
      ['Product Code', 'Product Name', 'Brand', 'Business Unit', 'Brand Code', 'Stock Quantity'],
    ];
    products?.map((product) => {
      const { productCode, name, brand, businessUnit, brandCode } = product;
      const row = [
        productCode?.replace(/,/g, ' ') || productCode,
        name?.replace(/,/g, ' ') || name,
        brand?.replace(/,/g, ' ') || brand,
        businessUnit?.replace(/,/g, ' ') || businessUnit,
        brandCode?.replace(/,/g, ' ') || brandCode,
        '',
      ];
      rows.push(row);
    });
    generateAndDownloadCsv(rows);
  };

  return (
    <Box width="100%" my="3rem">
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <Button
          variant="contained"
          endIcon={<Download />}
          onClick={downloadMasterlistCsv}
          sx={{ boxShadow: 0, width: 'auto', maxWidth: '250px' }}>
          Download Master List
        </Button>
        <Box
          py="20px"
          px="10px"
          width="auto"
          height="300px"
          display="flex"
          minWidth="550px"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          bgcolor={colors.grey[50]}
          sx={{ border: '1px dashed rgba(0,0,0,0.2)' }}
          {...getRootProps()}
          onDrop={(event) => {
            event.preventDefault();
          }}>
          {uploadedFile && (
            <Typography sx={{ mb: '10px', fontSize: '14px', fontWeight: 'bold' }}>
              {uploadedFile?.name}
              <IconButton onClick={() => setUploadedFile(null)}>
                <Cancel color="error" />
              </IconButton>
            </Typography>
          )}
          <input {...getInputProps()} />
          {!uploadedFile && (
            <FileUpload sx={{ width: '60px', height: '60px', fill: colors.blue[600] }} />
          )}
          {!uploadedFile && (
            <>
              <Typography>Drop your file here</Typography>
              <Typography>(Only *.csv file will be accepted)</Typography>
            </>
          )}
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ my: '20px' }}>
        <Button variant="contained" onClick={previous} sx={{ boxShadow: 0 }}>
          Previous
        </Button>
        <Button variant="contained" onClick={next} sx={{ boxShadow: 0 }} disabled={!uploadedFile}>
          Next
        </Button>
      </Stack>
    </Box>
  );
};

export default CsvFileFileUpload;
