import { Alert, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FEEDBACK_DURATION } from '../../constants/time';

const FeedbackNotification = () => {
  const [show, setShow] = useState(false);

  const feedback = useSelector((state) => state.feedback);

  useEffect(() => {
    if (feedback.time !== null) {
      setShow(true);
    }

    const interval = setInterval(() => setShow(false), FEEDBACK_DURATION + 15);
    return () => clearInterval(interval);
  }, [feedback.time]);

  return (
    <Snackbar
      sx={{ boxShadow: 5, borderRadius: 1 }}
      open={show}
      autoHideDuration={FEEDBACK_DURATION}
      onClose={() => setShow(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert onClose={() => setShow(false)} sx={{ width: '100%' }} severity={feedback.status}>
        {feedback?.message}
      </Alert>
    </Snackbar>
  );
};

export default FeedbackNotification;
