import * as yup from 'yup';
import { REGIOSN } from '../constants/constant';

export const shopAddSchema = yup.object().shape({
  name: yup.string().required('Must provide shop name'),
  shopCode: yup.string().required('Must select shop Code'),
  region: yup.string().oneOf(REGIOSN).required('Must select shop region'),
  baseUrl: yup.string().url('Not a valid url').required('Must provide market place link'),
});

export const CompanyShopSchema = yup.object().shape({
  keywords: yup.array().of(
    yup.object().shape({
      keyword: yup.string().required('Must provide a valid keyword'),
    }),
  ),
});
