import { Delete, Get, Post } from './request';

export const fetchScheduleForCompany = async (companyUid) => {
  const response = await Get(`/schedule/${companyUid}`);

  if (response.status === 200) {
    return response.schedules;
  } else {
    return [];
  }
};

export const addSchedule = async ({
  title,
  description,
  shop,
  products,
  slot,
  companyUid,
  keywords,
  darkStores,
}) => {
  const response = await Post('/schedule', {
    schedule: { title, description, shop, products, slot, companyUid, keywords, darkStores },
  });
  return response;
};

export const removeSchedule = async (scheduleUid) => {
  const response = await Delete(`/schedule/${scheduleUid}`);
  return response;
};

export const manuallyRunScrapper = async (scheduleUid) => {
  const response = await Post(`/schedule/${scheduleUid}`);
  return response;
};

export const manuallyRunAllScrapper = async (scheduleUids) => {
  const response = await Post('/schedule/all', { scheduleUids });
  return response;
};
