import { Stepper, Step, StepLabel } from '@mui/material';
import React, { useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import BasicInfoForm from './BasicInfoForm';
import MetaInfoForm from './MetaInfoForm';
import ShopInfoForm from './ShopInfoForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { masterListAddSchema } from '../../../validation/masterlist';
import Form from '../../global/Form';

const RenderSingleAddSteps = () => {
  const [activeStep, setActiveStep] = useState(0);

  const resolver = yupResolver(masterListAddSchema[activeStep]);

  const {
    control,
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver,
    mode: 'onChange',
    defaultValues: {
      listingStatus: 'LISTED',
      packageType: 'SINGLE',
      size: '',
      quantity: 0,
      shops: [],
      meta: [],
    },
  });

  const { fields, append, remove } = useFieldArray({ control, name: 'shops' });

  const {
    fields: metaFields,
    append: metaAppend,
    remove: metaRemove,
  } = useFieldArray({ control, name: 'meta' });

  const shopsWatch = useWatch({ control, name: 'shops' });
  const metaWatch = useWatch({ control, name: 'meta' });
  const listingStatus = useWatch({ control, name: 'listingStatus' });
  const packageType = useWatch({ control, name: 'packageType' });

  const steps = [
    {
      label: 'Basic Information',
      Element: (
        <BasicInfoForm
          toggleStep={setActiveStep}
          register={register}
          setFormValue={setValue}
          formErrors={errors}
          formSubmit={handleSubmit}
          watch={{ listingStatus, packageType }}
        />
      ),
    },

    {
      label: 'Shop Information',
      Element: (
        <ShopInfoForm
          toggleStep={setActiveStep}
          register={register}
          setFormValue={setValue}
          formErrors={errors}
          formSubmit={handleSubmit}
          formFields={fields}
          formAppend={append}
          formRemove={remove}
          watch={shopsWatch}
        />
      ),
    },
    {
      label: 'Meta Information',
      Element: (
        <MetaInfoForm
          toggleStep={setActiveStep}
          register={register}
          setFormValue={setValue}
          formErrors={errors}
          formSubmit={handleSubmit}
          formFields={metaFields}
          formAppend={metaAppend}
          formRemove={metaRemove}
          watch={metaWatch}
        />
      ),
    },
  ];

  return (
    <Form>
      <Stepper activeStep={activeStep}>
        {steps.map((step) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {steps[activeStep].Element}
    </Form>
  );
};

export default RenderSingleAddSteps;
