import { Get, Post } from './request';

export const signinUser = async (email, password) => {
  const response = await Post('/auth/sign-in', { email, password });
  if (response?.status === 200) {
    return {
      user: response.user,
      token: response.token,
    };
  } else {
    return null;
  }
};

export const logout = async () => {
  const response = await Post('/auth/logout');
  if (response.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const addNewUser = async ({ name, email, password, rePassword }) => {
  const response = await Post('/auth/add-user', { name, email, password, rePassword });
  if (response.status === 200) {
    return response;
  } else {
    return null;
  }
};

export const fetchAllAdmins = async () => {
  const response = await Get('/auth');
  if (response.status === 200) {
    return response.admins;
  } else {
    return [];
  }
};
