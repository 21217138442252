import { Autocomplete, Box, Button, Checkbox, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { fetchAllProducts } from '../../api/product';
import BaseTable from '../global/BaseTable';
import { GridToolbar } from '@mui/x-data-grid';
import { DARK_STORE_ENABLED_SHOPS, SEARCH_BASED_SHOP } from '../../constants/constant';
import { fetchKewordAndDarkStoreData } from '../../api/shop';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const ProductSelection = ({ toggleStep, control, setFormValue, formErrors, submit }) => {
  const [productList, setProductList] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [loading, setLoading] = useState(false);

  const shop = useWatch({ control, name: 'shop' });
  const company = useSelector((state) => state.company.selectedCompany);
  const selectedProducts = useWatch({ control, name: 'products' });
  const [keywordList, setKeywordList] = useState([]);
  const [darkStoreList, setDarkStoreList] = useState([]);

  const getDarkStoreAndKeywordOptions = async () => {
    const response = await fetchKewordAndDarkStoreData(company.uid, shop.shopCode);

    if (response.darkStores) {
      setDarkStoreList(response.darkStores);
    }

    if (response.keywords) {
      setKeywordList(response.keywords);
    }
  };

  useEffect(() => {
    (async () => await getDarkStoreAndKeywordOptions())();
  }, [company, shop]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await fetchAllProducts(company.uid, shop.shopCode);
      setProductList(response.products);
      setLoading(false);
    })();
  }, [company, shop]);

  const columns = [
    {
      field: 'productCode',
      headerName: 'Product Code',
      width: 120,
    },
    {
      field: 'name',
      headerName: 'Product Name',
      flex: 1,
    },
    {
      field: 'sku',
      flex: 1,
      headerName: 'SKU',
    },
    {
      field: 'link',
      headerName: 'Link',
      flex: 1,
    },
  ];

  const handleSelection = (ids) => {
    const seletedIds = new Set(ids);
    const selectedItems = productList.filter((item) => seletedIds.has(item.productCode));
    if (selectedItems?.length > 0) {
      setSelectionModel(ids);
      setFormValue('products', selectedItems);
    } else {
      setSelectionModel([]);
      setFormValue('products', null);
    }
  };

  useEffect(() => {
    if (selectedProducts) {
      const ids = selectedProducts?.map((item) => item.productCode);
      setSelectionModel(ids);
    }
  }, []);

  const showKeywordSelectionForm = () => {
    const shopCode = shop?.shopCode?.split('-')?.[0];
    if (SEARCH_BASED_SHOP.includes(shopCode)) {
      return true;
    }
    return false;
  };

  const showDarkStoreSelectionForm = () => {
    const shopCode = shop?.shopCode?.split('-')?.[0];
    if (DARK_STORE_ENABLED_SHOPS.includes(shopCode)) {
      return true;
    }
    return false;
  };

  const handleNext = () => toggleStep(2);

  const keywords = useWatch({ control, name: 'keywords' });
  const darkStores = useWatch({ control, name: 'darkStores' });

  return (
    <Box>
      {showKeywordSelectionForm() && (
        <Box>
          <Typography sx={{ my: 2 }} variant="h5" fontWeight={800}>
            Select Keywords
          </Typography>
          <Autocomplete
            disableCloseOnSelect
            multiple
            value={keywords}
            onChange={(event, value) => setFormValue('keywords', value)}
            options={keywordList}
            getOptionLabel={(option) => option.keyword}
            isOptionEqualToValue={(option, value) => option.uid === value.uid}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.keyword}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Add more stores" sx={{ maxWidth: '600px' }} />
            )}
          />
        </Box>
      )}
      {showDarkStoreSelectionForm() && (
        <Box>
          <Typography sx={{ my: 2 }} variant="h5" fontWeight={800}>
            Select Stores
          </Typography>
          <Autocomplete
            disableCloseOnSelect
            multiple
            value={darkStores}
            onChange={(event, value) => setFormValue('darkStores', value)}
            options={darkStoreList}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.uid === value.uid}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Add more stores" sx={{ maxWidth: '600px' }} />
            )}
          />
        </Box>
      )}

      <Typography sx={{ my: 2 }} variant="h5" fontWeight={800}>
        Select Products
      </Typography>
      {formErrors?.products && (
        <Typography color="red" fontSize="14px">
          {formErrors?.products?.message}
        </Typography>
      )}
      <Box height="500px" mt="15px">
        {productList && (
          <BaseTable
            getRowId={(row) => row.productCode}
            columns={columns}
            rows={productList}
            disableSelectionOnClick
            checkboxSelection={true}
            onSelectionModelChange={handleSelection}
            components={{ Pagination: null, Toolbar: GridToolbar }}
            selectionModel={selectionModel}
            density="compact"
            loading={loading}
          />
        )}
      </Box>
      <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
        <Button onClick={() => toggleStep(0)} variant="contained">
          Previous
        </Button>
        <Button onClick={submit(handleNext)} variant="contained">
          Next
        </Button>
      </Stack>
    </Box>
  );
};

export default ProductSelection;
