import { Box } from '@mui/material';
import React from 'react';

const Form = ({ children, ...rest }) => {
  return (
    <Box
      {...rest}
      my="20px"
      width="100%"
      bgcolor="white"
      height="auto"
      minHeight="78vh"
      boxSizing="border-box"
      px="5vw"
      py="30px"
      borderRadius={2}>
      {children}
    </Box>
  );
};

export default Form;
