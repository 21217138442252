import { combineReducers } from 'redux';
import authReducer from './authReducer';
import companyReducer from './companyReducer';
import feedbackReducer from './feedbackReducer';
import shopReducer from './shopReducer';
import userReducer from './userReducer';

const reducers = combineReducers({
  auth: authReducer,
  feedback: feedbackReducer,
  company: companyReducer,
  user: userReducer,
  shop: shopReducer,
});

export default reducers;
