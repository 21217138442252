import { createSlice } from '@reduxjs/toolkit';
import { NOTIFY } from '../actions/types';

const initialState = {
  status: '',
  message: '',
  time: null,
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    [NOTIFY]: (state, action) => {
      state.message = action.payload.message;
      state.status = action.payload.status;
      state.time = new Date();
    },
  },
});

export const feedbackActions = feedbackSlice.actions;
export default feedbackSlice.reducer;
