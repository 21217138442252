import { colors, createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    // eslint-disable-next-line quotes
    fontFamily: "'Encode Sans', sans-serif",
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ':root': {
          '& .MuiStepIcon-text': { color: colors.green[500] },
          '& .Mui-completed .MuiStepIcon-root': { color: colors.green[500] },
          '& .Mui-disabled .MuiStepIcon-root': { color: colors.grey[800] },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: ['none'],
          borderRadius: 3,
        },
      },
    },
  },
});
