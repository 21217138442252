export const masterlistFilterColumns = [
  {
    field: 'productCode',
    label: 'Product Code',
    placeholder: 'Search by product code',
    type: 'text',
  },
  {
    field: 'name',
    label: 'Product Name',
    placeholder: 'Search by product name',
    type: 'text',
  },
  {
    field: 'brand',
    shouldKeep: false,
    label: 'Brand',
    placeholder: 'Select brand name',
    type: 'multiSelect',
    valueOptionsName: 'brands',
  },
  {
    field: 'businessUnit',
    shouldKeep: false,
    label: 'Business Unit',
    placeholder: 'Select business unit',
    type: 'multiSelect',
    valueOptionsName: 'businessUnit',
  },
  {
    field: 'category',
    shouldKeep: false,
    label: 'Category',
    placeholder: 'Select category',
    type: 'multiSelect',
    valueOptionsName: 'categories',
  },
  {
    field: 'listingStatus',
    shouldKeep: false,
    label: 'Listing Status',
    placeholder: 'Select listing status',
    type: 'singleSelect',
    valueOptionsName: 'listingStatuses',
  },
];
