import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedPage from '../components/global/ProtectedPage';
import { routes } from '../constants/routes';
import PrivateLayout from '../layouts/PrivateLayout';
import PublicLayout from '../layouts/PublicLayout';
import NotFound from '../pages/global/NotFound';

const RootRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => {
          const { path, Element, isProtected, isIndexUrl } = route;
          if (isProtected) {
            return (
              <Route element={<PrivateLayout />} key={path}>
                <Route
                  index={isIndexUrl}
                  path={path}
                  element={
                    <ProtectedPage>
                      <Element />
                    </ProtectedPage>
                  }
                />
              </Route>
            );
          } else {
            return (
              <Route element={<PublicLayout />} key={path}>
                <Route index={isIndexUrl} path={path} element={<Element />} />
              </Route>
            );
          }
        })}
        <Route element={<PublicLayout />}>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RootRoute;
