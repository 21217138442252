import React from 'react';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, colors, Stack, Tab, Tabs } from '@mui/material';

import Page from '../../components/global/Page';
import Dropdown from '../../components/global/DropDown';
import GlobalFilter from '../../components/global/GlobalFilter';
import AllProductTable from '../../components/master-list/AllProductTable';
import SingleShopMasterListData from '../../components/master-list/SingleShopMasterListData';

import { fetchCompanyShops } from '../../api/shop';
import { fetchAllProducts, fetchUniqueSearchFilters } from '../../api/product';

import { masterlistFilterColumns } from '../../constants/filterColumns';
import { ADD_BULK_PRODUCT, ADD_SINGLE_PRODUCT, UPDATE_STOCK } from '../../constants/routerUrls';

const RenderMasterListTable = ({ activeShop, allProductList, loading }) => {
  if (!activeShop) {
    return <AllProductTable products={allProductList} loading={loading} />;
  }
  return <SingleShopMasterListData products={allProductList} loading={loading} />;
};

const MasterList = () => {
  const navigate = useNavigate();
  const [shopList, setShopList] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [activeShop, setActiveShop] = React.useState(null);
  const [allProductList, setAllProductList] = React.useState([]);
  const [filteredProductList, setFilteredProductList] = React.useState([]);

  const [filterOptions, setFilterOptions] = React.useState(null);

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  // * Fetch Shops for selected company
  const company = useSelector((state) => state.company.selectedCompany);

  React.useEffect(() => {
    (async () => {
      const shops = await fetchCompanyShops({ companyUid: company.uid });
      setShopList(shops);
    })();
  }, [company]);

  // * Fetch product by shop
  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await fetchAllProducts(company.uid, activeShop);
      setAllProductList(response.products);
      setFilteredProductList(response.products);
      setLoading(false);
    })();
  }, [activeShop, company]);

  const menuOptions = [
    {
      label: 'Add Single Product',
      callback: () => navigate(ADD_SINGLE_PRODUCT),
    },
    {
      label: 'Add Bulk Product',
      callback: () => navigate(ADD_BULK_PRODUCT),
    },
  ];

  React.useEffect(() => {
    (async () => {
      const uniqueFilterValues = await fetchUniqueSearchFilters(company.uid);
      setFilterOptions({ ...uniqueFilterValues, listingStatuses: ['LISTED', 'DELISTED'] });
    })();
  }, []);

  return (
    <Page>
      <Box my="25px" mx="auto" width="100%">
        {/* add-button */}
        <Stack
          spacing={1}
          width="100%"
          height="100%"
          direction="row"
          alignItems="center"
          justifyContent="flex-end">
          <Stack direction="row" spacing={2}>
            {!activeShop && (
              <Button variant="contained" onClick={() => navigate(UPDATE_STOCK)}>
                Update stock
              </Button>
            )}
            <Dropdown
              rightIcon
              variant="contained"
              options={menuOptions}
              title={'Add New Product'}
            />
          </Stack>
        </Stack>
        <Box flexGrow={1} sx={{ bgcolor: 'white', my: '15px', borderRadius: 2 }}>
          {filterOptions && (
            <GlobalFilter
              filters={masterlistFilterColumns}
              name="masterlistFilter"
              valueOptions={filterOptions}
              data={allProductList}
              updateData={setFilteredProductList}
            />
          )}
        </Box>
      </Box>

      {/* data-grid */}
      <Box
        mt={2}
        width="100%"
        height="auto"
        bgcolor="white"
        minHeight="60vh"
        overflow="hidden"
        borderRadius="10px">
        <Tabs
          scrollButtons
          value={activeTab}
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleTabChange}
          sx={{ bgcolor: colors.blue[50] }}>
          <Tab label="All" onClick={() => setActiveShop(null)} />
          {shopList.map((item) => (
            <Tab
              key={item.shopCode}
              label={`${item.name} - (${item.region})`}
              onClick={() => setActiveShop(item.shopCode)}
            />
          ))}
        </Tabs>
        <Box width="100%" height="auto" minHeight="60vh" bgcolor="white" borderRadius="10px">
          <RenderMasterListTable
            loading={loading}
            activeShop={activeShop}
            allProductList={filteredProductList}
          />
        </Box>
      </Box>
    </Page>
  );
};

export default MasterList;
