import * as yup from 'yup';
import { keyFormat } from '../constants/regex';

yup.addMethod(yup.array, 'unique', function (message) {
  return this.test('unique', message, function (list) {
    const shopCodes = [];
    list.map((item) => {
      if (!shopCodes.includes(item.shopCode)) {
        shopCodes.push(item.shopCode);
      }
    });
    return list.length === shopCodes.length;
  });
});

export const masterListAddSchema = [
  // * Basic info form validation
  yup.object().shape({
    name: yup.string().required('Must provide product name'),
    brand: yup.string().required('Must provide product brand'),
    brandCode: yup.string().required('Must provide product brand code'),
    businessUnit: yup.string(),
    category: yup.string().required('Must provide product category'),
    variant: yup.string().required('Must provide product variant'),
    size: yup.string().required('Must provide product size'),
    packageQuantity: yup
      .number()
      .positive()
      .typeError('Must provide a positive number')
      .required('Must provide product size'),
    listingStatus: yup
      .string()
      .oneOf(['LISTED', 'DELISTED'])
      .required('Must select a listing status'),
    packageType: yup
      .string()
      .oneOf(['SINGLE', 'BUNDLE', 'CARTOON'])
      .required('Must select a package type'),
    barcode: yup.string(),
  }),

  // * shop info form validation
  yup.object().shape({
    shops: yup
      .array()
      .of(
        yup.object().shape({
          sku: yup.string().required('Must provide product sku'),
        }),
      )
      .required(),
  }),

  yup.object().shape({
    meta: yup.array().of(
      yup.object().shape({
        fieldType: yup
          .string()
          .oneOf(['TEXT', 'NUMBER', 'LARGE TEXT'])
          .required('Must select value type'),
        identifier: yup
          .string()
          .matches(keyFormat, { message: 'Only character from a-z, A-Z' })
          .required('Must provide value name'),
        value: yup.string().required('Must provide value'),
      }),
    ),
  }),
];

export const bulkUploadSchema = yup.object().shape({
  shops: yup.array(),
  metas: yup.array().of(
    yup.object().shape({
      identifier: yup
        .string()
        .matches(keyFormat, { message: 'Only character from a-z, A-Z' })
        .required('Must provide value name'),
    }),
  ),
});

export const masterlistShopAddSchema = yup.object().shape({
  shops: yup
    .array()
    .of(
      yup.object().shape({
        shopCode: yup.string().required('Must select a shop'),
        sku: yup.string().required('Must enter product sku, ie - 1825820'),
      }),
    )
    .unique('Duplicate Shops selected'),
});
