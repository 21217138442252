import * as yup from 'yup';
import { email, password } from '../constants/regex';

yup.addMethod(yup.mixed, 'sameAs', function (ref, message) {
  return this.test('sameAs', message, function (value) {
    let other = this.resolve(ref);

    return !other || !value || value === other;
  });
});

export const signinValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Must enter your email')
    .matches(email, { message: 'Not company email' }),
  password: yup.string().required('Must enter password'),
});

export const userCreationSchema = yup.object().shape({
  name: yup.string().required('Must enter your name'),
  email: yup
    .string()
    .required('Must enter your email')
    .matches(email, { message: 'Not company email' }),
  password: yup
    .string()
    .required('Must enter password')
    .min(8, 'must be at least 8 characters long')
    .matches(password, 'must contain uppercase, lowercase and numbers'),
  rePassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords do not match')
    .required('Must enter password again to confirm'),
});
