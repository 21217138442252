import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const Dropdown = ({ options = [], title, rightIcon, variant }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant={variant || 'text'}
        disableElevation
        onClick={handleClick}
        endIcon={rightIcon ? open ? <KeyboardArrowUp /> : <KeyboardArrowDown /> : null}>
        {title}
      </Button>
      <Menu
        sx={{ marginTop: '5px' }}
        width="max-content"
        height="max-content"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {options.length > 0 ? (
          options.map((option) => (
            <MenuItem
              onClick={() => {
                const { callback } = option;
                callback();
                handleClose();
              }}
              key={option.label}>
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem onClick={handleClose}>Nothing To Show</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default Dropdown;
