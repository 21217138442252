import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { CheckBox, CheckBoxOutlineBlank, Save, Schedule } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import Dropdown from '../global/DropDown';
import DaySelector from '../global/DaySelector';
import BasicDatePicker from '../global/BasicDatePicker';
import ThemeSelector from '../global/ThemeSelector';
import { cardColors } from '../../constants/colors';
import { ReportBasicInfoSchema } from '../../validation/trackingReport';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchAllShops } from '../../api/shop';
import { REPORT_OVERVIEW } from '../../constants/routerUrls';

const ReportBasicInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [shopList, setShopList] = useState([]);
  const [endsOn, setEndsOn] = useState('NEVER');
  const [endDate, setEndDate] = useState(new Date());
  const [selectedDays, setSelectedDays] = useState([]);
  const [theme, setTheme] = useState(cardColors.grey);
  const [repeatType, setRepeatType] = useState('DO NOT REPEAT');
  const company = useSelector((state) => state.company.selectedCompany);

  useEffect(() => {
    (async () => {
      const shops = await fetchAllShops({ region: company.region });
      setShopList(shops);
    })();
  }, [company]);

  const resolver = yupResolver(ReportBasicInfoSchema);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver,
  });

  const shops = useWatch({ control, name: 'shops' });

  const slots = [
    {
      label: 'Morning 7:00 AM - 1:00 PM',
      value: 'MORNING',
    },
    {
      label: 'Evening 2:00 PM - 10:00 PM',
      value: 'EVENING',
    },
    {
      label: 'Night 11:00 PM - 6:00 AM',
      value: 'NIGHT',
    },
  ];

  const repeatOptions = [
    {
      label: 'Do not repeat',
      callback: () => setRepeatType('DO NOT REPEAT'),
    },
    {
      label: 'Everyday',
      callback: () => setRepeatType('EVERYDAY'),
    },
    {
      label: 'Custom...',
      callback: () => setRepeatType('CUSTOM'),
    },
  ];

  const endingOptions = [
    {
      label: 'Never',
      callback: () => setEndsOn('NEVER'),
    },
    {
      label: 'Custom...',
      callback: () => setEndsOn('CUSTOM'),
    },
  ];

  const reportThemes = Object.keys(cardColors).map((key) => cardColors[key]);

  const onSubmit = (data) => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
      navigate(REPORT_OVERVIEW, {
        state: {
          from: location.pathname,
          data: {
            ...data,
            theme,
            endsOn,
            endDate,
            repeatType,
            selectedDays,
            shops: data?.shops?.map((shop) => ({ ...shop, isConfigured: false })),
          },
        },
      });
    }, 3500);
  };

  const handleShopSelection = (event, value) => {
    const shops = value?.map((shop) => ({ name: shop.name, shopCode: shop.shopCode }));
    setValue('shops', shops);
  };

  return (
    <Box component="form" width="100%" onSubmit={handleSubmit(onSubmit)}>
      <Stack width="100%" spacing={2}>
        <Stack spacing={2} direction={{ lg: 'row', md: 'column', sm: 'column', xs: 'column' }}>
          <FormControl sx={{ flex: 1 }} error={errors?.title?.message}>
            <InputLabel>Title</InputLabel>
            <OutlinedInput
              label="Title"
              placeholder={`example: Daily Online Avalibility ${new Date().getFullYear()}`}
              {...register('title')}
            />
            <FormHelperText>{errors?.title?.message}</FormHelperText>
          </FormControl>
          <FormControl sx={{ flex: 1 }} error={errors?.description?.message}>
            <InputLabel>Description</InputLabel>
            <OutlinedInput
              label="Description"
              placeholder="describe what this report means"
              {...register('description')}
            />
            <FormHelperText>{errors?.description?.message}</FormHelperText>
          </FormControl>
          <FormControl sx={{ flex: 1 }} error={errors?.type?.message}>
            <InputLabel>Report Type</InputLabel>
            <Select label="Report Type" {...register('type')}>
              <MenuItem value={'COMPLIANCE'}>Compliance</MenuItem>
              <MenuItem value={'OTHER'}>Other</MenuItem>
            </Select>
            <FormHelperText>{errors?.type?.message}</FormHelperText>
          </FormControl>
          <FormControl sx={{ flex: 1, minWidth: 300 }} error={errors?.session?.message}>
            <InputLabel>Session</InputLabel>
            <Select
              label="Session"
              {...register('session')}
              renderValue={(selected) => (
                <Stack direction="row" spacing={1}>
                  <Schedule />
                  <Typography>{slots.find((slot) => slot.value === selected)?.label}</Typography>
                </Stack>
              )}>
              {slots.map((slot) => (
                <MenuItem key={slot.value} value={slot.value}>
                  <ListItem>
                    <ListItemIcon>
                      <Schedule />
                    </ListItemIcon>
                    <ListItemText>{slot.label}</ListItemText>
                  </ListItem>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors?.session?.message}</FormHelperText>
          </FormControl>
        </Stack>

        <Stack>
          <FormControl sx={{ flex: 0.33 }} error={errors?.shops?.message}>
            <Autocomplete
              multiple
              value={shops}
              options={shopList}
              disableCloseOnSelect
              onChange={handleShopSelection}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.shopCode === value.shopCode}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  label="Select Shops"
                  {...params}
                  placeholder="Search Shops"
                  error={errors?.shops?.message}
                />
              )}
            />
            {errors?.shops && <FormHelperText>{errors?.shops?.message}</FormHelperText>}
          </FormControl>
        </Stack>

        <Stack spacing={1} direction="column" alignItems="flex-start">
          <Typography fontWeight={600}>Repeats</Typography>
          <Dropdown title={repeatType} rightIcon variant="outlined" options={repeatOptions} />
          {repeatType === 'CUSTOM' && (
            <DaySelector selection={selectedDays} setSelection={setSelectedDays} />
          )}
        </Stack>
        <Stack spacing={1} direction="column" alignItems="flex-start">
          <Typography fontWeight={600}>Ends on</Typography>
          <Dropdown title={endsOn} rightIcon variant="outlined" options={endingOptions} />
          {endsOn === 'CUSTOM' && (
            <Box sx={{ pt: '10px' }}>
              <BasicDatePicker value={endDate} setValue={setEndDate} />
            </Box>
          )}
        </Stack>
        <Stack spacing={1} direction="column" alignItems="flex-start">
          <Typography fontWeight={600}>Theme</Typography>
          <ThemeSelector scheme={reportThemes} theme={theme} setTheme={setTheme} />
        </Stack>
      </Stack>
      <Stack mt={4} alignItems="flex-end">
        <LoadingButton
          type="submit"
          loading={loader}
          endIcon={<Save />}
          variant="contained"
          loadingPosition="end">
          Save
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default ReportBasicInfo;
