import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import Page from '../../components/global/Page';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userCreationSchema } from '../../validation/auth';
import { addNewUser, fetchAllAdmins } from '../../api/auth';
import { useDispatch } from 'react-redux';
import { feedbackActions } from '../../store/slice/feedbackReducer';
import { userActions } from '../../store/slice/userReducer';
import BaseTable from '../../components/global/BaseTable';
import SubmitButton from '../../components/global/SubmitButton';
import * as date from '../../utils/date';
import { GridToolbar } from '@mui/x-data-grid';
import NoDataOverlay from '../../components/global/NoDataOverlay';

const UserList = () => {
  const resolver = yupResolver(userCreationSchema);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver,
    mode: 'onChange',
    defaultValues: { name: '', email: '', password: '', rePassword: '' },
  });
  const [users, setUsers] = React.useState([]);
  const [promiseRequest, setPromiseRequest] = React.useState(null);

  const [showUserAddDialog, setShowUserAddDialog] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const dispatch = useDispatch();

  const closeUserAddDialog = () => setShowUserAddDialog(false);
  const openUserAddDialog = () => setShowUserAddDialog(true);

  const addUser = React.useCallback(
    (data) => new Promise((resolve, reject) => setPromiseRequest({ data, resolve, reject })),
    [],
  );

  const handleNoPress = () => {
    const { reject, data } = promiseRequest;
    reject(data);
    setPromiseRequest(null);
  };

  const handleYesPress = async () => {
    const { data, resolve, reject } = promiseRequest;
    const { email, name, password, rePassword } = data;
    const isUserCreated = await addNewUser({ email, name, password, rePassword });
    if (isUserCreated) {
      resolve(data);
      dispatch(userActions.addNew({ user: data }));
      dispatch(
        feedbackActions.NOTIFY({ status: 'success', message: `${name} added to user list` }),
      );
      setPromiseRequest(null);
      reset({ name: '', email: '', password: '', rePassword: '' });
      setShowUserAddDialog(false);
      setUsers([...users, { email, name, createdAt: new Date() }]);
    } else {
      reject(data);
      setPromiseRequest(null);
      dispatch(feedbackActions.NOTIFY({ status: 'error', message: 'User was not created' }));
    }
  };

  const fetchData = async () => {
    const response = await fetchAllAdmins();
    setUsers(response);
  };

  React.useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Added On',
      flex: 1,
      valueGetter: (params) => date.renderFormat(params.row.createdAt),
    },
  ];
  const CustomToolBar = () => {
    return (
      <Stack
        justifyContent="space-between"
        direction="row"
        sx={{ px: 1, py: 1, boxSizing: 'border-box' }}>
        <OutlinedInput placeholder="Search" />
        <GridToolbar />
      </Stack>
    );
  };

  return (
    <Page>
      <Dialog fullWidth={true} open={showUserAddDialog} onClose={closeUserAddDialog} maxWidth="xs">
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <Stack width="100%" direction="column" spacing={2}>
            <Typography>Add new user to access Pulse Manager</Typography>
            <FormControl fullWidth required error={!!errors?.name}>
              <InputLabel>Name</InputLabel>
              <OutlinedInput
                placeholder="Name, ie - Abir Rahman"
                label="Name"
                type="text"
                name="name"
                {...register('name')}
              />
              <FormHelperText>{errors?.name?.message}</FormHelperText>
            </FormControl>
            <FormControl fullWidth required error={!!errors?.email}>
              <InputLabel>Email</InputLabel>
              <OutlinedInput
                placeholder="Email, ie - abir@manush.tech"
                label="Email"
                type="email"
                name="email"
                {...register('email')}
              />
              <FormHelperText>{errors?.email?.message}</FormHelperText>
            </FormControl>
            <FormControl fullWidth required error={!!errors?.password}>
              <InputLabel>Password</InputLabel>
              <OutlinedInput
                placeholder="Password, ie - ghsdfqjewd"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                name="password"
                {...register('password')}
              />
              <FormHelperText>{errors?.password?.message}</FormHelperText>
            </FormControl>
            <FormControl fullWidth required error={!!errors?.rePassword}>
              <InputLabel>Confirm Password</InputLabel>
              <OutlinedInput
                placeholder="Confirm Password, ie - ghsdfqjewd"
                label="Confirm Password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                name="rePassword"
                {...register('rePassword')}
              />
              <FormHelperText>{errors?.rePassword?.message}</FormHelperText>
            </FormControl>
            <SubmitButton variant="contained" onClick={handleSubmit(addUser)}>
              Add User
            </SubmitButton>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        open={promiseRequest}
        onClose={() => {
          setPromiseRequest(null);
        }}>
        <DialogTitle>Are You Sure?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to add {promiseRequest?.data?.name}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNoPress}>NO</Button>
          <Button onClick={handleYesPress}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Box my="25px" mx="auto" width="100%" borderRadius={1}>
        <Stack
          width="100%"
          height="100%"
          direction="row"
          alignItems="center"
          justifyContent="flex-end">
          <Stack direction="row" spacing={2}>
            <Button onClick={openUserAddDialog} variant="contained">
              ADD NEW
            </Button>
          </Stack>
        </Stack>
      </Box>

      <Box width="100%" height="550px" bgcolor="#fff" mt="15px" borderRadius="10px">
        <BaseTable
          rows={users}
          columns={columns}
          getRowId={(row) => row.email}
          components={{
            Toolbar: CustomToolBar,
            NoResultsOverlay: NoDataOverlay,
            NoRowsOverlay: NoDataOverlay,
          }}
        />
      </Box>
    </Page>
  );
};

export default UserList;
