import React from 'react';
import { Box } from '@mui/system';
import Page from '../../components/global/Page';
import ReportBasicInfo from '../../components/tracking-report/ReportBasicInfo';

const CreateNew = () => {
  return (
    <Page>
      <Box
        sx={{
          width: '90%',
          padding: '5vw',
          minWidth: '360px',
          borderRadius: '10px',
          backgroundColor: 'white',
          margin: '2vh auto 0px auto',
        }}>
        <ReportBasicInfo />
      </Box>
    </Page>
  );
};

export default CreateNew;
