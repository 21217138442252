import React, { useEffect } from 'react';
import Page from '../../components/global/Page';
import {
  Box,
  Stack,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  FormHelperText,
  DialogActions,
} from '@mui/material';
import { GridRowModes, GridToolbar } from '@mui/x-data-grid';
import { addNewShop, fetchAllShops, updateShopInfo } from '../../api/shop';
import * as date from '../../utils/date';
import BaseTable from '../../components/global/BaseTable';
import SubmitButton from '../../components/global/SubmitButton';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { shopAddSchema } from '../../validation/shop';
import { REGIOSN, SHOP } from '../../constants/constant';
import { useDispatch, useSelector } from 'react-redux';
import { feedbackActions } from '../../store/slice/feedbackReducer';
import { shopActions } from '../../store/slice/shopReducer';
import NoDataOverlay from '../../components/global/NoDataOverlay';

const ShopList = () => {
  const shops = useSelector((state) => state.shop.shops);

  const [showShopAddDialog, setShowShopAddDialog] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [dataPatchPromise, setDataPatchPromise] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const resolver = yupResolver(shopAddSchema);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      region: '',
      baseUrl: '',
      shopCode: '',
    },
    mode: 'onChange',
    resolver,
  });

  const fetchShops = async () => {
    setLoading(true);
    const response = await fetchAllShops();
    dispatch(shopActions.fetchShop({ shops: response }));
    setLoading(false);
  };

  React.useEffect(() => {
    (async () => await fetchShops())();
  }, []);

  const columns = [
    {
      field: 'shopCode',
      headerName: 'Shop Code',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      editable: true,
    },
    {
      field: 'region',
      headerName: 'Region',
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['SG', 'EU', 'US'],
    },
    {
      field: 'baseUrl',
      headerName: 'Base Url',
      flex: 1,
      renderCell: (params) => (
        <Link href={params.value} target="_blank">
          {params.value}
        </Link>
      ),
      editable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Added on',
      flex: 1,
      renderCell: (params) => `${date.renderFormat(params.value)}`,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated On',
      flex: 1,
      renderCell: (params) => `${date.renderFormat(params.value)}`,
    },
  ];

  const addShop = async (shop) => {
    const { shopCode, name, region, baseUrl } = shop;
    const response = await addNewShop({ shopCode, name, region, baseUrl });
    if (response) {
      dispatch(
        shopActions.addShop({
          id: Date.now(),
          name,
          shopCode,
          region,
          baseUrl,
          createdAt: new Date(),
          updatedAt: new Date(),
        }),
      );
      setShowShopAddDialog(false);
      dispatch(feedbackActions.NOTIFY({ status: 'success', message: 'Shop added' }));
    } else {
      setShowShopAddDialog(false);
      dispatch(
        feedbackActions.NOTIFY({
          status: 'error',
          message: 'Failed to add shop or shop already been added',
        }),
      );
    }
  };

  const shopCode = useWatch({ control, name: 'shopCode' });

  useEffect(() => {
    const shop = SHOP.find((item) => item.code === shopCode);
    setValue('name', shop?.name);
  }, [shopCode]);

  const checkDif = (oldValue, newValue) =>
    oldValue.name !== newValue.name ||
    oldValue.region !== newValue.region ||
    oldValue.baseUrl !== newValue.baseUrl;

  const validate = (item) =>
    item.name.length > 0 && item.region.length > 0 && item.baseUrl.length > 0;

  const updateRow = React.useCallback(
    (newValue, oldValue) =>
      new Promise((resolve, reject) => {
        if (checkDif(oldValue, newValue) && validate(newValue)) {
          setDataPatchPromise({ resolve, reject, newValue, oldValue });
        } else {
          resolve(oldValue);
        }
      }),
    [],
  );

  const handleDataPatchNoPress = async () => {
    const { oldValue, resolve } = dataPatchPromise;

    resolve(oldValue);
    setDataPatchPromise(null);
  };

  const handleDataPatchYesPress = async () => {
    const { newValue, oldValue, reject, resolve } = dataPatchPromise;
    const response = await updateShopInfo(newValue);
    if (response) {
      resolve({ ...newValue, updatedAt: new Date() });
      setDataPatchPromise(null);
      dispatch(feedbackActions.NOTIFY({ status: 'success', message: 'Changes saved' }));
    } else {
      reject(oldValue);
      setDataPatchPromise(null);
    }
  };
  const CustomToolBar = () => {
    return (
      <Stack
        justifyContent="space-between"
        direction="row"
        sx={{ px: 1, py: 1, boxSizing: 'border-box' }}>
        <OutlinedInput placeholder="Search" />
        <GridToolbar />
      </Stack>
    );
  };

  return (
    <Page>
      <Dialog open={!!dataPatchPromise}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>Are your sure want save your changes?</DialogContent>
        <DialogActions>
          <Button onClick={handleDataPatchNoPress}>No</Button>
          <SubmitButton onClick={handleDataPatchYesPress} variant="contained" autoFocus>
            YES
          </SubmitButton>
        </DialogActions>
      </Dialog>
      <Dialog open={showShopAddDialog} fullWidth onClose={() => setShowShopAddDialog(false)}>
        <DialogTitle>Add New Shop</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: '15px' }}>
            <FormControl required error={!!errors?.shopCode}>
              <InputLabel>Shop Code</InputLabel>
              <Select label="Shop Code" placeholder="Select shop code" {...register('shopCode')}>
                {SHOP.map((item) => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {errors?.shopCode && <FormHelperText>{errors?.shopCode?.message}</FormHelperText>}
            </FormControl>
            <FormControl required error={!!errors?.region}>
              <InputLabel>Region</InputLabel>
              <Select label="Region" placeholder="Select shop region" {...register('region')}>
                {REGIOSN.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              {errors?.region && <FormHelperText>{errors?.region?.message}</FormHelperText>}
            </FormControl>
            <FormControl required error={!!errors?.baseUrl}>
              <InputLabel>Base Url</InputLabel>
              <OutlinedInput
                label="Base Url"
                placeholder="Provide url for the market place"
                {...register('baseUrl')}
              />
              {errors?.baseUrl && <FormHelperText>{errors?.baseUrl?.message}</FormHelperText>}
            </FormControl>
            <SubmitButton variant="contained" onClick={handleSubmit(addShop)}>
              Add shop
            </SubmitButton>
          </Stack>
        </DialogContent>
      </Dialog>
      <Box my="25px" mx="auto" width="100%" borderRadius={1}>
        <Stack
          width="100%"
          height="100%"
          direction="row"
          alignItems="center"
          justifyContent="flex-end">
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={() => setShowShopAddDialog(true)}>
              ADD NEW
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Box width="100%" height="550px" bgcolor="#fff" borderRadius="10px">
        <BaseTable
          rows={shops}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={updateRow}
          onProcessRowUpdateError={() => {
            dispatch(
              feedbackActions.NOTIFY({ status: 'error', message: 'Failed to update product info' }),
            );
          }}
          rowModesModel={rowModesModel}
          onRowEditStart={(e) => {
            setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.Edit });
          }}
          onRowEditStop={(e) => {
            setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.View });
          }}
          onRowEditCommit={(e) => {
            setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.View });
          }}
          loading={loading}
          components={{
            NoResultsOverlay: NoDataOverlay,
            NoRowsOverlay: NoDataOverlay,
            Toolbar: CustomToolBar,
          }}
        />
      </Box>
    </Page>
  );
};

export default ShopList;
