import { Get, Patch, Post, PostWithFileUpload } from './request';

export const addSingleProduct = async (product) => {
  const {
    name,
    brand,
    businessUnit,
    category,
    listingStatus,
    meta,
    variant,
    size,
    packageType,
    barcode,
    companyUid,
    shops,
    brandCode,
  } = product;
  const response = await Post('/product', {
    product: {
      name,
      brand,
      businessUnit,
      category,
      listingStatus,
      meta,
      variant,
      size,
      packageType,
      barcode,
      companyUid,
      shops,
      brandCode,
    },
  });
  return response;
};

export const uploadBulkProductCsv = async ({ file, fileName, uploadEvent, companyUid }) => {
  try {
    const formdata = new FormData();
    formdata.append('raw', file, fileName);
    formdata.append('companyUid', companyUid);
    formdata.append('parseFor', 'MASTERLIST');
    const respone = await PostWithFileUpload('/product/parse', formdata, uploadEvent);

    return respone;
  } catch (error) {
    console.error(error);
  }
};

export const uploadProductStockCsv = async ({ file, fileName, uploadEvent, companyUid }) => {
  try {
    const formdata = new FormData();
    formdata.append('raw', file, fileName);
    formdata.append('companyUid', companyUid);
    formdata.append('parseFor', 'STOCKINFO');
    const respone = await PostWithFileUpload('/product/parse', formdata, uploadEvent);

    return respone;
  } catch (error) {
    console.error(error);
  }
};

export const addBulkProduct = async (products) => {
  const response = await Post('/product/bulk', { products });
  return response;
};

export const fetchAllProducts = async (companyUid, shopCode) => {
  const response = await Get(`/product/${companyUid}`, { shopCode });
  return response;
};

export const updateProductInfo = async (product) => {
  const response = await Patch('/product', { ...product });
  return response;
};

export const updateProductShopInfo = async (shop) => {
  const response = await Patch('/product/shop', { ...shop });
  return response;
};

export const updateProductAndShopInfo = async (product) => {
  const response = await Patch('/product/extended', { ...product });
  return response;
};

export const addShopsToProduct = async (companyUid, productCode, shops) => {
  const response = await Post(`/product/shop/${companyUid}/${productCode}`, { shops });
  return response;
};
export const fetchUniqueSearchFilters = async (companyUid) => {
  const response = await Get(`/product/filters/options/${companyUid}`);
  if (response) {
    return {
      brands: response.brands,
      categories: response.categories,
      businessUnit: response.businessUnit,
    };
  } else {
    return null;
  }
};

export const updateProductStock = async (products) => {
  const response = await Patch('/product/update-stock', { products });
  return response;
};
