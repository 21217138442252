/* eslint-disable react/react-in-jsx-scope */
import {
  Business,
  Dashboard,
  EventAvailable,
  FactCheck,
  Inventory,
  Shop,
  SupervisedUserCircle,
  Paid,
  ContentPasteSearch,
} from '@mui/icons-material';
import { colors } from './colors';
import {
  COMPANY,
  COMPANY_SHOPS,
  DASHBOARD,
  MASTER_LIST,
  SCHEDULER,
  SHOP,
  STOCK_STATUS,
  TRACKING_REPORTS,
  USERS,
} from './routerUrls';

export const sidebarItems = [
  {
    label: 'Users',
    icon: <SupervisedUserCircle style={{ fill: colors.white }} />,
    path: USERS,
  },
  {
    label: 'Companies',
    icon: <Business style={{ fill: colors.white }} />,
    path: COMPANY,
  },
  {
    label: 'Shops',
    icon: <Shop style={{ fill: colors.white }} />,
    path: SHOP,
  },
];

export const companySpeceficItems = [
  {
    label: 'Dashboard',
    icon: (isActive) => {
      if (isActive) {
        return <Dashboard style={{ fill: colors.white }} />;
      } else {
        return <Dashboard style={{ fill: '#fafafa' }} />;
      }
    },
    path: DASHBOARD,
  },
  {
    label: 'Master List',
    icon: (isActive) => {
      if (isActive) {
        return <Inventory style={{ fill: colors.white }} />;
      } else {
        return <Inventory style={{ fill: '#fafafa' }} />;
      }
    },
    path: MASTER_LIST,
  },
  {
    label: 'Shops',
    icon: (isActive) => {
      if (isActive) {
        return <Shop style={{ fill: '#fafafa' }} />;
      } else {
        return <Shop style={{ fill: '#fafafa' }} />;
      }
    },
    path: COMPANY_SHOPS,
    isDisabled: false,
  },
  {
    label: 'Tracking Reports',
    icon: (isActive) => {
      if (isActive) {
        return <ContentPasteSearch style={{ fill: colors.white }} />;
      } else {
        return <ContentPasteSearch style={{ fill: '#fafafa' }} />;
      }
    },
    path: TRACKING_REPORTS,
    isDisabled: false,
  },
  {
    label: 'Stock Status',
    icon: (isActive) => {
      if (isActive) {
        return <FactCheck style={{ fill: colors.white }} />;
      } else {
        return <FactCheck style={{ fill: '#fafafa' }} />;
      }
    },
    path: STOCK_STATUS,
    isDisabled: false,
  },
  {
    label: 'Scheduler',
    icon: (isActive) => {
      if (isActive) {
        return <EventAvailable style={{ fill: colors.white }} />;
      } else {
        return <EventAvailable style={{ fill: '#fafafa' }} />;
      }
    },
    path: SCHEDULER,
  },
  {
    label: 'GMV',
    icon: (isActive) => {
      if (isActive) {
        return <Paid style={{ fill: '#fafafa' }} />;
      } else {
        return <Paid style={{ fill: '#fafafa' }} />;
      }
    },
    path: '/',
    isDisabled: true,
  },
];
