import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  colors,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { fetchAllShops } from '../../../api/shop';
import { useWatch } from 'react-hook-form';
import { CancelRounded } from '@mui/icons-material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ShopMetaInfo = ({
  toggleStep,
  setValue,
  formSubmit,
  control,
  formFields,
  formAppend,
  formRemove,
  formErrors,
  register,
}) => {
  const company = useSelector((state) => state.company.selectedCompany);

  const [shopList, setShopList] = useState([]);

  useEffect(() => {
    (async () => {
      const shops = await fetchAllShops({ region: company.region });
      setShopList(shops);
    })();
  }, [company]);

  const metas = useWatch({ control, name: 'metas' });
  const shops = useWatch({ control, name: 'shops' });

  const handleShopSelection = (event, value) => {
    const shops = value?.map((shop) => ({ name: shop.name, shopCode: shop.shopCode }));

    setValue('shops', shops);
  };

  const next = () => {
    toggleStep(1);
  };

  const appendMetaField = () => formAppend({ identifier: '' });
  return (
    <Box width="100%" my="3rem">
      <Alert severity="info" variant="outlined" sx={{ mb: '20px' }}>
        Please configure shops and any additional meta fields you want to add to your csv (optional)
      </Alert>
      <Typography variant="h5" fontWeight="bold" sx={{ mb: '10px' }}>
        Add Shops
      </Typography>
      <Divider />
      <Stack sx={{ my: '20px' }}>
        <FormControl>
          <Autocomplete
            sx={{ width: '100%', maxWidth: '550px' }}
            multiple
            value={shops}
            onChange={handleShopSelection}
            options={shopList}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.shopCode === value.shopCode}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField label="Select Shops" {...params} placeholder="Search Shops" />
            )}
          />
          {formErrors?.shops && <FormHelperText>{formErrors?.shops?.message}</FormHelperText>}
        </FormControl>
      </Stack>
      <Typography variant="h5" fontWeight="bold" sx={{ mb: '10px' }}>
        Add Meta Information
      </Typography>
      <Divider />
      <Box mb="20px" />
      <Stack sx={{ my: '10px' }}>
        {formFields?.map((field, index) => (
          <Stack key={index} direction="row" alignItems="center" spacing={2} sx={{ mb: '15px' }}>
            <Typography>{index + 1} .</Typography>
            <FormControl required error={!!formErrors?.metas?.[index]?.identifier}>
              <InputLabel>Identifier</InputLabel>
              <OutlinedInput
                label="Identifier"
                placeholder="Identifier meta field"
                {...register(`metas.${index}.identifier`)}
              />
              {formErrors?.metas?.[index]?.identifier && (
                <FormHelperText>{formErrors?.metas?.[index]?.identifier?.message}</FormHelperText>
              )}
            </FormControl>
            <IconButton sx={{ height: 'max-content' }} onClick={() => formRemove(index)}>
              <CancelRounded />
            </IconButton>
          </Stack>
        ))}
      </Stack>
      <Button variant="contained" sx={{ bgcolor: colors.grey[600] }} onClick={appendMetaField}>
        {metas?.length > 0 ? 'Add More' : 'Add Meta information'}
      </Button>

      <Stack direction="row" justifyContent="flex-end" sx={{ my: '20px' }} spacing={2}>
        <Button disabled variant="contained">
          Previous
        </Button>
        <Button variant="contained" onClick={formSubmit(next)}>
          {shops.length > 0 || formFields?.length > 0 ? 'Next' : 'Skip'}
        </Button>
      </Stack>
    </Box>
  );
};

export default ShopMetaInfo;
