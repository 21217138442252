import React, { useState, useEffect } from 'react';
import { Box, Button, Card, Chip, Divider, IconButton, Stack, Typography } from '@mui/material';

import { ArrowRight, Circle, PushPin, PushPinOutlined, Settings } from '@mui/icons-material';
import CustomTooltip from '../global/Tooltip';
import { isEllipsisActive } from '../../utils/dom';
import { grey } from '@mui/material/colors';
import { renderFormat } from '../../utils/date';
import { cardColors, colors } from '../../constants/colors';

const ReportCard = ({
  title = 'Report Title',
  shortDescription = 'This a sample report. If this gets too long then it will be truncated automatically.',
  onPreviewCallback,
}) => {
  const [pinned, setPinned] = useState(false);
  const [titleTooltip, setTitleTooltip] = useState(false);
  const [shortDescriptionTooltip, setShortDescriptionTooltip] = useState(false);

  useEffect(() => {
    setTitleTooltip(isEllipsisActive(document.getElementById('report-card-title')));
    setShortDescriptionTooltip(
      isEllipsisActive(document.getElementById('report-card-short-description')),
    );
  }, []);

  return (
    <Card
      sx={{
        width: '100%',
        height: '280px',
        maxWidth: '320px',
        marginRight: '10px',
        marginBottom: '10px',
        position: 'relative',
      }}>
      {/* Bottom panel */}
      <Stack
        p={1}
        width="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ position: 'absolute', bottom: '0px', right: '0px' }}>
        <Stack>
          <Chip label="compliance" size="small" />
        </Stack>
        <Button onClick={onPreviewCallback} sx={{ color: cardColors.blue.canvas }}>
          View Details
        </Button>
      </Stack>
      {/* Header */}
      <Stack
        p={1}
        width="100%"
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          color: cardColors.blue.text,
          backgroundColor: cardColors.blue.canvas,
        }}>
        <Box p={1}>
          <CustomTooltip title={titleTooltip ? title : ''} followCursor>
            <Typography
              width="200px"
              fontWeight="500"
              overflow="hidden"
              fontSize="1.2rem"
              whiteSpace="nowrap"
              id="report-card-title"
              textOverflow="ellipsis">
              {title}
            </Typography>
          </CustomTooltip>
          <CustomTooltip title={shortDescriptionTooltip ? shortDescription : ''} followCursor>
            <Typography
              mt="-5px"
              width="240px"
              fontSize=".8rem"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              id="report-card-short-description">
              {shortDescription}
            </Typography>
          </CustomTooltip>
        </Box>
        <Box>
          <CustomTooltip title={pinned ? 'unpin' : 'pin to top'} followCursor>
            <IconButton
              size="small"
              onClick={() => setPinned(!pinned)}
              sx={{ color: cardColors.blue.text }}>
              {pinned ? <PushPin /> : <PushPinOutlined />}
            </IconButton>
          </CustomTooltip>
        </Box>
      </Stack>
      <Divider />

      <Box pl={2} pt="3px">
        <Chip size="small" label="running . . ." variant="outline" icon={<Settings />} />
      </Box>

      <Stack p={1} spacing={0}>
        <Typography ml={1} fontSize="0.8rem" color={grey[700]} fontWeight="500">
          Latest activity
        </Typography>
        <Stack pl={1} pt={1} flexDirection="row" alignItems="center">
          <ArrowRight />
          <Typography fontSize="0.8rem" fontWeight="500">
            {renderFormat(new Date())}
          </Typography>
        </Stack>
        <Stack pl={3} pr={2} flexDirection="row" alignItems="center" color={colors.success}>
          <Circle sx={{ width: '8px' }} />
          <Typography
            ml={1}
            overflow="hidden"
            fontSize="0.8rem"
            whiteSpace="nowrap"
            textOverflow="ellipsis">
            Fairprice-SG morning report is ready!
          </Typography>
        </Stack>
        <Stack pl={3} pr={2} flexDirection="row" alignItems="center" color={colors.error}>
          <Circle sx={{ width: '8px' }} />
          <Typography
            ml={1}
            overflow="hidden"
            fontSize="0.8rem"
            whiteSpace="nowrap"
            textOverflow="ellipsis">
            Failed to process Amazon-SG morning report!
          </Typography>
        </Stack>
        <Typography px={3} fontSize="0.8rem" fontWeight="500">
          . . . and more
        </Typography>
      </Stack>
    </Card>
  );
};

export default ReportCard;
