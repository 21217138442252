import { BASE_SHOP } from '../constants/apiUrls';
import { Delete, Get, Patch, Post } from './request';

export const fetchCompanyShops = async (query) => {
  const response = await Get(`${BASE_SHOP}${query.companyUid}`);
  if (response) {
    return response.shops;
  } else {
    return [];
  }
};

export const fetchAllShops = async (query) => {
  const response = await Get(`${BASE_SHOP}`, { query });
  if (response) {
    return response.shops;
  } else {
    return [];
  }
};

export const fetchKewordAndDarkStoreData = async (companyUid, shopCode) => {
  const response = await Get(`${BASE_SHOP}meta/${companyUid}/${shopCode}`);
  if (response) {
    return {
      keywords: response.keywords,
      darkStores: response.darkStores,
    };
  } else {
    return null;
  }
};

export const fetchShopWhichDoesNotBelongToCompany = async (companyUid) => {
  const response = await Get(`${BASE_SHOP}un-assigned/${companyUid}`);
  if (response) {
    return response.shops;
  } else {
    return [];
  }
};

export const addNewShop = async ({ shopCode, name, region, baseUrl }) => {
  const response = await Post(`${BASE_SHOP}`, { shop: { shopCode, name, region, baseUrl } });
  return response;
};

export const updateShopInfo = async ({ shopCode, name, region, baseUrl }) => {
  const response = await Patch(`${BASE_SHOP}`, { shop: { shopCode, name, region, baseUrl } });
  return response;
};

export const removeKeyword = async (uid) => {
  const response = await Delete(`${BASE_SHOP}keyword/${uid}`);
  return response;
};

export const removeDarkStore = async (uid) => {
  const response = await Delete(`${BASE_SHOP}darkstore/${uid}`);
  return response;
};

export const updateKeywordAndDarkStore = async ({ companyUid, shopCode, darkStores, keywords }) => {
  const response = await Patch(`${BASE_SHOP}meta`, {
    shop: {
      companyUid,
      shopCode,
      darkStores,
      keywords,
    },
  });
  return response;
};
