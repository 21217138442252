import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import SubmitButton from './SubmitButton';

const AlertModal = ({
  title,
  isOpen,
  content,
  rejectText,
  resolveText,
  onRejectClick,
  onResolveClick,
}) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onRejectClick}>{rejectText || 'No'}</Button>
        <SubmitButton onClick={onResolveClick} variant="contained" autoFocus>
          {resolveText || 'YES'}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
