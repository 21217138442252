import { Get, Patch } from './request';

export const getScrapeData = async (query) => {
  const response = await Get('/scrape-data', query);
  if (response.status === 200) {
    return response;
  } else {
    return null;
  }
};

export const updateScrapeData = async (data) => {
  const response = await Patch('/scrape-data', data);
  if (response.status === 200) {
    return response;
  } else {
    return null;
  }
};
