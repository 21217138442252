import { Check, Delete, Error } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { GridRowModes, GridToolbar } from '@mui/x-data-grid';
import React from 'react';
import BaseTable from '../../global/BaseTable';
import { addBulkProduct } from '../../../api/product';
import { useDispatch } from 'react-redux';
import { feedbackActions } from '../../../store/slice/feedbackReducer';
import CustomTooltip from '../../global/Tooltip';

const ValidateRawData = ({ rawData, saveParsedData, toggleStep, saveErroredData }) => {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(20);
  const [selectedData, setSelectedData] = React.useState([]);
  const [selectedProductShops, setSelectedProductShops] = React.useState([]);
  const [erroredData, setErroredData] = React.useState([]);
  const [showDataErrorDialog, setShowDataErrorDialog] = React.useState(false);
  const [showSubmitDialog, setShowSubmitDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  const visualListingStatusValidation = (params) => {
    if (params.value === 'LISTED' || params.value === 'DELISTED') {
      return <Chip color="success" icon={<Check />} label={params.value} size="small" />;
    } else {
      return <Chip color="error" icon={<Error />} label={params.value} size="small" />;
    }
  };

  const visualPackageTypeValidation = (params) => {
    if (params.value === 'SINGLE' || params.value === 'BUNDLE' || params.value === 'CARTOON') {
      return <Chip color="success" icon={<Check />} label={params.value} size="small" />;
    } else {
      return <Chip color="error" icon={<Error />} label={params.value} size="small" />;
    }
  };

  const visualPackageQuantityValidation = (params) => {
    const number = parseInt(params.value);

    if (!isNaN(number)) {
      return <Chip color="success" icon={<Check />} label={params.value} size="small" />;
    } else {
      return <Chip color="error" icon={<Error />} label={params.value} size="small" />;
    }
  };

  const getColumns = () => {
    const columns = [
      { field: 'productCode', width: 150, headerName: 'Product Code', hide: true },
      { field: 'name', width: 250, headerName: 'Product Name', editable: true },
      { field: 'brand', headerName: 'Brand', width: 150, editable: true },
      { field: 'businessUnit', headerName: 'Business Unit', width: 150, editable: true },
      { field: 'brandCode', headerName: 'Brand Code', width: 150, editable: true },
      { field: 'category', headerName: 'Category', width: 150, editable: true },
      { field: 'variant', headerName: 'Variant', width: 150, editable: true },
      { field: 'size', headerName: 'Size', width: 150, editable: true },
      {
        field: 'packageQuantity',
        headerName: 'Package Quantity',
        width: 150,
        editable: true,
        type: 'number',
        renderCell: visualPackageQuantityValidation,
      },
      {
        field: 'listingStatus',
        headerName: 'Listing Status',
        width: 150,
        editable: true,
        renderCell: visualListingStatusValidation,
        type: 'singleSelect',
        valueOptions: ['LISTED', 'DELISTED'],
      },
      {
        field: 'packageType',
        headerName: 'Package Type',
        width: 150,
        editable: true,
        renderCell: visualPackageTypeValidation,
        type: 'singleSelect',
        valueOptions: ['SINGLE', 'CARTOON', 'BUNDLE'],
      },
      { field: 'barcode', headerName: 'Barcode', width: 150, editable: true },
      {
        field: 'shops',
        headerName: 'Shops',
        width: 200,
        renderCell: (params) => (
          <Button onClick={() => setSelectedProductShops(params.value)}>
            View Shops ({params?.value?.length})
          </Button>
        ),
      },
    ];

    return columns;
  };

  const ToolBar = () => {
    return (
      <Stack direction="row" justifyContent="space-between" sx={{ my: '20px', px: '20px' }}>
        {selectedData.length > 0 && (
          <Button
            endIcon={<Delete />}
            variant="contained"
            color="error"
            onClick={() => {
              const unselectedData = rawData.filter(
                (product) => !selectedData.includes(product.productCode),
              );
              saveParsedData(unselectedData);
            }}>
            Delete Selected
          </Button>
        )}
        <GridToolbar />
      </Stack>
    );
  };

  const previous = () => toggleStep(1);

  const next = () => {
    const errors = validateData();
    if (errors.length > 0) {
      setShowDataErrorDialog(true);
    } else {
      setShowSubmitDialog(true);
    }
  };

  const [rowModesModel, setRowModesModel] = React.useState({});

  const validateData = () => {
    let errorData = [];

    const pktoptions = ['SINGLE', 'BUNDLE', 'CARTOON'];
    const listingoptions = ['LISTED', 'DELISTED'];

    rawData.map((item) => {
      const { name, brand, businessUnit, brandCode, packageQuantity, listingStatus, packageType } =
        item;

      if (
        name.length <= 0 ||
        brand.length <= 0 ||
        businessUnit.length <= 0 ||
        brandCode.length <= 0
      ) {
        errorData.push(item);
      } else if (isNaN(parseInt(packageQuantity))) {
        errorData.push(item);
      } else if (!listingoptions.includes(listingStatus)) {
        errorData.push(item);
      } else if (!pktoptions.includes(packageType)) {
        errorData.push(item);
      }
    });

    setErroredData(errorData);

    return errorData;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const response = await addBulkProduct(rawData);

    if (!response) {
      dispatch(feedbackActions.NOTIFY({ status: 'error', message: 'Server crashed' }));
    } else {
      saveErroredData(response.erroredData);
      toggleStep(3);
    }
    setLoading(false);
  };

  return (
    <Box width="100%" my="3rem">
      <Dialog
        open={showDataErrorDialog}
        onClose={() => {
          setShowDataErrorDialog(false);
          setErroredData([]);
        }}
        fullWidth>
        <DialogTitle>Some data are not correctly formated</DialogTitle>
        <DialogContent>
          <Typography>Product with this Product code has errors</Typography>
          {erroredData?.map((item, index) => (
            <Typography key={item.productCode} sx={{ mb: '5px' }}>
              {index + 1}. {item.productCode} - {item.name}
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setErroredData([]);
              setShowDataErrorDialog(false);
            }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showSubmitDialog}
        onClose={() => {
          setShowSubmitDialog(false);
        }}
        fullWidth>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to add these products</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowSubmitDialog(false);
            }}>
            No
          </Button>
          <Button onClick={handleSubmit}>YES</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={selectedProductShops.length > 0}
        onClose={() => setSelectedProductShops([])}
        fullWidth>
        <DialogTitle>Shops</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Shop Code</TableCell>
                <TableCell align="left">SKU</TableCell>
                <TableCell align="left">Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProductShops?.map((item) => (
                <TableRow key={item.shopCode}>
                  <TableCell>{item.shopCode}</TableCell>
                  <TableCell align="left">{item.sku}</TableCell>
                  <TableCell align="left">
                    <CustomTooltip title={item.link}>
                      <Box>
                        <Button components="a" href={item.link} target="_blank">
                          Click Me
                        </Button>
                      </Box>
                    </CustomTooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedProductShops([])}>Close</Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h5" fontWeight="bold" sx={{ mb: '10px' }}>
        Check Parsed Data
      </Typography>
      <Divider />
      <Box mb="20px" />
      <Box height="550px" border="1px solid rgba(0,0,0,0.2)" borderRadius={2}>
        {rawData ? (
          <BaseTable
            checkboxSelection
            rows={rawData}
            getRowId={(row) => row?.productCode}
            columns={getColumns()}
            rowCount={rawData.langth}
            page={page}
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
            onPageSizeChange={(pageSize) => setPageSize(pageSize)}
            rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
            components={{
              Toolbar: ToolBar,
            }}
            processRowUpdate={(newVal, oldVal) => {
              if (oldVal !== newVal) {
                const editedData = rawData.map((item) =>
                  item.productCode === newVal.productCode
                    ? { ...newVal, id: newVal.productCode }
                    : item,
                );
                saveParsedData(editedData);
                return newVal;
              } else {
                return oldVal;
              }
            }}
            onProcessRowUpdateError={() => {
              dispatch(feedbackActions.NOTIFY({ status: 'error', message: 'Faile to update' }));
            }}
            onSelectionModelChange={(ids) => setSelectedData(ids)}
            experimentalFeatures={{ newEditingApi: true }}
            rowModesModel={rowModesModel}
            onRowEditStart={(e) => {
              setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.Edit });
            }}
            onRowEditStop={(e) => {
              setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.View });
            }}
            onRowEditCommit={(e) => {
              setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.View });
            }}
          />
        ) : (
          <Alert severity="error">CSV File could not be extracted</Alert>
        )}
      </Box>

      <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ my: '20px' }}>
        <Button variant="contained" onClick={previous} sx={{ boxShadow: 0 }}>
          Previous
        </Button>
        <Button variant="contained" onClick={next} sx={{ boxShadow: 0 }}>
          {loading ? <CircularProgress /> : 'Next'}
        </Button>
      </Stack>
    </Box>
  );
};

export default ValidateRawData;
