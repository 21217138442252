import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import RootRoute from './routes/RootRoute';
import { persistor, store } from './store';

const Root = () => {
  return (
    <PersistGate persistor={persistor} loading={null}>
      <Provider store={store}>
        <RootRoute />
      </Provider>
    </PersistGate>
  );
};

export default Root;
