import { Cancel, Download, FileUpload } from '@mui/icons-material';
import { Box, Button, colors, IconButton, Stack, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { uploadBulkProductCsv } from '../../../api/product';
import { feedbackActions } from '../../../store/slice/feedbackReducer';
import { generateAndDownloadCsv } from '../../../utils/csv';
import { useDropzone } from 'react-dropzone';

const CsvFileFileUpload = ({ toggleStep, control, saveParsedData }) => {
  const company = useSelector((state) => state.company.selectedCompany);
  const dispatch = useDispatch();

  const onDropFIleHandle = useCallback((acceptedFiles) => setUploadedFile(acceptedFiles[0]), []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFIleHandle,
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
  });

  const metas = useWatch({ control, name: 'metas' });
  const shops = useWatch({ control, name: 'shops' });

  const [uploadedFile, setUploadedFile] = useState(null);

  const previous = () => toggleStep(0);

  const next = async () => {
    const response = await uploadBulkProductCsv({
      file: uploadedFile,
      fileName: uploadedFile.name,
      companyUid: company.uid,
    });

    if (response) {
      saveParsedData(response.products);
      toggleStep(2);
    } else {
      dispatch(feedbackActions.NOTIFY({ status: 'error', message: 'Failed to parse data' }));
    }
  };

  const generateSampleCsv = () => {
    const rows = [
      [
        'Name',
        'Brand',
        'Business Unit',
        'Category',
        'Listing Status',
        'Variant',
        'Size',
        'Package Type',
        'Barcode',
        'Package Quantity',
        'Brand Code',
      ],
    ];
    shops?.map((shop) => {
      const { name, shopCode } = shop;

      rows[0].push(`${name}-${shopCode}-sku`);
      rows[0].push(`${name}-${shopCode}-link`);
    });

    metas?.map((meta) => rows[0].push(meta.identifier));

    generateAndDownloadCsv(rows);
  };

  return (
    <Box width="100%" my="3rem">
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <Button
          onClick={generateSampleCsv}
          variant="contained"
          sx={{ boxShadow: 0, width: 'auto', maxWidth: '250px' }}
          endIcon={<Download />}>
          Download Sample CSV
        </Button>
        <Box
          width="auto"
          minWidth="550px"
          height="300px"
          bgcolor={colors.grey[50]}
          sx={{ border: '1px dashed rgba(0,0,0,0.2)' }}
          px="10px"
          py="20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          onDrop={(event) => {
            event.preventDefault();
          }}
          {...getRootProps()}>
          {uploadedFile && (
            <Typography sx={{ mb: '10px', fontSize: '14px', fontWeight: 'bold' }}>
              {uploadedFile?.name}
              <IconButton onClick={() => setUploadedFile(null)}>
                <Cancel color="error" />
              </IconButton>
            </Typography>
          )}
          <input {...getInputProps()} />
          {!uploadedFile && (
            <FileUpload sx={{ width: '60px', height: '60px', fill: colors.blue[600] }} />
          )}
          {!uploadedFile && (
            <>
              <Typography>Drop your file here</Typography>
              <Typography>(Only *.csv file will be accepted)</Typography>
            </>
          )}
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ my: '20px' }}>
        <Button variant="contained" onClick={previous} sx={{ boxShadow: 0 }}>
          Previous
        </Button>
        <Button variant="contained" onClick={next} sx={{ boxShadow: 0 }} disabled={!uploadedFile}>
          Next
        </Button>
      </Stack>
    </Box>
  );
};

export default CsvFileFileUpload;
