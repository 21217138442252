import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { colors } from '../../constants/colors';
import { ChevronLeft } from '@mui/icons-material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { companySpeceficItems, sidebarItems } from '../../constants/sidebarItems';
import CustomTooltip from './Tooltip';
import { drawerWidth } from '../../constants/mixins';
import { useSelector } from 'react-redux';
import { Menu as MenuIcon } from '@mui/icons-material';

const openedMixin = (theme) => ({
  backgroundColor: colors.black,
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  backgroundColor: colors.black,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

/**
 * custom drawer header
 */
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Sidebar = ({ setOpen, open }) => {
  const drawerToggle = () => setOpen(!open);
  const navigate = useNavigate();
  const location = useLocation();
  const company = useSelector((state) => state.company.selectedCompany);

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={drawerToggle}>
          {open ? (
            <ChevronLeft style={{ color: colors.white }} />
          ) : (
            <MenuIcon style={{ color: colors.white }} />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List sx={{ flexGrow: 1 }}>
        {companySpeceficItems.map((item) => (
          <CustomTooltip key={item.path} title={open ? '' : item.label} placement="right">
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                disabled={item.isDisabled}
                onClick={() => {
                  if (company) {
                    navigate(item.path, { state: { from: location.pathname } });
                  }
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  {item.icon(company ? true : false)}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  sx={{ opacity: open ? 1 : 0 }}
                  style={{ color: company ? 'white' : '#fafafa' }}
                />
              </ListItemButton>
            </ListItem>
          </CustomTooltip>
        ))}
      </List>
      <Divider />
      <List>
        {sidebarItems.map((item) => (
          <CustomTooltip key={item.path} title={item.label} placement="right">
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => navigate(item.path, { state: { from: location.pathname } })}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  sx={{ opacity: open ? 1 : 0 }}
                  style={{ color: 'white' }}
                />
              </ListItemButton>
            </ListItem>
          </CustomTooltip>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
