import { Step, StepLabel, Stepper } from '@mui/material';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import Form from '../../components/global/Form';
import Page from '../../components/global/Page';
import ShopMetaInfo from '../../components/master-list/add-bulk/ShopMetaInfo';
import { yupResolver } from '@hookform/resolvers/yup';
import { bulkUploadSchema } from '../../validation/masterlist';
import CsvFileFileUpload from '../../components/master-list/add-bulk/CsvFileFileUpload';
import ValidateRawData from '../../components/master-list/add-bulk/ValidateRawData';
import Result from '../../components/master-list/add-bulk/Result';
const AddBulkProduct = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [parsedData, setParsedData] = useState([]);
  const [erroredData, setErroredData] = useState([]);

  const resolver = yupResolver(bulkUploadSchema);
  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      shops: [],
      metas: [],
    },
    resolver,
    mode: 'onChange',
  });

  const {
    fields: metaFields,
    append: metaAppend,
    remove: metaRemove,
  } = useFieldArray({ control, name: 'metas' });

  const steps = [
    {
      label: 'Configure CSV',
      Element: (
        <ShopMetaInfo
          toggleStep={setActiveStep}
          register={register}
          control={control}
          setValue={setValue}
          formSubmit={handleSubmit}
          formFields={metaFields}
          formAppend={metaAppend}
          formRemove={metaRemove}
          formErrors={errors}
        />
      ),
    },
    {
      label: 'Upload CSV',
      Element: (
        <CsvFileFileUpload
          control={control}
          toggleStep={setActiveStep}
          saveParsedData={setParsedData}
        />
      ),
    },

    {
      label: 'Validate Data',
      Element: (
        <ValidateRawData
          toggleStep={setActiveStep}
          rawData={parsedData}
          saveParsedData={setParsedData}
          saveErroredData={setErroredData}
        />
      ),
    },

    {
      label: 'Results',
      Element: <Result erroredData={erroredData} rawData={parsedData} />,
    },
  ];
  return (
    <Page>
      <Form>
        <Stepper activeStep={activeStep}>
          {steps.map((step) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={step.label} {...stepProps}>
                <StepLabel {...labelProps}>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {steps[activeStep].Element}
      </Form>
    </Page>
  );
};

export default AddBulkProduct;
