import { Avatar, Stack } from '@mui/material';
import React from 'react';

const DaySelector = ({ selection, setSelection }) => {
  const days = [
    { label: 'S', value: 'SUN' },
    { label: 'M', value: 'MON' },
    { label: 'T', value: 'TUE' },
    { label: 'W', value: 'WED' },
    { label: 'T', value: 'THU' },
    { label: 'F', value: 'FRI' },
    { label: 'S', value: 'SAT' },
  ];

  const handleClick = (value) => {
    const dayArray = selection;
    if (!dayArray?.includes(value)) {
      dayArray?.push(value);
    } else {
      dayArray?.splice(dayArray?.indexOf(value), 1);
    }
    setSelection([...dayArray]);
  };

  return (
    <Stack direction="row" spacing={1}>
      {days.map((day) => (
        <Avatar
          key={day.value}
          sx={{
            width: 24,
            height: 24,
            fontSize: 10,
            cursor: 'pointer',
            backgroundColor: selection?.includes(day.value) ? 'primary.main' : null,
          }}
          onClick={() => handleClick(day.value)}>
          {day.label}
        </Avatar>
      ))}
    </Stack>
  );
};

export default DaySelector;
