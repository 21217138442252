import { Box, Step, StepLabel, Stepper } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Form from '../../components/global/Form';
import Page from '../../components/global/Page';
import ProductSelection from '../../components/scheduler/ProductSelection';
import SchedulerBasicInfo from '../../components/scheduler/SchedulerBasicInfo';
import { yupResolver } from '@hookform/resolvers/yup';
import { addScheduleSchema } from '../../validation/schedule';
import VerifyInformation from '../../components/scheduler/VerifyInformation';

const AddNewSchedule = () => {
  const [activeStep, setActiveStep] = useState(0);
  const resolver = yupResolver(addScheduleSchema[activeStep]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      slot: '',
      shop: null,
    },
    mode: 'onChange',
    resolver,
  });
  const toggleStep = (step) => setActiveStep(step);
  const schedulerSteps = [
    {
      label: 'Basic Infomation',
      element: (
        <SchedulerBasicInfo
          toggleStep={toggleStep}
          register={register}
          submit={handleSubmit}
          formErrors={errors}
          setFormValue={setValue}
          control={control}
        />
      ),
    },
    {
      label: 'Select Products',
      element: (
        <ProductSelection
          toggleStep={toggleStep}
          control={control}
          setFormValue={setValue}
          formErrors={errors}
          submit={handleSubmit}
        />
      ),
    },
    {
      label: 'Verify',
      element: (
        <VerifyInformation toggleStep={toggleStep} submit={handleSubmit} control={control} />
      ),
    },
  ];

  return (
    <Page>
      <Form>
        <Stepper activeStep={activeStep}>
          {schedulerSteps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box mt={2}>{schedulerSteps[activeStep].element}</Box>
      </Form>
    </Page>
  );
};

export default AddNewSchedule;
