import React from 'react';
import Page from '../../components/global/Page';
import RenderSingleAddSteps from '../../components/master-list/add-single';

const AddSingleProduct = () => {
  return (
    <Page>
      <RenderSingleAddSteps />
    </Page>
  );
};

export default AddSingleProduct;
