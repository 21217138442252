import { Box } from '@mui/system';
import React from 'react';

const Screen = ({ children, ...rest }) => {
  return (
    <Box
      sx={{
        width: '100vw',
      }}
      {...rest}>
      {children}
    </Box>
  );
};

export default Screen;
