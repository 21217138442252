import React, { useState } from 'react';
import { Alert, Button, Card, Stack, Typography } from '@mui/material';
import Page from '../../components/global/Page';
import { useLocation } from 'react-router-dom';
import { capitalize } from '../../utils/word';
import { renderFormat } from '../../utils/date';
import CustomTooltip from '../../components/global/Tooltip';
import ShopConfig from '../../components/tracking-report/ShopConfig';
import { Close, Settings } from '@mui/icons-material';

const ReportOverview = () => {
  const location = useLocation();
  const reportData = location.state.data;
  const [configuring, setConfiguring] = useState();
  console.log(reportData);

  return (
    <Page>
      <Stack>
        <Card
          sx={{
            width: '100%',
            margin: '2vh 0',
            minWidth: '420px',
          }}>
          <Stack
            sx={{
              backgroundColor: reportData.theme.canvas,
              padding: '2vw',
              color: reportData.theme.text,
            }}>
            <Typography fontWeight="500" fontSize="1.8rem">
              {reportData?.title}
            </Typography>
            <Typography fontWeight="500" fontSize="0.8rem">
              {reportData?.description}
            </Typography>
            <Typography fontWeight="500" fontSize="0.9rem" sx={{ opacity: 0.6 }} mt={2}>
              <b>Created: </b>
              {renderFormat(new Date())}
            </Typography>
            <Typography fontWeight="500" fontSize="0.9rem" sx={{ opacity: 0.6 }}>
              <b>Last Updated: </b>
              {renderFormat(new Date())}
            </Typography>
            <Stack direction="row" spacing={2} mt={3} alignSelf="flex-end">
              <Typography fontSize="0.9rem">
                <b>Type: </b>
                {capitalize(reportData?.type)}
              </Typography>
              <Typography fontSize="0.9rem">
                <b>Session: </b>
                {capitalize(reportData?.session)}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignSelf="flex-end">
              <CustomTooltip
                title={
                  reportData?.repeatType === 'CUSTOM'
                    ? reportData?.selectedDays?.toString() || ''
                    : ''
                }
                followCursor>
                <Typography fontSize="0.9rem">
                  <b>Repeats: </b>
                  {capitalize(reportData?.repeatType)}
                </Typography>
              </CustomTooltip>
              <CustomTooltip
                title={reportData?.endsOn === 'CUSTOM' ? renderFormat(reportData?.endDate) : ''}
                followCursor>
                <Typography fontSize="0.9rem">
                  <b>Ends on: </b>
                  {capitalize(reportData?.endsOn)}
                </Typography>
              </CustomTooltip>
            </Stack>
          </Stack>
        </Card>

        <Typography fontSize="1.1rem" fontWeight="600" mb={1}>
          Shop Configuration
        </Typography>
        <Stack spacing={2} p={2} bgcolor="white" borderRadius="10px">
          {reportData?.shops?.map((shop) =>
            shop.isConfigured ? null : (
              <Stack>
                <Alert
                  elevation={1}
                  severity="warning"
                  key={shop.shopCode}
                  action={
                    <Button
                      size="small"
                      color="inherit"
                      variant="contained"
                      onClick={
                        configuring === shop.shopCode
                          ? () => setConfiguring()
                          : () => setConfiguring(shop.shopCode)
                      }
                      endIcon={configuring === shop.shopCode ? <Close /> : <Settings />}>
                      {configuring === shop.shopCode ? 'Cancel' : 'Configure'}
                    </Button>
                  }>
                  <b>{shop.name}</b> is not configured!
                </Alert>
                <ShopConfig shop={shop} isOpen={configuring === shop.shopCode} />
              </Stack>
            ),
          )}
        </Stack>
      </Stack>
    </Page>
  );
};

export default ReportOverview;
