import React from 'react';
import { useDispatch } from 'react-redux';
import { Delete } from '@mui/icons-material';
import {
  Box,
  Alert,
  Stack,
  Table,
  Button,
  Dialog,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from '@mui/material';
import { GridRowModes, GridToolbar } from '@mui/x-data-grid';

import BaseTable from '../../global/BaseTable';
import CustomTooltip from '../../global/Tooltip';

import { updateProductStock } from '../../../api/product';
import { feedbackActions } from '../../../store/slice/feedbackReducer';

const ValidateRawData = ({ rawData, saveParsedData, toggleStep, saveErroredData }) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(20);
  const [loading, setLoading] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState([]);
  const [showSubmitDialog, setShowSubmitDialog] = React.useState(false);
  const [selectedProductShops, setSelectedProductShops] = React.useState([]);

  const getColumns = () => {
    const columns = [
      { field: 'productCode', width: 150, headerName: 'Product Code', hide: true },
      { field: 'name', width: 250, headerName: 'Product Name' },
      { field: 'brand', headerName: 'Brand', width: 150 },
      { field: 'businessUnit', headerName: 'Business Unit', width: 150 },
      { field: 'brandCode', headerName: 'Brand Code', width: 150 },
      {
        field: 'stockQuantity',
        headerName: 'Stock Quantity',
        width: 150,
        editable: true,
        type: 'number',
      },
    ];

    return columns;
  };

  const ToolBar = () => {
    return (
      <Stack direction="row" justifyContent="space-between" sx={{ my: '20px', px: '20px' }}>
        {selectedData.length > 0 && (
          <Button
            endIcon={<Delete />}
            variant="contained"
            color="error"
            onClick={() => {
              const unselectedData = rawData.filter(
                (product) => !selectedData.includes(product.productCode),
              );
              saveParsedData(unselectedData);
            }}>
            Delete Selected
          </Button>
        )}
        <GridToolbar />
      </Stack>
    );
  };

  const previous = () => toggleStep(1);

  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleSubmit = async () => {
    setLoading(true);
    const response = await updateProductStock(rawData);

    if (!response) {
      dispatch(feedbackActions.NOTIFY({ status: 'error', message: 'Server crashed' }));
    } else {
      saveErroredData(response.erroredData);
      toggleStep(2);
    }
    setLoading(false);
  };

  return (
    <Box width="100%" my="3rem">
      <Dialog
        open={showSubmitDialog}
        onClose={() => {
          setShowSubmitDialog(false);
        }}
        fullWidth>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to update stock quantity of these products</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowSubmitDialog(false);
            }}>
            No
          </Button>
          <Button onClick={handleSubmit}>YES</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={selectedProductShops.length > 0}
        onClose={() => setSelectedProductShops([])}
        fullWidth>
        <DialogTitle>Shops</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Shop Code</TableCell>
                <TableCell align="left">SKU</TableCell>
                <TableCell align="left">Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProductShops?.map((item) => (
                <TableRow key={item.shopCode}>
                  <TableCell>{item.shopCode}</TableCell>
                  <TableCell align="left">{item.sku}</TableCell>
                  <TableCell align="left">
                    <CustomTooltip title={item.link}>
                      <Box>
                        <Button components="a" href={item.link} target="_blank">
                          Click Me
                        </Button>
                      </Box>
                    </CustomTooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedProductShops([])}>Close</Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h5" fontWeight="bold" sx={{ mb: '10px' }}>
        Check Parsed Data
      </Typography>
      <Divider />
      <Box mb="20px" />
      <Box height="550px" border="1px solid rgba(0,0,0,0.2)" borderRadius={2}>
        {rawData ? (
          <BaseTable
            checkboxSelection
            rows={rawData}
            getRowId={(row) => row?.productCode}
            columns={getColumns()}
            rowCount={rawData.langth}
            page={page}
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
            onPageSizeChange={(pageSize) => setPageSize(pageSize)}
            rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
            components={{
              Toolbar: ToolBar,
            }}
            processRowUpdate={(newVal, oldVal) => {
              if (oldVal !== newVal) {
                const editedData = rawData.map((item) =>
                  item.productCode === newVal.productCode
                    ? { ...newVal, id: newVal.productCode }
                    : item,
                );
                saveParsedData(editedData);
                return newVal;
              } else {
                return oldVal;
              }
            }}
            onProcessRowUpdateError={() => {
              dispatch(feedbackActions.NOTIFY({ status: 'error', message: 'Faile to update' }));
            }}
            onSelectionModelChange={(ids) => setSelectedData(ids)}
            experimentalFeatures={{ newEditingApi: true }}
            rowModesModel={rowModesModel}
            onRowEditStart={(e) => {
              setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.Edit });
            }}
            onRowEditStop={(e) => {
              setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.View });
            }}
            onRowEditCommit={(e) => {
              setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.View });
            }}
          />
        ) : (
          <Alert severity="error">CSV File could not be extracted</Alert>
        )}
      </Box>

      <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ my: '20px' }}>
        <Button variant="contained" onClick={previous} sx={{ boxShadow: 0 }}>
          Previous
        </Button>
        <Button variant="contained" onClick={() => setShowSubmitDialog(true)} sx={{ boxShadow: 0 }}>
          {loading ? <CircularProgress /> : 'Next'}
        </Button>
      </Stack>
    </Box>
  );
};

export default ValidateRawData;
