import React from 'react';
import { Done } from '@mui/icons-material';
import { Avatar, Stack } from '@mui/material';

const ThemeSelector = ({ scheme, theme, setTheme }) => {
  return (
    <Stack direction="row" spacing={1}>
      {scheme?.map((item) => (
        <Avatar
          key={item.canvas}
          sx={{
            width: 24,
            height: 24,
            fontSize: 12,
            cursor: 'pointer',
            backgroundColor: item.canvas,
          }}
          onClick={() => setTheme(item)}>
          {theme.canvas === item.canvas && (
            <Done color="white" sx={{ height: '16px', width: '16px' }} />
          )}
        </Avatar>
      ))}
    </Stack>
  );
};

export default ThemeSelector;
