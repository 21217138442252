import React from 'react';
import { Outlet } from 'react-router-dom';
import FeedbackNotification from '../components/global/FeedbackNotification';

const PublicLayout = () => {
  return (
    <>
      <Outlet />
      <FeedbackNotification />
    </>
  );
};

export default PublicLayout;
