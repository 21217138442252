import {
  COMPANY,
  DASHBOARD,
  ADD_NEW_COMPANY,
  SHOP,
  USERS,
  NEW_USER,
  MASTER_LIST,
  ADD_SINGLE_PRODUCT,
  ADD_BULK_PRODUCT,
  SCHEDULER,
  ADD_NEW_SCHEDULER,
  COMPANY_SHOPS,
  ADD_NEW_COMPANY_SHOPS,
  UPDATE_COMPANY_SHOPS,
  UPDATE_STOCK,
  STOCK_STATUS,
  TRACKING_REPORTS,
  CREATE_NEW_REPORT,
  REPORT_OVERVIEW,
} from './routerUrls';

export default {
  [DASHBOARD]: 'Dashboard',

  [COMPANY]: 'Companies',
  [ADD_NEW_COMPANY]: 'Add Company',

  [SHOP]: 'Shops',

  [USERS]: 'Users',
  [NEW_USER]: 'Add User',

  [MASTER_LIST]: 'Master List',
  [ADD_SINGLE_PRODUCT]: 'Add Single Product',
  [ADD_BULK_PRODUCT]: 'Add Bulk Product',
  [UPDATE_STOCK]: 'Update Product Stock',

  [TRACKING_REPORTS]: 'Tracking Reports',
  [CREATE_NEW_REPORT]: 'Create New Report',
  [REPORT_OVERVIEW]: 'Report Overview',
  [STOCK_STATUS]: 'Stock Status',

  [SCHEDULER]: 'Scheduler',
  [ADD_NEW_SCHEDULER]: 'New Schedule',

  [COMPANY_SHOPS]: 'Stores',
  [ADD_NEW_COMPANY_SHOPS]: 'New Stores',
  [UPDATE_COMPANY_SHOPS]: 'Update Information',
};
