import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Fab, Stack } from '@mui/material';
import Page from '../../components/global/Page';
import ReportCard from '../../components/tracking-report/ReportCard';
import CustomTooltip from '../../components/global/Tooltip';
import { CREATE_NEW_REPORT } from '../../constants/routerUrls';

const TrackingReport = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Page>
      <Stack py={2} flexDirection="row">
        <ReportCard />
      </Stack>
      <CustomTooltip title="Create new report" placement="left-start">
        <Fab
          sx={{ position: 'fixed', bottom: '20px', right: '20px' }}
          onClick={() => navigate(CREATE_NEW_REPORT, { state: { from: location.pathname } })}>
          <Add />
        </Fab>
      </CustomTooltip>
    </Page>
  );
};

export default TrackingReport;
