import { Box, Button, IconButton, Link, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAllShops, fetchCompanyShops } from '../../../api/shop';
import Page from '../../../components/global/Page';
import { ADD_NEW_COMPANY_SHOPS, UPDATE_COMPANY_SHOPS } from '../../../constants/routerUrls';
import { useSelector } from 'react-redux';
import BaseTable from '../../../components/global/BaseTable';
import NoDataOverlay from '../../../components/global/NoDataOverlay';
import { Edit } from '@mui/icons-material';
import CustomTooltip from '../../../components/global/Tooltip';
import { renderFormat } from '../../../utils/date';

const CompanyBasedShops = () => {
  const company = useSelector((state) => state.company.selectedCompany);
  const [shouldDisableAddNew, setShouldDisableAddNew] = useState(false);
  const [assignedShops, setAsignedShops] = useState([]);

  const [unAssignedShops, setUnAssignedShops] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const all = await fetchAllShops();
      const assigned = await fetchCompanyShops({ companyUid: company.uid });
      setAsignedShops(assigned);

      if (assigned?.length === all?.length) {
        setShouldDisableAddNew(true);
      } else {
        const assignedCodes = assigned.map((item) => item.shopCode);

        const filtered = all.filter((item) => !assignedCodes.includes(item.shopCode));
        if (filtered) {
          setUnAssignedShops(filtered);
        } else {
          setUnAssignedShops([]);
        }
      }
    })();
  }, [company]);

  const navigateToEdit = (shopCode, companyUid) =>
    navigate(UPDATE_COMPANY_SHOPS, { state: { shopCode, companyUid } });

  const columns = [
    {
      field: 'name',
      headerName: 'Shop',
    },
    {
      field: 'shopCode',
      headerName: 'Shop Code',
    },
    {
      field: 'region',
      headerName: 'Region',
    },
    {
      field: 'baseUrl',
      headerName: 'Link',
      width: 220,
      renderCell: (params) => <Link href={params.value}>{params.value}</Link>,
    },
    {
      field: 'keywords',
      headerName: 'Keywords',
      width: 300,
      renderCell: (params) => (
        <CustomTooltip title={`${params?.value?.map((item) => item.keyword + ', ')}`}>
          <Typography>{params?.value?.map((item) => item.keyword + ', ')}</Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'darkStores',
      headerName: 'Dark Stores',
      width: 300,
      renderCell: (params) => (
        <CustomTooltip title={`${params?.value?.map((item) => item.name + ', ')}`}>
          <Typography>{params?.value?.map((item) => item.name + ', ')}</Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Added On',
      width: 160,
      renderCell: (params) => renderFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: 'Last Updated',
      width: 160,
      renderCell: (params) => renderFormat(params.value),
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 80,
      renderCell: (params) => {
        const { darkStores, keywords } = params.row;

        if (darkStores?.length > 0 || keywords?.length > 0) {
          return (
            <CustomTooltip title="Edit">
              <Box>
                <IconButton onClick={() => navigateToEdit(params.row.shopCode, company.uid)}>
                  <Edit sx={{ fill: '#000' }} />
                </IconButton>
              </Box>
            </CustomTooltip>
          );
        }
      },
    },
  ];
  return (
    <Page>
      <Box my="25px" mx="auto" width="100%" borderRadius={1}>
        <Stack
          width="100%"
          height="100%"
          direction="row"
          alignItems="center"
          justifyContent="flex-end">
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              onClick={() => {
                navigate(ADD_NEW_COMPANY_SHOPS, { state: { unAssignedShops } });
              }}
              disabled={shouldDisableAddNew}>
              ADD NEW
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Box width="100%" height="550px" bgcolor="#fff" mt="15px" borderRadius="10px">
        <BaseTable
          rows={assignedShops}
          columns={columns}
          getRowId={(row) => row.shopCode}
          components={{
            Pagination: null,
            NoResultsOverlay: NoDataOverlay,
            NoRowsOverlay: NoDataOverlay,
          }}
        />
      </Box>
    </Page>
  );
};

export default CompanyBasedShops;
