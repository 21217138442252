import React from 'react';
import { Box, Card, CardContent, Chip, Stack, Typography } from '@mui/material';

const StatCard = ({ title, stat, statColor, diff, infoText, status, rightElement }) => {
  return (
    <Card
      sx={{
        width: 300,
        marginRight: '10px',
        marginBottom: '10px',
        border: `2px solid ${statColor || 'unset'}`,
      }}>
      <CardContent>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h4" component="div" mt={2} color={statColor || 'unset'}>
              {stat}
            </Typography>
            {status && infoText ? (
              <Stack direction="row" mt={1} alignItems="center">
                <Chip variant="fill" color={status} size="small" label={diff} />
                <Typography color="text.secondary" ml={1} fontSize="small">
                  {infoText}
                </Typography>
              </Stack>
            ) : (
              <Box mt={1} height="24px" width="100%"></Box>
            )}
          </Stack>
          {rightElement}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default StatCard;
