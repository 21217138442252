import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import {
  Tab,
  Tabs,
  Link,
  Alert,
  Stack,
  colors,
  Divider,
  IconButton,
  Typography,
  Chip,
} from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { GridRowModes, GridToolbar } from '@mui/x-data-grid';

import Page from '../../components/global/Page';
import StatCard from '../../components/global/StatCard';
import BaseTable from '../../components/global/BaseTable';
import CustomTooltip from '../../components/global/Tooltip';
import AlertModal from '../../components/global/AlertModal';
import NoDataOverlay from '../../components/global/NoDataOverlay';
import BasicDatePicker from '../../components/global/BasicDatePicker';

import Atp from '../../assets/atp.png';
import InStock from '../../assets/in-stock.png';
import OutOfStock from '../../assets/out-of-stock.png';
import CouldntTrack from '../../assets/couldnt-track.png';
import TotalTrackables from '../../assets/total-trackables.png';

import { fetchCompanyShops } from '../../api/shop';
import { getScrapeData, updateScrapeData } from '../../api/oos';

import * as date from '../../utils/date';
import { feedbackActions } from '../../store/slice/feedbackReducer';

const StockStatus = () => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(25);
  const [shopList, setShopList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeShop, setActiveShop] = useState('');
  const [scrapeData, setScrapeData] = useState([]);
  const [scrapeStats, setScrapeStats] = useState({});
  const [rowModesModel, setRowModesModel] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dataPatchPromise, setDataPatchPromise] = React.useState(null);
  const company = useSelector((state) => state.company.selectedCompany);

  useEffect(() => {
    (async () => {
      const shops = await fetchCompanyShops({ companyUid: company.uid });
      setShopList(shops);
      setActiveShop(shops?.[0]?.shopCode);
    })();
  }, []);

  useEffect(() => {
    setLoading(true);
    if (activeShop) {
      (async () => {
        const result = await getScrapeData({
          companyUid: company.uid,
          shopCode: activeShop,
          date: date.sendFormat(selectedDate),
        });
        setScrapeData(result?.data);
        setScrapeStats(result?.stats);
      })();
    }
    setLoading(false);
  }, [activeShop, selectedDate, dataPatchPromise]);

  const statCards = [
    {
      title: 'Total trackables',
      stat: scrapeStats?.total || 0,
      rightElement: (
        <Box width="65px">
          <img src={TotalTrackables} style={{ width: '100%' }} />
        </Box>
      ),
    },
    {
      title: 'In stock',
      stat: scrapeStats?.in_stock?.current || 0,
      diff: `${scrapeStats?.in_stock?.diff >= 0 ? '+' : ''}${scrapeStats?.in_stock?.diff}%`,
      status:
        scrapeStats?.in_stock?.diff > 0
          ? 'success'
          : scrapeStats?.in_stock?.diff < 0
          ? 'error'
          : 'info',
      infoText: 'since previous day',
      rightElement: (
        <Box width="65px">
          <img src={InStock} style={{ width: '100%' }} />
        </Box>
      ),
    },
    {
      title: 'Out of stock',
      stat: scrapeStats?.out_of_stock?.current || 0,
      diff: `${scrapeStats?.out_of_stock?.diff >= 0 ? '+' : ''}${scrapeStats?.out_of_stock?.diff}%`,
      status:
        scrapeStats?.out_of_stock?.diff > 0
          ? 'success'
          : scrapeStats?.out_of_stock?.diff < 0
          ? 'error'
          : 'info',
      infoText: 'since previous day',
      rightElement: (
        <Box width="65px">
          <img src={OutOfStock} style={{ width: '100%' }} />
        </Box>
      ),
    },
    {
      title: 'Couldn’t track',
      stat: scrapeStats?.couldnt_track?.current || 0,
      diff: `${scrapeStats?.couldnt_track?.diff >= 0 ? '+' : ''}${
        scrapeStats?.couldnt_track?.diff
      }%`,
      status:
        scrapeStats?.couldnt_track?.diff > 0
          ? 'success'
          : scrapeStats?.couldnt_track?.diff < 0
          ? 'error'
          : 'info',
      infoText: 'since previous day',
      rightElement: (
        <Box width="65px">
          <img src={CouldntTrack} style={{ width: '100%' }} />
        </Box>
      ),
    },
    {
      title: 'ATP',
      stat: `${scrapeStats?.atp?.current || 0.0}%`,
      statColor:
        scrapeStats?.atp?.current >= 95
          ? '#2E7D32'
          : scrapeStats?.atp?.current >= 90
          ? '#ED6C02'
          : scrapeStats?.atp?.current > 0
          ? '#D32F2F'
          : 'unset',
      diff: `${scrapeStats?.atp?.diff >= 0 ? '+' : ''}${scrapeStats?.atp?.diff}%`,
      status:
        scrapeStats?.atp?.diff > 0 ? 'success' : scrapeStats?.atp?.diff < 0 ? 'error' : 'info',
      infoText: 'since previous day',
      rightElement: (
        <Box width="65px">
          <img src={Atp} style={{ width: '100%' }} />
        </Box>
      ),
    },
  ];

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 100,
      renderCell: (params) => date.renderFormat(params.value).split(',')[0],
    },
    {
      field: 'scrapeSessionUid',
      headerName: 'Session Id',
      width: 120,
      hide: true,
    },
    {
      field: 'sku',
      headerName: 'SKU',
      width: 120,
      renderCell: (params) => (
        <CustomTooltip title={params?.value} followCursor>
          <Typography fontSize="14px">{params?.value}</Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'shopCode',
      headerName: 'Shop Code',
      width: 160,
      hide: true,
    },
    {
      field: 'productCode',
      headerName: 'Product Code',
      width: 120,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 600,
      editable: true,
      renderCell: (params) => (
        <CustomTooltip title={params?.value} followCursor>
          <Typography fontSize="14px">{params?.value}</Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 180,
      hide: true,
      editable: true,
      renderCell: (params) => (
        <CustomTooltip title={params?.value} followCursor>
          <Typography>{params?.value?.substring(0, 40)}</Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'size',
      headerName: 'Size',
      width: 100,
      hide: true,
      editable: true,
    },
    {
      field: 'rsp',
      headerName: 'RSP',
      width: 100,
      hide: true,
      editable: true,
    },
    {
      field: 'offerPrice',
      headerName: 'Offer Price',
      width: 100,
      hide: true,
      editable: true,
    },
    {
      field: 'promotions',
      headerName: 'Promotions',
      width: 100,
      hide: true,
      editable: true,
    },
    {
      field: 'vouchers',
      headerName: 'Vouchers',
      width: 100,
      hide: true,
      editable: true,
    },
    {
      field: 'ratings',
      headerName: 'Ratings',
      width: 100,
      hide: true,
      editable: true,
    },
    {
      field: 'reviews',
      headerName: 'Reviews',
      width: 100,
      hide: true,
      editable: true,
    },
    {
      field: 'seller',
      headerName: 'Seller',
      width: 100,
      hide: true,
      editable: true,
    },
    {
      field: 'shipping',
      headerName: 'Shipping',
      width: 100,
      hide: true,
      editable: true,
    },
    {
      field: 'stockStatus',
      headerName: 'Stock Status',
      width: 140,
      type: 'singleSelect',
      // eslint-disable-next-line quotes
      valueOptions: ['In stock', 'Out of stock', "Couldn't track"],
      editable: true,
    },
    {
      field: 'dbStockQuantity',
      headerName: 'Distributor Stock Quantity',
      width: 140,
      type: 'number',
      editable: true,
      renderCell: (params) => (
        <CustomTooltip title={`Threshold: ${params.row?.dbStockThreshold}`} followCursor>
          <Chip
            size="small"
            label={params?.value}
            color={params?.value > params.row?.dbStockThreshold ? 'success' : 'error'}
          />
        </CustomTooltip>
      ),
    },
    {
      field: 'dbStockThreshold',
      headerName: 'Distributor Stock Threshold',
      width: 140,
      type: 'number',
      hide: true,
      editable: true,
    },
    {
      field: 'stockQuantity',
      headerName: 'Quantity',
      width: 100,
      hide: true,
      editable: true,
    },
    {
      field: 'link',
      headerName: 'Link',
      width: 800,
      editable: true,
      renderCell: (params) => (
        <Link href={params?.value} target="_blank">
          {params?.value}
        </Link>
      ),
    },
    {
      field: 'link-copy',
      headerName: 'Copy Link',
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              navigator.clipboard
                .writeText(params.row?.link)
                .then(() => {
                  dispatch(
                    feedbackActions.NOTIFY({
                      status: 'success',
                      message: 'Link coppied',
                    }),
                  );
                })
                .catch(() => {
                  alert('something went wrong');
                });
            }}>
            <ContentCopy />
          </IconButton>
        );
      },
    },
  ];

  const checkDiff = (oldValue, newValue) => {
    let flag = false;
    for (let key in newValue) {
      if (newValue[key] !== oldValue[key]) {
        flag = true;
      }
    }
    return flag;
  };

  const validate = (newValue) => {
    let flag = true;
    const requiredFields = ['stockStatus'];
    requiredFields.forEach((key) => {
      if (newValue[key].length <= 0) {
        flag = false;
      }
    });
    return flag;
  };

  const updateRow = useCallback(
    (newValue, oldValue) =>
      new Promise((resolve, reject) => {
        if (checkDiff(oldValue, newValue) && validate(newValue)) {
          setDataPatchPromise({ resolve, reject, newValue, oldValue });
        } else {
          resolve(oldValue);
        }
      }),
    [],
  );

  const handleDataPatchNoPress = async () => {
    const { oldValue, resolve } = dataPatchPromise;
    resolve(oldValue);
    setDataPatchPromise(null);
  };

  const handleDataPatchYesPress = async () => {
    setLoading(true);
    const { newValue, oldValue, reject, resolve } = dataPatchPromise;
    const response = await updateScrapeData(newValue);
    if (response) {
      resolve(newValue);
      setDataPatchPromise(null);
      dispatch(feedbackActions.NOTIFY({ status: 'success', message: 'Changes saved' }));
    } else {
      reject(oldValue);
      setDataPatchPromise(null);
    }
    setLoading(false);
  };

  const getBackgroundColor = (color, mode) => {
    return mode === 'dark' ? '#FFEBEE' : '#FFEBEE';
  };

  return (
    <Page>
      <AlertModal
        title="Are you sure?"
        isOpen={dataPatchPromise}
        onRejectClick={handleDataPatchNoPress}
        onResolveClick={handleDataPatchYesPress}
        content="Are your sure want save your changes?"
      />
      <Box my="25px" mx="auto" width="100%">
        {/* date-picker */}
        <Stack
          spacing={1}
          width="100%"
          height="100%"
          direction="row"
          alignItems="center"
          justifyContent="flex-start">
          <Stack direction="row" spacing={2}>
            <BasicDatePicker value={selectedDate} setValue={setSelectedDate} />
          </Stack>
          {scrapeData?.length > 0 ? null : (
            <Alert severity="info">{`Stock data for ${
              shopList?.find((item) => item?.shopCode == activeShop)?.name
            } is not available yet. Please check again later.`}</Alert>
          )}
        </Stack>

        {/* stat-cards */}
        <Stack mt={2} mb={1} width="100%">
          <Stack width="100%" flexWrap="wrap" direction="row">
            {statCards?.map((item, index) => (
              <StatCard
                key={index}
                stat={item?.stat}
                diff={item?.diff}
                title={item?.title}
                status={item?.status}
                infoText={item?.infoText}
                statColor={item?.statColor}
                rightElement={item?.rightElement}
              />
            ))}
          </Stack>
        </Stack>
        <Divider />

        {/* data-grid */}
        <Box
          mt={2}
          width="100%"
          height="auto"
          bgcolor="white"
          minHeight="60vh"
          overflow="hidden"
          borderRadius="10px">
          {/* shop-tabs */}
          <Tabs
            scrollButtons
            value={activeTab}
            variant="scrollable"
            allowScrollButtonsMobile
            sx={{ bgcolor: colors.blue[50] }}
            onChange={(event, value) => setActiveTab(value)}>
            {shopList.map((item) => (
              <Tab
                key={item.shopCode}
                label={`${item.name} - (${item.region})`}
                onClick={() => setActiveShop(item.shopCode)}
              />
            ))}
          </Tabs>

          {/* oos-table */}
          <Box
            width="100%"
            height="500px"
            minHeight="60vh"
            sx={{
              '& .super-app-theme--warning': {
                bgcolor: (theme) => getBackgroundColor(theme.palette.info.main, theme.palette.mode),
              },
            }}>
            <BaseTable
              density="compact"
              loading={loading}
              columns={columns}
              rows={scrapeData}
              pageSize={pageSize}
              processRowUpdate={updateRow}
              rowModesModel={rowModesModel}
              rowsPerPageOptions={[25, 50, 75, 100]}
              experimentalFeatures={{ newEditingApi: true }}
              onPageSizeChange={(value) => setPageSize(value)}
              components={{
                Toolbar: GridToolbar,
                NoRowsOverlay: NoDataOverlay,
                NoResultsOverlay: NoDataOverlay,
              }}
              onProcessRowUpdateError={() => {
                dispatch(
                  feedbackActions.NOTIFY({
                    status: 'error',
                    message: 'Failed to update product info',
                  }),
                );
              }}
              onRowEditStart={(e) => {
                setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.Edit });
              }}
              onRowEditStop={(e) => {
                setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.View });
              }}
              onRowEditCommit={(e) => {
                setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.View });
              }}
              getRowClassName={(params) =>
                `super-app-theme--${
                  // eslint-disable-next-line quotes
                  params.row.stockStatus === "Couldn't track" ? 'warning' : 'default'
                }`
              }
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default StockStatus;
