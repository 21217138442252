import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJs, registerables } from 'chart.js';
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';

ChartJs.register(...registerables);

const AtpStat = () => {
  const randomNumbers = (length, max) => {
    let values = [];
    for (let i = 0; i <= length; i += 1) {
      values.push(Math.floor(Math.random() * max));
    }

    return values;
  };

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
        <Typography variant="h6" fontWeight="bold">
          ATP
        </Typography>
        <FormControl>
          <InputLabel>Select Range</InputLabel>
          <Select
            label="Select Range"
            sx={{ Width: '200px' }}
            defaultValue="this year"
            size="small">
            <MenuItem value="this month">This Month</MenuItem>
            <MenuItem value="this year">This Year</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Box height="300px">
        <Line
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'top',
              },
            },
          }}
          data={{
            labels: [
              'Jan',
              'Feb',
              'March',
              'April',
              'May',
              'June',
              'July',
              'Sept',
              'Oct',
              'Nov',
              'Dec',
            ],
            datasets: [
              {
                id: 1,
                label: 'AMZ-SG',
                data: randomNumbers(100, 100),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
              {
                id: 2,
                label: 'CSG-EU',
                data: randomNumbers(130, 100),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
            ],
          }}
        />
      </Box>
    </Stack>
  );
};

export default AtpStat;
