import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ArrowDropDownRounded, CancelRounded, Check, Clear, Search } from '@mui/icons-material';
import { isObjectEmpty } from '../../utils/objects';
import CustomTooltip from './Tooltip';
import { getRandomColor } from '../../utils/color';

const GetField = ({ field, removeCallBack, valueOptions, filterModel, updateFilterModel }) => {
  const handleTextFieldChange = (fieldName, value) =>
    updateFilterModel({ ...filterModel, [fieldName]: value });

  const handleMutiSelectFieldChange = (fieldName, value) => {
    const formatedValue = value?.reduce((prValue, crValue) => {
      if (prValue !== '') {
        return prValue + ',' + crValue;
      } else {
        return prValue + crValue;
      }
    }, '');
    updateFilterModel({ ...filterModel, [fieldName]: formatedValue });
  };

  const handleSingleSelectFieldChage = (fieldName, value) =>
    updateFilterModel({ ...filterModel, [fieldName]: value });

  let multiSelectDefaultValue = [];

  const getMultiSelectValue = () => {
    const value = filterModel?.[field.field];
    if (value) {
      const values = value.split(',').filter((item) => item !== '');
      return values;
    } else {
      return [];
    }
  };

  switch (field.type) {
    case 'text':
      return (
        <Stack direction="row" spacing={0.2} sx={{ mr: 2, mb: 1 }}>
          <TextField
            label={field.label}
            placeholder={field.placeholder}
            sx={{ width: 350, borderRadius: 0, bgcolor: 'white' }}
            size="small"
            value={filterModel[field.field] || ''}
            onChange={(e) => handleTextFieldChange(field.field, e.target.value)}
          />
          <IconButton onClick={() => removeCallBack(field)}>
            <CancelRounded sx={{ fill: 'red' }} />
          </IconButton>
        </Stack>
      );
    case 'multiSelect':
      multiSelectDefaultValue = getMultiSelectValue();

      return (
        <Stack direction="row" spacing={0.2} sx={{ mr: 2, mb: 1 }}>
          <Autocomplete
            sx={{ width: 350 }}
            multiple
            size="small"
            disableCloseOnSelect
            value={multiSelectDefaultValue}
            options={valueOptions[field.valueOptionsName]}
            renderTags={(value) => value.map((item) => item + ', ')}
            renderInput={(params) => (
              <TextField
                {...params}
                label={field.label}
                placeholder={field.placeholder}
                sx={{ maxWidth: 350, borderRadius: 0, bgcolor: 'white' }}
                size="small"
              />
            )}
            onChange={(event, value) => handleMutiSelectFieldChange(field.field, value)}
          />
          <IconButton onClick={() => removeCallBack(field)}>
            <CancelRounded sx={{ fill: 'red' }} />
          </IconButton>
        </Stack>
      );
    case 'singleSelect':
      return (
        <Stack direction="row" spacing={0.2} sx={{ mr: 2, mb: 1 }}>
          <Autocomplete
            sx={{ width: 350 }}
            size="small"
            value={filterModel[field.field] || ''}
            options={valueOptions[field.valueOptionsName]}
            renderTags={(value) => value.map((item) => item + ', ')}
            renderInput={(params) => (
              <TextField
                {...params}
                label={field.label}
                placeholder={field.placeholder}
                sx={{ maxWidth: 350, borderRadius: 0, bgcolor: 'white' }}
                size="small"
              />
            )}
            onChange={(event, value) => handleSingleSelectFieldChage(field.field, value)}
          />
          <IconButton onClick={() => removeCallBack(field)}>
            <CancelRounded sx={{ fill: 'red' }} />
          </IconButton>
        </Stack>
      );
    default:
      return (
        <Stack direction="row" spacing={0.2} sx={{ mr: 2, mb: 1 }}>
          <TextField
            label={field.label}
            placeholder={field.placeholder}
            value={filterModel[field.field] || ''}
            sx={{ maxWidth: 350, borderRadius: 0, bgcolor: 'white' }}
            size="small"
          />
          <IconButton onClick={() => removeCallBack(field)}>
            <CancelRounded sx={{ fill: 'red' }} />
          </IconButton>
        </Stack>
      );
  }
};

const RenderFilterInfo = ({ filterModel, selectedFilter }) => {
  const fields = Object.keys(filterModel);

  const findFiled = (field) => {
    const filteredField = selectedFilter.find((item) => item.field === field);
    return filteredField;
  };

  return (
    <Stack direction="row" flexWrap="wrap" spacing={2}>
      {fields.map(
        (field) =>
          filterModel?.[field] && (
            <CustomTooltip title={findFiled(field).label}>
              <Chip
                sx={{ borderColor: getRandomColor() }}
                variant="outlined"
                key={field}
                label={`${filterModel?.[field]}`}
              />
            </CustomTooltip>
          ),
      )}
    </Stack>
  );
};

const GlobalFilter = ({ filters, name, valueOptions, data, updateData }) => {
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [anchorElFilterMenu, setAnchorElFilterMenu] = React.useState(null);

  const [filterModel, setFilterModel] = React.useState({});
  const [showClearButton, setShowClearButton] = React.useState(false);

  useEffect(() => {
    const filtersFromLocalStorage = localStorage.getItem(name);
    if (JSON.parse(filtersFromLocalStorage)) {
      setSelectedFilter(JSON.parse(filtersFromLocalStorage));
    } else {
      setSelectedFilter([]);
    }
  }, []);

  const isFilterMenuOpen = Boolean(anchorElFilterMenu);

  const handleFilterMenuOpen = (event) => {
    setAnchorElFilterMenu(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setAnchorElFilterMenu(null);
  };

  const isFilterSelected = (filter) => {
    const selected = selectedFilter?.find((item) => item.field === filter.field);
    if (selected) {
      return true;
    }
    return false;
  };

  const handleFilterSelect = (filter) => {
    const isSelected = isFilterSelected(filter);
    if (isSelected) {
      const updatedFilterList = selectedFilter.filter((item) => item.field !== filter.field);
      setSelectedFilter(updatedFilterList);
      setFilterModel({ ...filterModel, [filter.field]: undefined });
      localStorage.setItem(name, JSON.stringify(updatedFilterList));
    } else {
      setSelectedFilter([...selectedFilter, filter]);
      setFilterModel({ ...filterModel, [filter.field]: '' });
      localStorage.setItem(name, JSON.stringify([...selectedFilter, filter]));
    }

    setAnchorElFilterMenu(null);
  };

  const removeFilter = (filter) => {
    const updatedFilterList = selectedFilter.filter((item) => item.field !== filter.field);
    setSelectedFilter(updatedFilterList);
    setFilterModel({ ...filterModel, [filter.field]: undefined });

    localStorage.setItem(name, JSON.stringify(updatedFilterList));
  };

  const clearFilter = () => {
    setFilterModel({});
    updateData(data);
    setShowClearButton(false);
  };

  const constructFilterModel = () => {
    let filters = {};

    selectedFilter?.map((item) => {
      if (item.type === 'multiSelect') {
        const values = filterModel?.[item.field];
        if (values) {
          const filter = values.split(',').filter((item) => item !== '');
          filters = {
            ...filters,
            [item.field]: (actualValue) => filter.includes(actualValue?.[item.field]),
          };
        }
      } else {
        const values = filterModel?.[item.field];

        if (values) {
          filters = {
            ...filters,
            [item.field]: (actualValue) =>
              actualValue?.[item.field] === values || actualValue?.[item.field].includes(values),
          };
        }
      }
    });

    const filterArr = Object.values(filters);
    return filterArr;
  };

  const filterData = () => {
    const callbacks = constructFilterModel();
    const items = data?.filter((i) => {
      let select = true;

      callbacks.map((callbacks) => {
        const shouldSelect = callbacks(i);
        console.log(shouldSelect);
        select = select & shouldSelect;
      });

      return select;
    });
    updateData(items);
    setShowClearButton(true);
  };

  return (
    <Stack direction="column">
      <Stack
        flexGrow={1}
        direction="row"
        spacing={2}
        sx={{ boxSizing: 'border-box', px: 1, py: 2 }}>
        <Stack>
          <Button
            id="basic-button"
            aria-controls={isFilterMenuOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isFilterMenuOpen ? 'true' : undefined}
            onClick={handleFilterMenuOpen}
            endIcon={<ArrowDropDownRounded />}
            variant="contained"
            sx={{
              flexGrow: 0,
              borderRadius: 1,
              width: '160px',
              maxWidth: '160px',
              height: 'maxContent',
            }}>
            Select Filter
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorElFilterMenu}
            open={isFilterMenuOpen}
            onClose={handleFilterMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            {filters.map((filter) => (
              <MenuItem key={filter.field} onClick={() => handleFilterSelect(filter)}>
                <Stack direction="row" spacing={2}>
                  {isFilterSelected(filter) && <Check color="success" />}
                  <Typography>{filter.label}</Typography>
                </Stack>
              </MenuItem>
            ))}
          </Menu>
        </Stack>
        <Stack flexGrow={1} direction="row" flexWrap="wrap">
          {selectedFilter?.map((filter) => (
            <GetField
              key={filter.field}
              field={filter}
              removeCallBack={removeFilter}
              valueOptions={valueOptions}
              filterModel={filterModel}
              updateFilterModel={setFilterModel}
            />
          ))}
        </Stack>
        <Stack direction="row" spacing={0.5} flexGrow={0}>
          <Button
            sx={{ flexGrow: 0, borderRadius: 1, height: 'max-content' }}
            endIcon={<Clear />}
            variant="contained"
            disabled={!showClearButton}
            onClick={clearFilter}>
            {'Clear'}
          </Button>
          <Button
            disabled={selectedFilter?.length <= 0}
            sx={{ flexGrow: 0, borderRadius: 1, height: 'max-content' }}
            endIcon={<Search />}
            variant="contained"
            onClick={filterData}>
            {'Search'}
          </Button>
        </Stack>
      </Stack>
      {!isObjectEmpty(filterModel) && selectedFilter?.length > 0 && (
        <Alert severity="warning">
          <AlertTitle>Filtering For</AlertTitle>
          <RenderFilterInfo filterModel={filterModel} selectedFilter={selectedFilter} />
        </Alert>
      )}
    </Stack>
  );
};

export default GlobalFilter;
