export const colors = {
  black: '#33373A',
  green: '#65FF8F',
  orange: '#FF931E',
  white: '#fff',
  success: '#64B967',
  error: '#F0625F',
};

export const cardColors = {
  grey: { canvas: '#777777', text: 'white' },
  red: { canvas: '#EA4335', text: 'white' },
  blue: { canvas: '#4285F4', text: 'white' },
  green: { canvas: '#34A853', text: 'white' },
  black: { canvas: '#000000', text: 'white' },
  yellow: { canvas: '#FBBC05', text: 'black' },
};
