import { store } from '../store';
import { feedbackActions } from '../store/slice/feedbackReducer';
import axios from './axios';

export const Get = async (url, query) => {
  try {
    const response = await axios.get(url, { params: query });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const Patch = async (url, body) => {
  try {
    const response = await axios.patch(url, { ...body });
    return response.data;
  } catch (error) {
    store.dispatch(feedbackActions.NOTIFY({ message: 'Request failed', status: 'error' }));
    return null;
  }
};

export const Delete = async (url) => {
  try {
    const response = await axios.delete(url);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const Post = async (url, body) => {
  try {
    const response = await axios.post(url, { ...body });
    return response.data;
  } catch (error) {
    store.dispatch(feedbackActions.NOTIFY({ message: 'Request failed', status: 'error' }));
    return null;
  }
};

export const PostWithFileUpload = async (url, formData, uploadEvent) => {
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: uploadEvent,
    });

    return response.data;
  } catch (error) {
    return null;
  }
};
