import { Cancel, Check } from '@mui/icons-material';
import {
  Button,
  Chip,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { GridRowModes, GridToolbar } from '@mui/x-data-grid';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateProductAndShopInfo } from '../../api/product';
import { feedbackActions } from '../../store/slice/feedbackReducer';
import { convertCamelCaseToWord } from '../../utils/word';
import BaseTable from '../global/BaseTable';
import NoDataOverlay from '../global/NoDataOverlay';
import SubmitButton from '../global/SubmitButton';
import CustomTooltip from '../global/Tooltip';

const MetaInfoTable = ({ productInfo }) => {
  const columns = [
    {
      field: 'key',
      headerName: 'Field Name',
      width: 200,
      renderCell: (params) => `${convertCamelCaseToWord(params.value)}`,
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 200,
    },
  ];
  const rows = () => {
    const keys = Object.keys(productInfo.meta);
    const valuePair = [];
    keys.map((item, index) =>
      valuePair.push({ id: index, key: item, value: productInfo.meta[item] }),
    );
    return valuePair;
  };
  return (
    <>
      <BaseTable
        columns={columns}
        rows={rows()}
        components={{ Pagination: null }}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            bgcolor: colors.grey[400],
          },
        }}
      />
      <Box boxSizing="border-box" px="15px" py="15px">
        <Stack
          width="100%"
          justifyContent="flex-end"
          sx={{ my: '15px' }}
          direction="row"
          spacing={2}>
          <Button variant="outlined" disabled>
            Add
          </Button>
          <Button variant="contained" disabled>
            Save
          </Button>
        </Stack>
      </Box>
    </>
  );
};

const SingleShopMasterListData = ({ products, loading }) => {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(20);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [dataPatchPromise, setDataPatchPromise] = React.useState(null);
  const [selectedProductInfo, setSelectedProductInfo] = React.useState(null);
  const [showMetaInfo, setShowMetaInfo] = React.useState(false);
  const dispatch = useDispatch();

  const columns = () => {
    const items = [
      {
        field: 'sku',
        headerName: 'SKU',
        width: 150,
        editable: true,
      },
      {
        field: 'productCode',
        headerName: 'Product Code',
        width: 130,
      },
      {
        field: 'name',
        headerName: 'Product Name',
        width: 400,
        renderCell: (params) => (
          <CustomTooltip title={params.value}>
            <Typography>{params.value.substring(0, 40)}</Typography>
          </CustomTooltip>
        ),
        editable: true,
      },
      {
        field: 'variant',
        headerName: 'Variant',
        width: 100,
        editable: true,
      },

      {
        field: 'brand',
        headerName: 'Brand',
        width: 100,
        editable: true,
      },
      {
        field: 'businessUnit',
        headerName: 'Business Unit',
        width: 150,
        editable: true,
      },
      {
        field: 'category',
        headerName: 'Category',
        width: 150,
        type: 'singleSelect',
        editable: true,
      },
      {
        field: 'size',
        headerName: 'Size',
        width: 80,
        editable: true,
      },
      {
        field: 'packageQuantity',
        headerName: 'Package Quantity',
        width: 140,
        editable: true,
      },

      {
        field: 'packageType',
        headerName: 'Package Type',
        width: 150,
        editable: true,
        type: 'singleSelect',
        valueOptions: ['BUNDLE', 'SINGLE', 'CARTOON'],
      },

      {
        field: 'link',
        headerName: 'Link',
        width: 300,
        renderCell: (params) => {
          return (
            <Link href={params.value} target="_blank">
              {params.value}
            </Link>
          );
        },
        editable: true,
      },
      {
        field: 'listingStatus',
        headerName: 'Listing Status',
        width: 150,
        type: 'singleSelect',
        valueOptions: ['LISTED', 'DELISTED'],
        editable: true,
        renderCell: (params) => {
          if (params.value === 'LISTED') {
            return <Chip label="Listed" color="success" icon={<Check />} size="small" />;
          } else {
            return <Chip label="Delisted" color="error" icon={<Cancel />} size="small" />;
          }
        },
      },
      {
        field: 'meta',
        headerName: 'Other Info',
        width: 200,
        renderCell: (params) => {
          if (Object.keys(params.row?.meta).length > 0) {
            return (
              <Button
                onClick={() => {
                  setSelectedProductInfo(params.row);
                  setShowMetaInfo(true);
                }}>
                View
              </Button>
            );
          } else {
            return <></>;
          }
        },
      },
    ];

    return items;
  };

  const updateRow = React.useCallback(
    (newValue, oldValue) =>
      new Promise((resolve, reject) => {
        if (newValue !== oldValue) {
          setDataPatchPromise({ resolve, reject, newValue, oldValue });
        } else {
          resolve(oldValue);
        }
      }),
    [],
  );

  const handleDataPatchNoPress = async () => {
    const { oldValue, resolve } = dataPatchPromise;

    resolve(oldValue);
    setDataPatchPromise(null);
  };

  const handleDataPatchYesPress = async () => {
    const { newValue, oldValue, reject, resolve } = dataPatchPromise;
    const response = await updateProductAndShopInfo(newValue);
    if (response) {
      resolve({ ...newValue, updatedAt: new Date() });
      dispatch(feedbackActions.NOTIFY({ status: 'success', message: 'Changes saved' }));
      setDataPatchPromise(null);
    } else {
      reject(oldValue);
      setDataPatchPromise(null);
    }
  };

  return (
    <Box width="100%" height="500px" minHeight="60vh">
      <Dialog
        open={!!dataPatchPromise}
        onKeyUp={async (event) => {
          if (event.key === 'Enter') {
            await handleDataPatchYesPress();
          }
        }}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>Are you sure you want to save changes?</DialogContent>
        <DialogActions>
          <Button onClick={handleDataPatchNoPress}>No</Button>
          <SubmitButton onClick={handleDataPatchYesPress} variant="contained" autoFocus>
            YES
          </SubmitButton>
        </DialogActions>
      </Dialog>
      <Drawer
        open={showMetaInfo}
        anchor="right"
        onClose={() => {
          setSelectedProductInfo(null);
          setShowMetaInfo(false);
        }}>
        <Box
          width="max(50vw, 290px)"
          height="100%"
          bgcolor="white"
          zIndex={500}
          boxSizing="border-box">
          <Box width="100%" height="350px">
            {selectedProductInfo && selectedProductInfo?.meta && (
              <MetaInfoTable productInfo={selectedProductInfo} />
            )}
          </Box>
        </Box>
      </Drawer>
      {products && (
        <BaseTable
          density="compact"
          columns={columns()}
          rows={products}
          page={page}
          pageSize={pageSize}
          loading={loading}
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: NoDataOverlay,
            NoResultsOverlay: NoDataOverlay,
          }}
          rowsPerPageOptions={[10, 20, 35, 45, 75, 100]}
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={updateRow}
          onProcessRowUpdateError={() => {
            dispatch(
              feedbackActions.NOTIFY({ status: 'error', message: 'Failed to update product info' }),
            );
          }}
          rowModesModel={rowModesModel}
          onRowEditStart={(e) => {
            setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.Edit });
          }}
          onRowEditStop={(e) => {
            setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.View });
          }}
          onRowEditCommit={(e) => {
            setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.View });
          }}
        />
      )}
    </Box>
  );
};

export default SingleShopMasterListData;
