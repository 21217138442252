import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, LinearProgress, Step, StepLabel, Stepper } from '@mui/material';

import Form from '../../components/global/Form';
import Page from '../../components/global/Page';
import Result from '../../components/master-list/update-stock/Result';
import ValidateRawData from '../../components/master-list/update-stock/ValidateRawData';
import CsvFileFileUpload from '../../components/master-list/update-stock/CsvFileFileUpload';

import { fetchAllProducts } from '../../api/product';

const UpdateStock = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [parsedData, setParsedData] = useState([]);
  const [erroredData, setErroredData] = useState([]);

  const company = useSelector((state) => state.company.selectedCompany);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await fetchAllProducts(company.uid);
      setProducts(response.products);
      setLoading(false);
    })();
  }, []);

  const steps = [
    {
      label: 'Upload CSV',
      Element: (
        <CsvFileFileUpload
          products={products}
          toggleStep={setActiveStep}
          saveParsedData={setParsedData}
        />
      ),
    },

    {
      label: 'Validate Data',
      Element: (
        <ValidateRawData
          rawData={parsedData}
          toggleStep={setActiveStep}
          saveParsedData={setParsedData}
          saveErroredData={setErroredData}
        />
      ),
    },

    {
      label: 'Results',
      Element: <Result erroredData={erroredData} rawData={parsedData} />,
    },
  ];
  return (
    <Page>
      <Form>
        <Stepper activeStep={activeStep}>
          {steps.map((step) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={step.label} {...stepProps}>
                <StepLabel {...labelProps}>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {!loading ? (
          steps[activeStep].Element
        ) : (
          <Box sx={{ width: '80%', margin: '100px auto' }}>
            <LinearProgress />
          </Box>
        )}
      </Form>
    </Page>
  );
};

export default UpdateStock;
