import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  colors,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Page from '../../../components/global/Page';
import { COMPANY_SHOPS } from '../../../constants/routerUrls';
import { ArrowCircleRight, Delete } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  DARK_STORES,
  DARK_STORE_ENABLED_SHOPS,
  SEARCH_BASED_SHOP,
} from '../../../constants/constant';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompanyShopSchema } from '../../../validation/shop';
import { useDispatch, useSelector } from 'react-redux';
import { addShopToCompanyExtended } from '../../../api/company';
import { feedbackActions } from '../../../store/slice/feedbackReducer';

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const AddNewShop = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [selectedShop, setSelectedShop] = useState(null);
  const [showKeywardForm, setShowKeywardForm] = useState(false);

  const [showDarkStoreForm, setShowDarkStoreFrom] = useState(false);
  const [selectedDarkStore, setSelectedDarkStore] = useState([]);

  const company = useSelector((state) => state.company.selectedCompany);
  const dispatch = useDispatch();

  const resolver = yupResolver(CompanyShopSchema);

  const {
    control,
    setValue: setKeywordValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver,
    mode: 'onChange',
  });

  const { append, remove, fields } = useFieldArray({ control, name: 'keywords' });

  useEffect(() => {
    if (!state?.unAssignedShops) {
      navigate(COMPANY_SHOPS);
    }
  }, []);

  const isShopSelected = (shop) => {
    if (shop.shopCode === selectedShop?.shopCode) {
      return true;
    }
    return false;
  };

  const selectShop = (shop) => {
    setKeywordValue('keywords', []);
    setSelectedDarkStore([]);
    if (shop.shopCode === selectedShop?.shopCode) {
      setSelectedShop(null);
    } else {
      setSelectedShop(shop);
    }
  };

  const shouldPromptForKeyward = () => {
    const shopCode = selectedShop?.shopCode?.split('-')?.[0];
    if (SEARCH_BASED_SHOP.includes(shopCode)) {
      setShowKeywardForm(true);
    } else {
      setShowKeywardForm(false);
    }
  };

  useEffect(() => {
    if (selectedShop) {
      shouldPromptForKeyward();
    } else {
      setShowKeywardForm(false);
    }
  }, [selectedShop]);

  const shouldPromptForDarkStore = () => {
    const shopCode = selectedShop?.shopCode?.split('-')?.[0];
    if (DARK_STORE_ENABLED_SHOPS.includes(shopCode)) {
      setShowDarkStoreFrom(true);
    } else {
      setShowDarkStoreFrom(false);
    }
  };

  useEffect(() => {
    if (selectedShop) {
      shouldPromptForDarkStore();
    } else {
      setShowDarkStoreFrom(false);
    }
  }, [selectedShop]);

  const getDarkStoreOptions = () => {
    const shopCode = selectedShop?.shopCode?.split('-')?.[0];

    const options = DARK_STORES?.[shopCode];
    if (options) {
      return options;
    } else {
      return [];
    }
  };

  const onKeywordAdd = (event, index) => {
    const keyword = event.target.value.toLowerCase();
    const shopCode = selectedShop?.shopCode?.split('-')?.[0];

    let url = '';
    switch (shopCode) {
      case 'FP':
        url = `https://www.fairprice.com.sg/brand/${keyword}`;
        break;
      case 'GT':
        url = `https://giant.sg/${keyword}`;
        break;
      case 'CSG':
        url = `https://coldstorage.com.sg/${keyword}`;
        break;
      case 'GUR':
        url = '';
        break;
      case 'PDM':
        url = '';
        break;
      case 'WT':
        url = `https://www.watsons.com.sg/search?q=${keyword}:mostRelevant:`;
        break;
    }
    const encodedUrl = encodeURI(url);
    setKeywordValue(`keywords.${index}.searchLink`, encodedUrl);
  };

  const handleSave = async (data) => {
    const { keywords } = data;
    const response = await addShopToCompanyExtended({
      companyUid: company.uid,
      shopCode: selectedShop.shopCode,
      keywords,
      darkStores: selectedDarkStore,
    });

    if (response) {
      dispatch(
        feedbackActions.NOTIFY({ status: 'success', message: `${selectedShop.name} added` }),
      );
      navigate(COMPANY_SHOPS);
    } else {
      dispatch(
        feedbackActions.NOTIFY({ status: 'error', message: `Failed to add ${selectedShop.name}` }),
      );
    }
  };

  const keywords = useWatch({ control, name: 'keywords' });

  return (
    <Page>
      <Typography sx={{ my: 2 }} variant="h5" fontWeight={800}>
        Select A Shop
      </Typography>
      <Grid container gap={2}>
        {state.unAssignedShops?.map((shop) => (
          <Grid
            item
            xs={12}
            md={4}
            lg={2}
            key={shop.shopCode}
            sx={{ cursor: 'pointer' }}
            onClick={() => selectShop(shop)}>
            <Card
              sx={{
                bgcolor: isShopSelected(shop) ? colors.blueGrey[100] : 'white',
              }}>
              <CardContent>
                <Stack spacing={2}>
                  <Typography sx={{ my: 2 }} variant="h5" fontWeight={800}>
                    {shop.name}
                  </Typography>
                  <Typography fontWeight={600}>Code: {shop.shopCode}</Typography>
                  <Typography fontWeight={800}>Code: {shop.shopCode}</Typography>
                  <Button
                    sx={{ maxWidth: 'max-content', px: 0 }}
                    endIcon={<ArrowCircleRight />}
                    component="a"
                    href={shop.baseUrl}
                    target="_blank">
                    View Shop
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {showKeywardForm && (
        <Box>
          <Typography sx={{ my: 2 }} variant="h5" fontWeight={800}>
            Configure Shop
          </Typography>
          <Stack spacing={2}>
            {fields?.map((item, index) => (
              <Stack key={JSON.stringify(item)} direction="row" spacing={2}>
                <IconButton sx={{ height: 'max-content' }} onClick={() => remove(index)}>
                  <Delete />
                </IconButton>
                <FormControl
                  sx={{ minWidth: '290px' }}
                  required
                  error={errors?.keywords?.[index]?.keyword}>
                  <InputLabel>Keyword</InputLabel>
                  <OutlinedInput
                    label="Keyword"
                    {...register(`keywords.${index}.keyword`)}
                    placeholder="Please enter keyword"
                    onChange={(event) => onKeywordAdd(event, index)}
                  />
                  {errors?.keywords?.[index]?.keyword && (
                    <FormHelperText>{errors?.keywords?.[index]?.keyword?.message}</FormHelperText>
                  )}
                </FormControl>
                <TextField
                  autoFocus
                  placeholder="Please enter link"
                  value={keywords?.[index]?.searchLink}
                  {...register(`keywords.${index}.searchLink`)}
                  sx={{ width: '550px' }}
                />
              </Stack>
            ))}
            {errors?.keywords && (
              <FormHelperText sx={{ color: 'red' }}>{errors?.keywords?.message}</FormHelperText>
            )}
          </Stack>

          <Button
            variant="contained"
            sx={{ mt: '15px' }}
            onClick={() => append({ keyword: '', searchLink: '' })}>
            ADD KEYWORD
          </Button>
        </Box>
      )}

      {showDarkStoreForm && (
        <Box>
          <Typography fontSize="1.6rem" fontWeight={700} sx={{ my: 2 }}>
            Configure Stores
          </Typography>

          <Autocomplete
            disableCloseOnSelect
            multiple
            onChange={(event, value) => setSelectedDarkStore(value)}
            options={getDarkStoreOptions()}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Add stores" sx={{ maxWidth: '600px' }} />
            )}
          />
          {selectedDarkStore?.length <= 0 && (
            <FormHelperText sx={{ color: 'red' }}>Please select stores</FormHelperText>
          )}
        </Box>
      )}
      <Stack direction="row" justifyContent="flex-start" spacing={2} sx={{ mt: 3 }}>
        <Button variant="outlined" onClick={() => navigate(COMPANY_SHOPS)}>
          GO BACK
        </Button>
        <Button variant="contained" onClick={handleSubmit(handleSave)} disabled={!selectedShop}>
          Save Configuration
        </Button>
      </Stack>
    </Page>
  );
};

export default AddNewShop;
