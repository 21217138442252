import React from 'react';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Schedule } from '@mui/icons-material';
import { fetchCompanyShops } from '../../api/shop';
import { useSelector } from 'react-redux';
import { useWatch } from 'react-hook-form';

const SchedulerBasicInfo = ({
  toggleStep,
  register,
  submit,
  formErrors,
  setFormValue,
  control,
}) => {
  const [shopList, setShopList] = React.useState([]);

  const company = useSelector((state) => state.company.selectedCompany);

  const fetchShopList = async () => {
    const response = await fetchCompanyShops({ companyUid: company?.uid });
    setShopList(response);
  };

  React.useEffect(() => {
    (async () => await fetchShopList())();
  }, [company]);

  const slots = [
    {
      label: 'Morning 7:00 AM - 1:00 PM',
      value: 'MORNING',
    },
    {
      label: 'Evening 2:00 PM - 10:00 PM',
      value: 'EVENING',
    },
    {
      label: 'Night 11:00 PM - 6:00 AM',
      value: 'NIGHT',
    },
  ];

  const handleNext = () => toggleStep(1);
  const selectedShop = useWatch({ control, name: 'shop' });
  const selectedSlot = useWatch({ control, name: 'slot' });

  return (
    <Box>
      <Grid container gap={2}>
        <Grid item xs={12} md={5.93}>
          <FormControl fullWidth required error={formErrors?.title}>
            <InputLabel>Title</InputLabel>
            <OutlinedInput
              label="Title"
              placeholder="Give this schedule a name"
              {...register('title')}
            />
            {formErrors?.title && <FormHelperText>{formErrors?.title?.message}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5.93}>
          <FormControl fullWidth>
            <InputLabel>Description</InputLabel>
            <OutlinedInput
              label="Description"
              placeholder="More information about this schedule"
              multiline
              {...register('description')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5.93}>
          <FormControl fullWidth error={formErrors?.slot} required>
            <InputLabel>Finder Slot</InputLabel>
            <Select
              value={selectedSlot}
              {...register('slot')}
              label="Finder Slot"
              placeholder="When should this scrapper run?"
              renderValue={(selected) => (
                <Stack direction="row" spacing={1}>
                  <Schedule />
                  <Typography>{slots.find((slot) => slot.value === selected)?.label}</Typography>
                </Stack>
              )}>
              {slots.map((slot) => (
                <MenuItem key={slot.value} value={slot.value}>
                  <ListItem>
                    <ListItemIcon>
                      <Schedule />
                    </ListItemIcon>
                    <ListItemText>{slot.label}</ListItemText>
                  </ListItem>
                </MenuItem>
              ))}
            </Select>
            {formErrors?.slot && <FormHelperText>{formErrors?.slot?.message}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5.93}>
          <FormControl fullWidth error={formErrors?.shop} required>
            <Autocomplete
              value={selectedShop}
              options={shopList}
              onChange={(event, value) => setFormValue('shop', value)}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.shopCode === value.shopCode}
              renderOption={(props, option) => <li {...props}>{option.name}</li>}
              renderInput={(params) => (
                <TextField {...params} label="Shops" placeholder="Search Shops" />
              )}
            />
            {formErrors?.shop && <FormHelperText>{formErrors?.shop?.message}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
      <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
        <Button onClick={submit(handleNext)} variant="contained">
          Next
        </Button>
      </Stack>
    </Box>
  );
};

export default SchedulerBasicInfo;
