import React from 'react';
import {
  Box,
  Button,
  colors,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { CancelRounded } from '@mui/icons-material';
import { convertWordToCamelCase } from '../../../utils/word';
import { castStrByFieldType } from '../../../utils/casting';
import { useDispatch, useSelector } from 'react-redux';
import { addSingleProduct } from '../../../api/product';
import { useNavigate } from 'react-router-dom';
import { MASTER_LIST } from '../../../constants/routerUrls';
import { feedbackActions } from '../../../store/slice/feedbackReducer';

const MetaInfoForm = ({
  toggleStep,
  formFields,
  formAppend,
  formRemove,
  watch,
  formErrors,
  formSubmit,
  register,
}) => {
  const company = useSelector((state) => state.company.selectedCompany);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const save = async (data) => {
    const {
      name,
      brand,
      businessUnit,
      category,
      listingStatus,
      meta,
      variant,
      size,
      packageType,
      barcode,
      shops,
      brandCode,
    } = data;

    let metaArrayToObject = {};

    meta.map((item) => {
      const { fieldType, identifier, value } = item;
      const key = convertWordToCamelCase(identifier);
      const formatedValue = castStrByFieldType(value, fieldType);

      metaArrayToObject = { ...metaArrayToObject, [key]: formatedValue };
    });
    const insertData = {
      name,
      brand,
      businessUnit,
      category,
      listingStatus,
      meta: JSON.stringify(metaArrayToObject),
      variant,
      size,
      packageType,
      barcode,
      companyUid: company.uid,
      shops,
      brandCode,
    };

    const response = await addSingleProduct(insertData);

    if (response.status === 200) {
      navigate(MASTER_LIST);
      dispatch(
        feedbackActions.NOTIFY({ status: 'success', message: `${name} added successfully` }),
      );
    }
  };

  return (
    <Box width="100%" my="5rem">
      <Stack width="100%" spacing={2} sx={{ margin: '20px 0' }}>
        <Typography fontWeight="bold">
          You can add any extra field for this product (optional)
        </Typography>
        {formFields.map((field, index) => (
          <Stack direction="row" key={index} spacing={2} sx={{ mb: '10px' }} alignItems="center">
            <Typography>{index + 1}.</Typography>
            <FormControl
              sx={{ width: '250px' }}
              required
              error={formErrors?.meta?.[index]?.fieldType}>
              <InputLabel>Field Type</InputLabel>
              <Select
                defaultValue="TEXT"
                label="Field Type"
                {...register(`meta.${index}.fieldType`)}>
                <MenuItem value="TEXT">Text</MenuItem>
                <MenuItem value="LARGE TEXT">Large Text</MenuItem>
                <MenuItem value="NUMBER">Number</MenuItem>
              </Select>
              {formErrors?.meta?.[index]?.fieldType && (
                <FormHelperText>{formErrors?.meta?.[index]?.fieldType?.message}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              sx={{ flexGrow: 1 }}
              required
              error={formErrors?.meta?.[index]?.identifier}>
              <InputLabel>Identifier</InputLabel>
              <OutlinedInput
                label="Identifier"
                placeholder="Value name"
                {...register(`meta.${index}.identifier`)}
              />
              {formErrors?.meta?.[index]?.identifier && (
                <FormHelperText>{formErrors?.meta?.[index]?.identifier?.message}</FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ flexGrow: 1 }} required error={formErrors?.meta?.[index]?.value}>
              <InputLabel>Value</InputLabel>
              <OutlinedInput
                label="Value"
                placeholder="value for the field"
                {...register(`meta.${index}.value`)}
              />
              {formErrors?.meta?.[index]?.value && (
                <FormHelperText>{formErrors?.meta?.[index]?.value?.message}</FormHelperText>
              )}
            </FormControl>
            <IconButton onClick={() => formRemove(index)} sx={{ height: 'max-content' }}>
              <CancelRounded />
            </IconButton>
          </Stack>
        ))}
      </Stack>
      <Button
        variant="contained"
        sx={{ my: '10px', bgcolor: colors.grey[600] }}
        onClick={() => formAppend({ key: '', value: '' })}>
        {watch?.length > 0 ? 'ADD MORE' : 'Add meta infomation'}
      </Button>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Button variant="contained" onClick={() => toggleStep(1)}>
          Previous
        </Button>
        <Button variant="contained" onClick={formSubmit(save)}>
          Save
        </Button>
      </Stack>
    </Box>
  );
};

export default MetaInfoForm;
