import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCompanies } from '../../api/company';
import { companyActions } from '../../store/slice/companyReducer';
import SubmitButton from '../global/SubmitButton';

const SelectCompanyDialog = ({ show, toggle, secondSelect }) => {
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();

  const dispatch = useDispatch();

  const fetchCompanySuggestion = async (name) => {
    setIsLoading(true);
    const response = await fetchAllCompanies({ name, pageSize: 20000 });
    setCompanies(response.companies);
    setIsLoading(false);
  };

  const handleSelection = () => {
    dispatch(
      companyActions.selectCompany({
        uid: selectedCompany.uid,
        name: selectedCompany.name,
        region: selectedCompany.region,
      }),
    );
    toggle(false);
  };

  const company = useSelector((state) => state.company.selectedCompany);

  return (
    <Dialog
      open={show}
      maxWidth="xs"
      fullWidth={true}
      onClose={() => {
        if (secondSelect) {
          toggle(false);
        } else {
          toggle(true);
        }
      }}>
      <DialogTitle>Select Company</DialogTitle>
      <DialogContent>
        {secondSelect && (
          <Typography>
            Currently {company?.name} selected in {company?.region} region
          </Typography>
        )}
        <Autocomplete
          sx={{ marginTop: '15px' }}
          options={companies}
          filterSelectedOptions
          autoComplete
          filterOptions={(x) => x}
          getOptionLabel={(option) => `${option.name} - (${option.region})`}
          isOptionEqualToValue={(option, value) => option.uid === value.uid}
          onChange={(event, company) => setSelectedCompany(company)}
          onInputChange={async (event, value) => {
            await fetchCompanySuggestion(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Company Name"
              placeholder="Enter company name, ie - Reckitt"
              autoFocus
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <DialogActions sx={{ marginTop: '10px', marginRight: '-10px' }}>
          <SubmitButton variant="contained" onClick={handleSelection} disabled={!selectedCompany}>
            Ok
          </SubmitButton>
          {secondSelect && (
            <Button variant="outlined" onClick={() => toggle(false)}>
              Cancel
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default SelectCompanyDialog;
