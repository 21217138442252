import Signin from '../pages/auth/Signin';
import AddNewCompany from '../pages/company/AddNewCompany';
import CompanyList from '../pages/company/CompanyList';
import Dashboard from '../pages/global/Dashboard';
import AddBulkProduct from '../pages/master-list/AddBulkProduct';
import AddSingleProduct from '../pages/master-list/AddSingleProduct';
import MasterList from '../pages/master-list/MasterList';
import UpdateStock from '../pages/master-list/UpdateStock';
import AddNewSchedule from '../pages/scheduler/AddNewSchedule';
import Scheduler from '../pages/scheduler/Scheduler';
import AddNewShop from '../pages/shop/company/AddNewShop';
import CompanyBasedShops from '../pages/shop/company/CompanyBasedShops';
import UpdateCompanyShop from '../pages/shop/company/UpdateCompanyShop';
import ShopList from '../pages/shop/ShopList';
import StockStatus from '../pages/stock-status/StockStatus';
import CreateNew from '../pages/tracking-report/CreateNew';
import ReportOverview from '../pages/tracking-report/ReportOverview';
import TrackingReport from '../pages/tracking-report/TrackingReport';
import AddNewUser from '../pages/users/AddNewUser';
import UserList from '../pages/users/UserList';
import {
  ADD_BULK_PRODUCT,
  ADD_NEW_COMPANY,
  ADD_NEW_COMPANY_SHOPS,
  ADD_NEW_SCHEDULER,
  ADD_SINGLE_PRODUCT,
  COMPANY,
  COMPANY_SHOPS,
  DASHBOARD,
  MASTER_LIST,
  NEW_USER,
  SCHEDULER,
  SHOP,
  SIGN_IN,
  UPDATE_COMPANY_SHOPS,
  STOCK_STATUS,
  UPDATE_STOCK,
  USERS,
  TRACKING_REPORTS,
  CREATE_NEW_REPORT,
  REPORT_OVERVIEW,
} from './routerUrls';

export const routes = [
  // * Dashboard
  {
    isProtected: true,
    label: 'Dashboard',
    path: DASHBOARD,
    Element: Dashboard,
    isIndexedUrl: false,
  },

  // * Company
  {
    isProtected: true,
    label: 'Company',
    path: COMPANY,
    Element: CompanyList,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'New Company',
    path: ADD_NEW_COMPANY,
    Element: AddNewCompany,
    isIndexedUrl: false,
  },

  // * Auth
  {
    isProtected: false,
    label: 'Signin',
    path: SIGN_IN,
    Element: Signin,
    isIndexedUrl: true,
  },

  // * Users
  {
    isProtected: true,
    label: 'User',
    path: USERS,
    Element: UserList,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'New User',
    path: NEW_USER,
    Element: AddNewUser,
    isIndexedUrl: false,
  },

  // * Master list
  {
    isProtected: true,
    label: 'Master List',
    path: MASTER_LIST,
    Element: MasterList,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'Add Single Product',
    path: ADD_SINGLE_PRODUCT,
    Element: AddSingleProduct,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'Add Bulk Product',
    path: ADD_BULK_PRODUCT,
    Element: AddBulkProduct,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'Update Product Stock',
    path: UPDATE_STOCK,
    Element: UpdateStock,
    isIndexedUrl: false,
  },

  // * Shop list
  {
    isProtected: true,
    label: 'Shop List',
    path: SHOP,
    Element: ShopList,
    isIndexedUrl: false,
  },

  // * Tracking Reports
  {
    isProtected: true,
    label: 'Tracker Reports',
    path: TRACKING_REPORTS,
    Element: TrackingReport,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    path: CREATE_NEW_REPORT,
    label: 'Create New Report',
    Element: CreateNew,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    path: REPORT_OVERVIEW,
    label: 'Report Overview',
    Element: ReportOverview,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'Stock Status',
    path: STOCK_STATUS,
    Element: StockStatus,
    isIndexedUrl: false,
  },

  // * Scheduler
  {
    isProtected: true,
    label: 'Scheules',
    path: SCHEDULER,
    Element: Scheduler,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'New Scheule',
    path: ADD_NEW_SCHEDULER,
    Element: AddNewSchedule,
    isIndexedUrl: false,
  },

  // * Company Shops
  {
    isProtected: true,
    label: 'Stores',
    path: COMPANY_SHOPS,
    Element: CompanyBasedShops,
    isIndexedUrl: false,
  },

  {
    isProtected: true,
    label: 'New Store',
    path: ADD_NEW_COMPANY_SHOPS,
    Element: AddNewShop,
    isIndexedUrl: false,
  },

  {
    isProtected: true,
    label: 'Update Store',
    path: UPDATE_COMPANY_SHOPS,
    Element: UpdateCompanyShop,
    isIndexedUrl: false,
  },
];
