import {
  Alert,
  AlertTitle,
  colors,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import Screen from '../../components/global/Screen';
import { DASHBOARD } from '../../constants/routerUrls';
import { authActions } from '../../store/slice/authReducer';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { signinValidationSchema } from '../../validation/auth';
import { signinUser } from '../../api/auth';
import { feedbackActions } from '../../store/slice/feedbackReducer';
import SubmitButton from '../../components/global/SubmitButton';

const Signin = () => {
  const resolver = yupResolver(signinValidationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver, defaultValues: { email: '', password: '' }, mode: 'onChange' });
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [isEmailPasswordWrong, setIsEmailPasswordWrong] = useState(false);

  const signin = async (data) => {
    const { email, password } = data;
    const response = await signinUser(email, password);
    if (response) {
      dispatch(authActions.SIGNIN({ user: response.user, token: response.token }));
      dispatch(
        feedbackActions.NOTIFY({ status: 'success', message: `Welcome ${response.user.name}` }),
      );
      setIsEmailPasswordWrong(false);
    } else {
      setIsEmailPasswordWrong(true);
    }
  };

  /**
   * if user authenticated redirect to
   * dashboard
   */
  if (auth.isAuth) {
    return <Navigate to={DASHBOARD} state={{ from: location.pathname }} />;
  }

  /**
   * else show signin page
   */
  return (
    <Screen sx={{ bgcolor: colors.grey[100] }}>
      <Stack width="100vw" height="100vh" justifyContent="center" alignItems="center">
        <Box
          bgcolor="#fff"
          width="400px"
          minWidth="290px"
          boxSizing="border-box"
          px="20px"
          py="15px"
          borderRadius={1}>
          <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
            <Typography variant="h4">Welcome</Typography>
            <Typography>Sign in to Pulse Manager</Typography>
            {isEmailPasswordWrong && (
              <Alert severity="warning">
                <AlertTitle>Your email or password might be wrong</AlertTitle>
              </Alert>
            )}
            <FormControl fullWidth={true} required={true} error={errors?.email}>
              <InputLabel>Email</InputLabel>
              <OutlinedInput
                placeholder="Email, ie - abir@manush.tech"
                label="Email"
                type="email"
                name="email"
                {...register('email')}
              />
              <FormHelperText>{errors?.email?.message}</FormHelperText>
            </FormControl>
            <FormControl fullWidth={true} required={true} error={errors?.password}>
              <InputLabel>Password</InputLabel>
              <OutlinedInput
                placeholder="Password, ie - 123456"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                name="password"
                {...register('password')}
              />
              <FormHelperText>{errors?.password?.message}</FormHelperText>
            </FormControl>
            <SubmitButton
              autoFocus
              type="submit"
              onClick={handleSubmit(signin)}
              variant="contained"
              sx={{ boxShadow: 0 }}
              fullWidth={true}>
              SIGN IN
            </SubmitButton>
          </Stack>
        </Box>
      </Stack>
    </Screen>
  );
};

export default Signin;
