import { createSlice } from '@reduxjs/toolkit';

const initialState = { shops: [] };

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    fetchShop: (state, action) => {
      state.shops = action.payload.shops;
    },
    addShop: (state, action) => {
      state.shops.push(action.payload);
    },
    updateShop: (state, action) => {
      state.shops = state.shops.map((item) =>
        item.shopCode === action.payload.shopCode ? { ...action.payload } : item,
      );
    },
  },
});

export const shopActions = shopSlice.actions;
export default shopSlice.reducer;
