/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';

import {
  fetchKewordAndDarkStoreData,
  removeDarkStore,
  removeKeyword,
  updateKeywordAndDarkStore,
} from '../../../api/shop';
import Page from '../../../components/global/Page';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompanyShopSchema } from '../../../validation/shop';
import { DARK_STORES } from '../../../constants/constant';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { COMPANY_SHOPS } from '../../../constants/routerUrls';
import { useDispatch } from 'react-redux';
import { feedbackActions } from '../../../store/slice/feedbackReducer';
import { Delete } from '@mui/icons-material';

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const UpdateCompanyShop = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedDarkStores, setSelectedDarkStores] = useState([]);
  const [inUseStores, setInUseStores] = useState([]);

  const resolver = yupResolver(CompanyShopSchema);
  const {
    setValue,
    control,
    register,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm({ mode: 'onChange', resolver });
  const { append, remove, fields } = useFieldArray({ control, name: 'keywords' });

  const dispatch = useDispatch();

  const fetchData = async () => {
    setLoading(true);

    const shopMeta = await fetchKewordAndDarkStoreData(
      location.state.companyUid,
      location.state.shopCode,
    );

    if (shopMeta.darkStores) {
      setInUseStores(shopMeta.darkStores);
    }

    if (shopMeta.keywords) {
      const keywords = shopMeta.keywords?.map((item) => ({
        uid: item.uid,
        keyword: item.keyword,
        searchLink: item.searchUrl,
      }));
      setValue('keywords', keywords);
    }

    setLoading(false);
  };

  useEffect(() => {
    (async () => await fetchData())();
  }, [location.state]);

  const onKeywordAdd = (event, index) => {
    const keyword = event.target.value.toLowerCase();
    const shopCode = location.state?.shopCode?.split('-')?.[0];

    let url = '';
    switch (shopCode) {
      case 'FP':
        url = `https://www.fairprice.com.sg/brand/${keyword}`;
        break;
      case 'GT':
        url = `https://giant.sg/${keyword}`;
        break;
      case 'CSG':
        url = `https://coldstorage.com.sg/${keyword}`;
        break;
      case 'GUR':
        url = '';
        break;
      case 'PDM':
        url = '';
        break;
      case 'WT':
        url = `https://www.watsons.com.sg/search?q=${keyword}:mostRelevant:`;
        break;
    }
    const encodedUrl = encodeURI(url);
    setValue(`keywords.${index}.searchLink`, encodedUrl);
  };

  const getDarkStoreOptions = () => {
    const shopCode = location.state?.shopCode?.split('-')?.[0];

    const options = DARK_STORES?.[shopCode];
    if (options) {
      const usingsStores = inUseStores.map((item) => item.name);

      return options.filter((item) => !usingsStores.includes(item.name));
    } else {
      return [];
    }
  };

  const handleSave = async (data) => {
    const { keywords } = data;
    if (isDirty || selectedDarkStores?.length > 0) {
      const response = await updateKeywordAndDarkStore({
        companyUid: location.state.companyUid,
        shopCode: location.state.shopCode,
        keywords,
        darkStores: selectedDarkStores,
      });
      if (response) {
        dispatch(feedbackActions.NOTIFY({ status: 'success', message: 'Information saved' }));
        navigate(COMPANY_SHOPS);
      } else {
        dispatch(feedbackActions.NOTIFY({ status: 'error', message: 'Failed to save changes' }));
      }
    } else {
      dispatch(feedbackActions.NOTIFY({ status: 'warning', message: 'Nothing changed' }));
    }
  };

  const keywords = useWatch({ control, name: 'keywords' });

  const handleKeywordRemove = async (index) => {
    const keyword = keywords?.find((item, keywordIndex) => keywordIndex === index);

    if (keyword?.uid?.length > 0) {
      const response = await removeKeyword(keyword?.uid);
      if (response) {
        remove(index);
      } else {
        dispatch({ status: 'error', message: `Failed to remove ${keyword?.keyword}` });
      }
    } else {
      remove(index);
    }
  };

  const handleDarStoreRemove = async (uid, name) => {
    const response = await removeDarkStore(uid);
    if (response) {
      const darkStores = inUseStores.filter((item) => item.uid !== uid);
      setInUseStores(darkStores);
    } else {
      dispatch(feedbackActions.NOTIFY({ status: 'error', message: `Failed to remove ${name}` }));
    }
  };
  return (
    <Page>
      {loading ? (
        <Stack width="100%" alignItems="center" justifyContent="center" sx={{ height: '60vh' }}>
          <CircularProgress />
        </Stack>
      ) : (
        <Box>
          <Typography sx={{ my: 2 }} variant="h5" fontWeight={800}>
            Keywords
          </Typography>
          <Stack spacing={2}>
            {fields.map((field, index) => (
              <Stack key={index} direction="row" sx={{ maxWidth: '600px' }} spacing={2}>
                <IconButton
                  sx={{ height: 'max-content' }}
                  onClick={async () => await handleKeywordRemove(index)}>
                  <Delete />
                </IconButton>
                <FormControl required error={errors?.keywords?.[index]?.keyword}>
                  <InputLabel>Keyword</InputLabel>
                  <OutlinedInput
                    label="Keyword"
                    {...register(`keywords.${index}.keyword`)}
                    onChange={(event) => onKeywordAdd(event, index)}
                  />
                  {errors?.keywords?.[index]?.keyword && (
                    <FormHelperText>{errors?.keywords?.[index]?.keyword?.message}</FormHelperText>
                  )}
                </FormControl>
                <TextField
                  autoFocus
                  placeholder="Please enter keyword"
                  value={keywords?.[index]?.searchLink}
                  {...register(`keywords.${index}.searchLink`)}
                  sx={{ width: '550px' }}
                />
              </Stack>
            ))}
            <Button
              variant="contained"
              sx={{ mt: '15px', width: 'max-content' }}
              onClick={() => append({ keyword: '', searchLink: '' })}>
              ADD KEYWORD
            </Button>
          </Stack>

          <Typography sx={{ my: 2 }} variant="h5" fontWeight={800}>
            Stores
          </Typography>
          <Typography sx={{ my: 2 }}>Already Added</Typography>
          <Stack maxWidth="600px" flexWrap="wrap" direction="row" sx={{ my: 3 }}>
            {inUseStores?.map((item) => (
              <Chip
                key={item.uid}
                label={item.name}
                sx={{ maxWidth: 'max-content', mr: 1, mb: 1 }}
                color="info"
                onDelete={async () => await handleDarStoreRemove(item.uid, item.name)}
              />
            ))}
          </Stack>
          <Autocomplete
            disableCloseOnSelect
            multiple
            value={selectedDarkStores}
            onChange={(event, value) => setSelectedDarkStores(value)}
            options={getDarkStoreOptions()}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Add more stores" sx={{ maxWidth: '600px' }} />
            )}
          />
          {selectedDarkStores?.length <= 0 && (
            <FormHelperText sx={{ color: 'red' }}>Please select stores</FormHelperText>
          )}
        </Box>
      )}
      <Stack direction="row" justifyContent="flex-start" spacing={2} sx={{ my: 3 }}>
        <Button component={Link} to={COMPANY_SHOPS} variant="outlined">
          GO Back
        </Button>
        <Button variant="contained" onClick={handleSubmit(handleSave)}>
          Save Changes
        </Button>
      </Stack>
    </Page>
  );
};

export default UpdateCompanyShop;
