/* eslint-disable no-unused-vars */
import { CancelRounded } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyWithShopInfomation } from '../../../api/dashboard-stat';
import { feedbackActions } from '../../../store/slice/feedbackReducer';
import CustomTooltip from '../../global/Tooltip';

const ShopInfoForm = ({
  toggleStep,
  formFields,
  watch,
  formAppend,
  formRemove,
  formSubmit,
  formErrors,
  register,
}) => {
  const [shops, setShops] = useState([]);
  const [selected, setSelected] = useState({});

  const dispatch = useDispatch();

  const company = useSelector((state) => state.company.selectedCompany);
  useEffect(() => {
    (async () => {
      const response = await fetchCompanyWithShopInfomation(company.uid);
      setShops(response.company.shops);
    })();
  }, [company]);

  const [selectedShops, setSelectedShops] = useState([]);

  const handleShopSelect = (val) => {
    if (!selectedShops.includes(val.shopCode)) {
      setSelectedShops([...selectedShops, val.shopCode]);
      formAppend({ name: val.name, shopCode: val.shopCode, sku: '', link: '' });
      setSelected({});
    } else {
      dispatch(
        feedbackActions.NOTIFY({ status: 'warning', message: `${val.name} already selected` }),
      );
      setSelected({});
    }
  };

  const handleItemRemove = (index, value) => {
    const filterSelectedShops = selectedShops.filter((item) => item !== value.shopCode);
    setSelectedShops(filterSelectedShops);

    formRemove(index);
  };

  const next = () => {
    toggleStep(2);
  };

  return (
    <Box width="100%" my="5rem">
      <Stack width="100%" spacing={2} sx={{ margin: '20px 0' }}>
        <Typography fontWeight="bold">Select the shops this product will be listed on :</Typography>
        <Autocomplete
          clearOnBlur={true}
          blurOnSelect={true}
          value={selected}
          autoComplete
          options={shops.filter((item) => !selectedShops.includes(item?.shopCode))}
          filterOptions={(x) => x}
          onChange={(event, value) => handleShopSelect(value)}
          getOptionLabel={(option) => (option?.name ? `${option.name} (${option.region})` : '')}
          sx={{ width: '100%', maxWidth: '550px' }}
          renderInput={(params) => <TextField {...params} placeholder="Search Shops" />}
        />
        <Box mb="20px" />
        <Divider />
        <Box mb="10px" />

        {formFields?.map((field, index) => (
          <Stack direction="row" key={index} spacing={2} sx={{ mb: '10px' }} alignItems="center">
            <TextField
              focused
              label="Shop"
              InputProps={{ readOnly: true, value: field.name }}
              sx={{ flexGrow: 1 }}
            />
            <FormControl sx={{ flexGrow: 1 }} required error={!!formErrors?.shops?.[index]?.sku}>
              <InputLabel>Product SKU</InputLabel>
              <OutlinedInput label="Product SKU" {...register(`shops.${index}.sku`)} />
              {formErrors?.shops?.[index]?.sku && (
                <FormHelperText>{formErrors?.shops?.[index]?.sku?.message}</FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ flexGrow: 1 }}>
              <InputLabel>Product Link</InputLabel>
              <OutlinedInput label="Product Link" {...register(`shops.${index}.link`)} />
            </FormControl>
            <IconButton
              onClick={() => handleItemRemove(index, field)}
              sx={{ height: 'max-content' }}>
              <CancelRounded />
            </IconButton>
          </Stack>
        ))}
      </Stack>

      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Button variant="contained" onClick={() => toggleStep(0)}>
          Previous
        </Button>
        {watch?.length <= 0 ? (
          <CustomTooltip title="Please select a shop">
            <Box>
              <Button variant="contained" disabled={watch?.length <= 0} onClick={formSubmit(next)}>
                Next
              </Button>
            </Box>
          </CustomTooltip>
        ) : (
          <Button variant="contained" onClick={formSubmit(next)}>
            Next
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default ShopInfoForm;
