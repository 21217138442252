import { Chip, CircularProgress, colors, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { fetchCompanyWithShopInfomation } from '../../api/dashboard-stat';
import CustomTooltip from '../global/Tooltip';

const CompanyStat = ({ companyUid }) => {
  const [stat, setStat] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const companyStat = await fetchCompanyWithShopInfomation(companyUid);

      setStat({
        ...companyStat.company,
        products: companyStat.products,
        trackables: companyStat.trackables,
      });
      setLoading(false);
    })();
  }, [companyUid]);

  return (
    <Stack spacing={2} justifyContent="flex-start">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Typography variant="h6" fontWeight="bold">
              {stat?.name} ({stat?.region})
            </Typography>
          </Stack>
          <Stack direction="row" flexWrap="wrap" justifyContent="flex-start">
            <Chip
              sx={{
                fontWeight: 'bold',
                maxWidth: 'max-content',
                flex: '1 0 21%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '5px',
              }}
              color="info"
              label={`Shops: ${stat?.shops?.length}`}
            />
            <Chip
              sx={{
                fontWeight: 'bold',
                maxWidth: 'max-content',
                flex: '1 0 21%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '5px',
              }}
              color="warning"
              label={`Products: ${stat?.products}`}
            />
            <Chip
              sx={{
                fontWeight: 'bold',
                maxWidth: 'max-content',
                flex: '1 0 21%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '5px',
              }}
              color="success"
              label={`Trackables: ${stat?.trackables}`}
            />
          </Stack>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
            {stat?.shops?.map((shop) => (
              <CustomTooltip title={shop.name} key={shop.shopCode}>
                <Box
                  sx={{
                    boxSizing: 'border-box',
                    padding: '5px',
                    borderRadius: 1,
                    height: '40px',
                    bgcolor: colors.amber[200],
                    maxWidth: 'max-content',
                    flex: '1 0 21%',
                    margin: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                    }}>
                    {shop.shopCode}
                  </Typography>
                </Box>
              </CustomTooltip>
            ))}
          </Box>
        </>
      )}
    </Stack>
  );
};

export default CompanyStat;
