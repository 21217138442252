import React, { useEffect, useState } from 'react';

import SelectCompanyDialog from './SelectCompanyDialog';
import { useLocation } from 'react-router-dom';
const SelectCompany = () => {
  const location = useLocation();
  const [showCompanySelectDialog, setShowCompanySelectDialog] = useState(true);
  useEffect(() => setShowCompanySelectDialog(true), [location.pathname]);
  return <SelectCompanyDialog show={showCompanySelectDialog} toggle={setShowCompanySelectDialog} />;
};

export default SelectCompany;
