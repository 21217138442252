import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [],
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    addNew: (state, action) => {
      state.users = [action.payload.user, ...state.users];
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
