import React from 'react';
import Page from '../../components/global/Page';
import {
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  DialogActions,
  OutlinedInput,
  Chip,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchScheduleForCompany,
  manuallyRunAllScrapper,
  manuallyRunScrapper,
  removeSchedule,
} from '../../api/schedules';
import { feedbackActions } from '../../store/slice/feedbackReducer';
import BaseTable from '../../components/global/BaseTable';
import { Delete, PlayCircle } from '@mui/icons-material';
import CustomTooltip from '../../components/global/Tooltip';
import { GridToolbar } from '@mui/x-data-grid';
import NoDataOverlay from '../../components/global/NoDataOverlay';
import { Link } from 'react-router-dom';
import { ADD_NEW_SCHEDULER } from '../../constants/routerUrls';

const Scheduler = () => {
  const [schedules, setSchedules] = React.useState([]);
  const [showRunAllScraperDialog, setShowRunAllScrapperDialog] = React.useState(false);

  const company = useSelector((state) => state.company.selectedCompany);
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      const response = await fetchScheduleForCompany(company.uid);
      setSchedules(response);
    })();
  }, [company]);

  const deleteSchedule = async (data) => {
    const { uid } = data;
    const response = await removeSchedule(uid);
    if (!response) {
      dispatch(feedbackActions.NOTIFY({ status: 'error', message: 'Failed to delete schedule' }));
    }
    const filteredSchedules = schedules.filter((item) => item.uid !== uid);
    setSchedules(filteredSchedules);
    dispatch(feedbackActions.NOTIFY({ status: 'success', message: 'Schedule Removed' }));
  };

  const runScrapper = async (schedule) => {
    const response = await manuallyRunScrapper(schedule.uid);
    if (!response) {
      dispatch(
        feedbackActions.NOTIFY({ status: 'error', message: 'Pulse finder failed to start' }),
      );
    }
    dispatch(feedbackActions.NOTIFY({ status: 'success', message: 'Pulse finder started' }));
  };

  const runAllScrapper = async () => {
    const scheduleUids = schedules.map((item) => item.uid);

    const response = await manuallyRunAllScrapper(scheduleUids);
    if (!response) {
      dispatch(
        feedbackActions.NOTIFY({ status: 'error', message: 'Pulse finder failed to start' }),
      );
    }
    dispatch(feedbackActions.NOTIFY({ status: 'success', message: 'Pulse finder started' }));
    setShowRunAllScrapperDialog(false);
  };
  const slots = [
    {
      label: 'Morning 7:00 AM - 1:00 PM',
      value: 'MORNING',
    },
    {
      label: 'Evening 2:00 PM - 10:00 PM',
      value: 'EVENING',
    },
    {
      label: 'Night 11:00 PM - 6:00 AM',
      value: 'NIGHT',
    },
  ];

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      width: 200,
      renderCell: (params) => (
        <CustomTooltip title={params.row.description}>
          <Typography>{params.value}</Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'companyName',
      headerName: 'Company',
      width: 150,
    },
    {
      field: 'shopCode',
      headerName: 'Shop Code',
      width: 100,
    },
    {
      field: 'slot',
      headerName: 'Slot',
      width: 200,
      renderCell: (params) => slots.find((item) => item.value === params.row?.slot).label,
    },
    {
      field: 'keywords',
      headerName: 'Keywords',
      width: 300,
      renderCell: (params) => (
        <CustomTooltip title={`${params?.value?.map((item) => item.keyword + ', ')}`}>
          <Typography>{params?.value?.map((item) => item.keyword + ', ')}</Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'darkStores',
      headerName: 'Stores',
      width: 300,
      renderCell: (params) => (
        <CustomTooltip title={`${params?.value?.map((item) => item.name + ', ')}`}>
          <Typography>{params?.value?.map((item) => item.name + ', ')}</Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'products',
      headerName: 'Products Assigned',
      width: 150,
      align: 'center',
      renderCell: (params) => {
        return <Chip label={params.row.products.length} size="small" color="warning" />;
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="row">
            <CustomTooltip title="Run Pulse Finder">
              <Box>
                <IconButton onClick={async () => await runScrapper(params.row)} color="success">
                  <PlayCircle />
                </IconButton>
              </Box>
            </CustomTooltip>
            <CustomTooltip title="Delete schedule">
              <Box>
                <IconButton onClick={async () => await deleteSchedule(params.row)} color="error">
                  <Delete />
                </IconButton>
              </Box>
            </CustomTooltip>
          </Stack>
        );
      },
    },
  ];

  const CustomToolBar = () => {
    return (
      <Stack
        justifyContent="space-between"
        direction="row"
        sx={{ px: 1, py: 1, boxSizing: 'border-box' }}>
        <OutlinedInput placeholder="Search" />
        <GridToolbar />
      </Stack>
    );
  };
  console.log(schedules);
  return (
    <Page>
      <Dialog open={showRunAllScraperDialog} onClose={() => setShowRunAllScrapperDialog(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>Are you sure you want start finder for all shops?</DialogContent>
        <DialogActions>
          <Button onClick={() => setShowRunAllScrapperDialog(false)}>No</Button>
          <Button variant="contained" onClick={runAllScrapper}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Stack width="100%" direction="row" justifyContent="flex-end" spacing={2} sx={{ my: '15px' }}>
        <Button
          variant="contained"
          onClick={() => setShowRunAllScrapperDialog(true)}
          endIcon={<PlayCircle />}
          disabled={schedules?.length <= 0}>
          Run All
        </Button>
        <Button variant="contained" component={Link} to={ADD_NEW_SCHEDULER}>
          Add New
        </Button>
      </Stack>
      <Box width="100%" height="550px" bgcolor="#fff" borderRadius="10px">
        <BaseTable
          columns={columns}
          getRowId={(row) => row.uid}
          rows={schedules}
          components={{
            Toolbar: CustomToolBar,
            NoResultsOverlay: NoDataOverlay,
            NoRowsOverlay: NoDataOverlay,
          }}
        />
      </Box>
    </Page>
  );
};

export default Scheduler;
