import {
  Alert,
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MASTER_LIST } from '../../../constants/routerUrls';

const Result = ({ erroredData, rawData }) => {
  const navigate = useNavigate();

  const getAddedProducts = () => {
    const erroredProducts = erroredData?.map((item) => item.brandCode);
    const items = rawData.filter((item) => !erroredProducts.includes(item.brandCode));

    return items;
  };

  return (
    <Box width="100%" my="3rem">
      {getAddedProducts().length > 0 && (
        <Alert severity="success">
          {getAddedProducts()?.length} Products added to master list.
        </Alert>
      )}
      <Box mb="20px" />
      {erroredData.length > 0 && (
        <Alert severity="error">
          Failed to update any information for following {erroredData?.length} products!
        </Alert>
      )}
      <Box mb="20px" />
      <Typography sx={{ mb: '10px' }} variant="h6" fontWeight="bold">
        {erroredData?.length > 0 ? 'Errored data' : 'No errored data'}
      </Typography>
      <Divider />
      {erroredData?.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Brand Code</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Business Unit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {erroredData?.map((item) => (
              <TableRow key={item.brandCode}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.brandCode}</TableCell>
                <TableCell>{item.brand}</TableCell>
                <TableCell>{item.businessUnit}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <Typography sx={{ mb: '10px', mt: '30px' }} variant="h6" fontWeight="bold">
        Master list was updated for following products
      </Typography>
      <Divider />
      {getAddedProducts()?.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Brand Code</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Business Unit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getAddedProducts()?.map((item) => (
              <TableRow key={item.brandCode}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.brandCode}</TableCell>
                <TableCell>{item.brand}</TableCell>
                <TableCell>{item.businessUnit}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography>No data to update</Typography>
      )}
      <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ my: '20px' }}>
        <Button variant="contained" onClick={() => navigate(MASTER_LIST)} sx={{ boxShadow: 0 }}>
          Done
        </Button>
      </Stack>
    </Box>
  );
};

export default Result;
