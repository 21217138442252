import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT, SIGNIN, SIGNUP } from '../actions/types';

const initState = {
  isAuth: false,
  token: null,
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initState,
  reducers: {
    [SIGNIN]: (state, action) => {
      state.isAuth = true;
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    [SIGNUP]: (state) => {
      state.isAuth = true;
      state.token = '';
      state.user = '';
    },
    [LOGOUT]: (state) => {
      state.isAuth = false;
      state.token = null;
      state.user = null;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
