/* eslint-disable react/jsx-key */
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { addShopToCompany, fetchAllCompanies, patchCompany, removeShop } from '../../api/company';
import Page from '../../components/global/Page';
import Table from '../../components/global/Table';
import { useDispatch, useSelector } from 'react-redux';
import { companyActions } from '../../store/slice/companyReducer';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as date from '../../utils/date';
import { Link } from 'react-router-dom';
import { ADD_NEW_COMPANY } from '../../constants/routerUrls';
import { GridActionsCellItem, GridRowModes } from '@mui/x-data-grid';
import { Edit, Delete, Save, Cancel } from '@mui/icons-material';
import CustomTooltip from '../../components/global/Tooltip';
import { fetchCompanyShops, fetchShopWhichDoesNotBelongToCompany } from '../../api/shop';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BaseTable from '../../components/global/BaseTable';
import SubmitButton from '../../components/global/SubmitButton';
import { feedbackActions } from '../../store/slice/feedbackReducer';
import { convertCamelCaseToWord } from '../../utils/word';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CompanyList = () => {
  const [total, setTotal] = useState();
  const [loading, setIsLoading] = useState();
  const [rowModesModel, setRowModesModel] = useState({});
  const [patchRequested, setPatchRequested] = useState(null);
  const [drawerComponent, setDrawerComponent] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [shopList, setShopList] = useState([]);
  const [metaRows, setMetaRows] = useState([]);
  const [inUseShops, setInUseShops] = useState([]);
  const [selectedShops, setSelectedShops] = useState([]);
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.company.companies);

  const fetchCompanyData = async (query) => {
    setIsLoading(true);
    const { companies: companyDataFromApi, totalEntries } = await fetchAllCompanies(query);
    setTotal(totalEntries);
    dispatch(companyActions.setCompanies({ companies: companyDataFromApi }));
    setIsLoading(false);
  };

  const fetchUnUsedShopData = async () => {
    const shops = await fetchShopWhichDoesNotBelongToCompany(selectedCompany.uid);
    setShopList(shops);
  };

  const fetchInUseShop = async () => {
    const shops = await fetchCompanyShops({ companyUid: selectedCompany.uid });
    setInUseShops(shops);
  };

  useEffect(() => {
    (async () => await fetchInUseShop())();
    (async () => await fetchUnUsedShopData())();
    handleMetaRows();
  }, [selectedCompany]);

  useEffect(() => {
    (async () => {
      await fetchCompanyData();
    })();
  }, []);

  const formatCompnayStatus = (params) => {
    switch (params.value) {
      case 'ACTIVE':
        return <Chip label="Active" color="success" size="small" />;
      case 'INACTIVE':
        return <Chip label="In Active" color="info" size="small" />;
      case 'PAYMENT PENDING':
        return <Chip label="Payment Pending" color="warning" size="small" />;
      case 'DISABLED':
        return <Chip label="Disabled" color="error" size="small" />;
      default:
        return <Chip label={params?.value} size="small" />;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = () => () => {
    // setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleNoPress = async () => {
    const { oldData, resolve } = patchRequested;
    resolve(oldData);
    setPatchRequested(null);
  };

  const handleYesPress = async () => {
    const { newData, oldData, reject, resolve } = patchRequested;
    const response = await patchCompany({ ...newData, uid: newData?.uid || selectedCompany?.uid });
    if (response) {
      resolve({
        ...newData,
        meta: { ...selectedCompany?.meta, [newData?.key]: newData?.value },
        updatedAt: new Date(),
      });
      setPatchRequested(null);
      dispatch(
        companyActions.updateCompany({
          company: {
            ...newData,
            meta: { ...selectedCompany?.meta, [newData?.key]: newData?.value },
            updatedAt: new Date(),
          },
        }),
      );
      handleMetaRows(newData);
    } else {
      reject(oldData);
      setPatchRequested(null);
    }
  };

  const processRowUpdate = React.useCallback(
    (newData, oldData) =>
      new Promise((resolve, reject) => {
        if (
          newData?.name !== oldData?.name ||
          newData?.region !== oldData?.region ||
          newData?.status !== oldData?.status ||
          newData?.value != oldData?.value
        ) {
          setPatchRequested({ resolve, reject, newData, oldData });
        } else {
          resolve(oldData);
        }
      }),
    [],
  );

  const removeShopFromCompany = async (shop) => {
    const response = await removeShop(selectedCompany.uid, shop.shopCode);
    if (response) {
      setSelectedShops([]);
      setSelectedCompany(null);
    } else {
      dispatch(
        feedbackActions.NOTIFY({ status: 'error', message: `Failed to remove ${shop.name}` }),
      );
    }
  };

  const shopToCompany = async () => {
    const response = await addShopToCompany(selectedCompany.uid, selectedShops);
    if (!response) {
      dispatch(feedbackActions.NOTIFY({ status: 'error', message: 'Failed to add shops ' }));
    }

    setInUseShops([...inUseShops, ...selectedShops]);
    setSelectedShops([]);
    setSelectedCompany(null);
  };

  const columns = [
    {
      field: 'uid',
      headerName: 'UID',
      width: 150,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      width: 250,
    },
    {
      field: 'region',
      headerName: 'Region',
      editable: true,
      width: 120,
      filterable: false,
      type: 'singleSelect',
      valueOptions: ['SG', 'EU', 'US'],
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: true,
      renderCell: formatCompnayStatus,
      width: 200,
      filterable: false,
      type: 'singleSelect',
      valueOptions: ['ACTIVE', 'INACTIVE', 'PAYMENT PENDING', 'DISABLED'],
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      valueGetter: (params) => date.renderFormat(params.row.createdAt),
      width: 200,
      editable: false,
      filterable: false,
    },
    {
      field: 'updatedAt',
      headerName: 'Last Updated',
      valueGetter: (params) => date.renderFormat(params.row.updatedAt),
      width: 200,
      editable: false,
      filterable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <CustomTooltip title="Save">
              <GridActionsCellItem
                icon={<Save />}
                label="Save"
                onClick={handleSaveClick(id)}
                color="primary"
              />
            </CustomTooltip>,
            <CustomTooltip title="Cancel Edit">
              <GridActionsCellItem
                icon={<Cancel />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            </CustomTooltip>,
          ];
        }

        return [
          <CustomTooltip title="Edit">
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />
          </CustomTooltip>,
          <CustomTooltip title="Delete">
            <GridActionsCellItem
              icon={<Delete />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          </CustomTooltip>,
        ];
      },
    },
    {
      field: 'Shops',
      headerName: 'Shops',
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              setSelectedCompany(params.row);
              setDrawerComponent('SHOPS');
            }}>
            SHOPS
          </Button>
        );
      },
    },
    {
      field: 'Meta',
      headerName: 'Settings',
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              setSelectedCompany(params.row);
              setDrawerComponent('SETTINGS');
            }}>
            SETTINGS
          </Button>
        );
      },
    },
  ];

  const shopsColumn = [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'shopCode',
      headerName: 'Shop Code',
      width: 150,
    },
    {
      field: 'region',
      headerName: 'Region',
      width: 70,
    },
    {
      field: 'action',
      headerName: 'Delete',
      renderCell: (param) => {
        return (
          <IconButton onClick={async () => await removeShopFromCompany(param.row)}>
            <Delete />
          </IconButton>
        );
      },
    },
  ];

  const metaColumns = [
    {
      field: 'key',
      headerName: 'Field Name',
      width: 200,
      renderCell: (params) => `${convertCamelCaseToWord(params.value)}`,
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1,
      editable: true,
    },
  ];

  const handleMetaRows = (newData) => {
    const valuePair = [];
    const rawData = newData ? { [newData?.key]: newData?.value } : selectedCompany?.meta;
    if (rawData) {
      const keys = Object.keys(rawData);
      keys.map((item, index) => valuePair.push({ id: index, key: item, value: rawData[item] }));
    }
    setMetaRows(valuePair);
  };

  const renderDrawerComponent = () => {
    switch (drawerComponent) {
      case 'SHOPS':
        return (
          <Box width="600px" boxSizing="border-box" px="20px" py="25px">
            <Typography variant="h5" fontWeight="bold" sx={{ mb: '10px' }}>
              Assigned Shops
            </Typography>
            <Box width="100%" height="350px">
              {selectedCompany && <BaseTable columns={shopsColumn} rows={inUseShops} />}
            </Box>
            <Autocomplete
              multiple
              value={selectedShops}
              onChange={(event, value) => setSelectedShops(value)}
              options={shopList}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.shopCode === value.shopCode}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Shops" placeholder="Search Shops" />
              )}
            />
            <Stack width="100%" direction="row" justifyContent="flex-end" sx={{ mt: '15px' }}>
              <SubmitButton
                variant="contained"
                disabled={selectedShops.length <= 0}
                onClick={shopToCompany}>
                Add Shops
              </SubmitButton>
            </Stack>
          </Box>
        );
      case 'SETTINGS':
        return (
          <Box width="600px" boxSizing="border-box" px="20px" py="25px">
            <Typography variant="h5" fontWeight="bold" sx={{ mb: '10px' }}>
              Configuration
            </Typography>
            <Box width="100%" height="350px">
              {selectedCompany && (
                <BaseTable
                  columns={metaColumns}
                  rows={metaRows}
                  experimentalFeatures={{ newEditingApi: true }}
                  processRowUpdate={processRowUpdate}
                />
              )}
            </Box>

            <Stack width="100%" direction="row" justifyContent="flex-end" sx={{ mt: '15px' }}>
              <SubmitButton
                variant="contained"
                disabled={selectedShops.length <= 0}
                onClick={shopToCompany}>
                Add To Config
              </SubmitButton>
            </Stack>
          </Box>
        );
    }
  };

  return (
    <Page>
      <Drawer
        open={selectedCompany && drawerComponent}
        anchor="right"
        onClose={() => {
          setSelectedCompany(null);
          setSelectedShops([]);
          setInUseShops([]);
          setShopList([]);
        }}>
        {renderDrawerComponent()}
      </Drawer>
      <Dialog open={!!patchRequested}>
        <DialogTitle>Are You Sure?</DialogTitle>
        <DialogContent>Are you sure You want to update company information?</DialogContent>
        <DialogActions>
          <Button onClick={handleNoPress}>No</Button>
          <Button onClick={handleYesPress}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Box my="25px" mx="auto" width="100%" borderRadius="10px">
        <Stack
          width="100%"
          height="100%"
          direction="row"
          alignItems="center"
          justifyContent="flex-end">
          <Stack direction="row" spacing={2}>
            <Button component={Link} to={ADD_NEW_COMPANY} variant="contained">
              ADD NEW
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Box width="100%" height="100%" mt="15px" borderRadius="10px">
        <Table
          rows={companies}
          total={total}
          columns={columns}
          queryCallback={fetchCompanyData}
          loading={loading}
          searchFieldLabel="Search By Name"
          quickSearchParamName="name"
          rowModesModel={rowModesModel}
          processRowUpdate={processRowUpdate}
        />
      </Box>
    </Page>
  );
};

export default CompanyList;
