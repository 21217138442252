import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from '@mui/material';
import React from 'react';

const BasicInfoForm = ({ toggleStep, register, formErrors, formSubmit, watch }) => {
  const next = () => {
    toggleStep(1);
  };

  return (
    <Box width="100%" my="5rem">
      <Stack width="100%" spacing={2} sx={{ margin: '20px 0' }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <FormControl sx={{ flexGrow: 1 }} required error={!!formErrors?.name}>
            <InputLabel>Name</InputLabel>
            <OutlinedInput
              label="Name"
              placeholder="Product name - ie Dettol Handwash"
              {...register('name')}
            />
            {formErrors?.name && <FormHelperText>{formErrors?.name?.message}</FormHelperText>}
          </FormControl>
          <FormControl sx={{ flexGrow: 1 }} required error={!!formErrors?.brand}>
            <InputLabel>Brand</InputLabel>
            <OutlinedInput
              label="Brand"
              placeholder="Product brand name - ie Dettol"
              {...register('brand')}
            />
            {formErrors?.brand && <FormHelperText>{formErrors?.brand?.message}</FormHelperText>}
          </FormControl>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <FormControl sx={{ flexGrow: 1 }} required error={!!formErrors?.brandCode}>
            <InputLabel>Brand Code</InputLabel>
            <OutlinedInput
              label="Brand Code"
              placeholder="Enter brand code - ie 5225200"
              {...register('brandCode')}
            />
            {formErrors?.brandCode && (
              <FormHelperText>{formErrors?.brandCode?.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ flexGrow: 1 }}>
            <InputLabel>Business Unit</InputLabel>
            <OutlinedInput
              label="Business Unit"
              placeholder="Product business unit - ie Health"
              {...register('businessUnit')}
            />
          </FormControl>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <FormControl sx={{ flexGrow: 1 }} required error={!!formErrors?.category}>
            <InputLabel>Category</InputLabel>
            <OutlinedInput
              label="Category"
              placeholder="Product category - ie Handwash"
              {...register('category')}
            />
            {formErrors?.category && (
              <FormHelperText>{formErrors?.category?.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ flexGrow: 1 }} required error={!!formErrors?.variant}>
            <InputLabel>Variant</InputLabel>
            <OutlinedInput
              label="Variant"
              placeholder="Product variant - ie Lemon"
              {...register('variant')}
            />
            {formErrors?.variant && <FormHelperText>{formErrors?.variant?.message}</FormHelperText>}
          </FormControl>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <FormControl sx={{ flexGrow: 1 }} required error={!!formErrors?.size}>
            <InputLabel>Size</InputLabel>
            <OutlinedInput
              label="Size"
              placeholder="Product size - ie 250ml"
              {...register('size')}
            />
            {formErrors?.size && <FormHelperText>{formErrors?.size?.message}</FormHelperText>}
          </FormControl>
          <FormControl sx={{ flexGrow: 1 }} required error={!!formErrors?.packageQuantity}>
            <InputLabel>Package Quantity</InputLabel>
            <OutlinedInput
              label="Package Quantity"
              placeholder="Product quantity - ie 1"
              {...register('packageQuantity')}
              type="number"
            />
            {formErrors?.packageQuantity && (
              <FormHelperText>{formErrors?.packageQuantity?.message}</FormHelperText>
            )}
          </FormControl>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <FormControl sx={{ flexGrow: 1 }} required error={!!formErrors?.listingStatus}>
            <InputLabel>Listing Status</InputLabel>
            <Select
              {...register('listingStatus')}
              placeholder="Product Listing status"
              defaultValue={watch?.listingStatus}
              label="Listing Status">
              <MenuItem value="LISTED">Listed</MenuItem>
              <MenuItem value="DELISTED">Delisted</MenuItem>
            </Select>
            {formErrors?.listingStatus && (
              <FormHelperText>{formErrors?.listingStatus?.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ flexGrow: 1 }} required error={!!formErrors?.packageType}>
            <InputLabel>Package Type</InputLabel>
            <Select
              label="Package Type"
              placeholder="Package Type"
              {...register('packageType')}
              defaultValue={watch?.packageType}>
              <MenuItem value="SINGLE">Single</MenuItem>
              <MenuItem value="BUNDLE">Bundle</MenuItem>
              <MenuItem value="CARTOON">Cartoon</MenuItem>
            </Select>
            {formErrors?.packageType && (
              <FormHelperText>{formErrors?.packageType?.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ flexGrow: 1 }}>
            <InputLabel>Barcode</InputLabel>
            <OutlinedInput
              label="Barcode"
              placeholder="Product barcode - ie 255800"
              {...register('barcode')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack justifyContent="space-between" alignItems="flex-start" direction="row">
        <Alert sx={{ width: '550px' }} severity="info" variant="outlined">
          Brand Code: The uniqie identifier for the product, ie Reckitt code
        </Alert>

        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button variant="contained" disabled>
            Previous
          </Button>
          <Button variant="contained" onClick={formSubmit(next)}>
            Next
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default BasicInfoForm;
