import * as React from 'react';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';

/**
 * custom drawer header
 */
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  marginBottom: '-20px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

import Navbar from '../components/global/Navbar';
import Sidebar from '../components/global/Sidebar';
import Shortcuts from '../components/global/Shortcuts';
import { colors } from '@mui/material';
import FeedbackNotification from '../components/global/FeedbackNotification';
import SelectCompany from '../components/dashboard/SelectCompany';
import { useSelector } from 'react-redux';

const PrivateLayout = () => {
  const [open, setOpen] = React.useState(false);
  const company = useSelector((state) => state.company.selectedCompany);
  return (
    <Box sx={{ display: 'flex' }}>
      <Navbar show={open} setShow={setOpen} />
      <Sidebar open={open} setOpen={setOpen} />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, height: 'auto', minHeight: '100vh' }}
        bgcolor={colors.grey[100]}>
        <DrawerHeader />
        <Shortcuts />
        <Outlet />
        <FeedbackNotification />
        {!company && <SelectCompany />}
      </Box>
    </Box>
  );
};

export default PrivateLayout;
