import { Button } from '@mui/material';
import React, { useEffect, useRef } from 'react';

const SubmitButton = ({ children, ...rest }) => {
  const ref = useRef();

  const handleSubmit = (event) => {
    if (event.key === 'Enter') {
      ref?.current?.click();
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', handleSubmit);

    return () => document.removeEventListener('keypress', handleSubmit);
  }, [ref]);

  return (
    <Button {...rest} ref={ref}>
      {children}
    </Button>
  );
};

export default SubmitButton;
