import React, { useState } from 'react';
import { Autocomplete, Box, Button, Checkbox, Stack, TextField, Typography } from '@mui/material';
import { DARK_STORE_ENABLED_SHOPS, SEARCH_BASED_SHOP } from '../../constants/constant';
import { fetchKewordAndDarkStoreData } from '../../api/shop';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { CheckBox, CheckBoxOutlineBlank, Save } from '@mui/icons-material';
import BaseTable from '../global/BaseTable';
import { fetchAllProducts } from '../../api/product';
import { GridToolbar } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab';

const ShopConfig = ({ isOpen, shop }) => {
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [keywordList, setKeywordList] = useState([]);
  const [darkStoreList, setDarkStoreList] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const company = useSelector((state) => state.company.selectedCompany);

  const getDarkStoreAndKeywordOptions = async () => {
    const response = await fetchKewordAndDarkStoreData(company.uid, shop.shopCode);
    if (response.darkStores) {
      setDarkStoreList(response.darkStores);
    }
    if (response.keywords) {
      setKeywordList(response.keywords);
    }
  };

  useEffect(() => {
    (async () => await getDarkStoreAndKeywordOptions())();
    (async () => {
      setLoading(true);
      const response = await fetchAllProducts(company.uid, shop.shopCode);
      setProductList(response.products);
      setLoading(false);
    })();
  }, [company, shop]);

  const showKeywordSelectionForm = () => {
    const shopCode = shop?.shopCode?.split('-')?.[0];
    if (SEARCH_BASED_SHOP.includes(shopCode)) {
      return true;
    }
    return false;
  };

  const showDarkStoreSelectionForm = () => {
    const shopCode = shop?.shopCode?.split('-')?.[0];
    if (DARK_STORE_ENABLED_SHOPS.includes(shopCode)) {
      return true;
    }
    return false;
  };

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({ resolver: null });

  const keywords = useWatch({ control, name: 'keywords' });
  const darkStores = useWatch({ control, name: 'darkStores' });
  const selectedProducts = useWatch({ control, name: 'products' });

  const columns = [
    {
      field: 'productCode',
      headerName: 'Product Code',
      width: 120,
    },
    {
      field: 'name',
      headerName: 'Product Name',
      flex: 1,
    },
    {
      field: 'sku',
      flex: 1,
      headerName: 'SKU',
    },
    {
      field: 'link',
      headerName: 'Link',
      flex: 1,
    },
  ];

  const handleSelection = (ids) => {
    const seletedIds = new Set(ids);
    const selectedItems = productList.filter((item) => seletedIds.has(item.productCode));
    if (selectedItems?.length > 0) {
      setSelectionModel(ids);
      setValue('products', selectedItems);
    } else {
      setSelectionModel([]);
      setValue('products', null);
    }
  };

  useEffect(() => {
    if (selectedProducts) {
      const ids = selectedProducts?.map((item) => item.productCode);
      setSelectionModel(ids);
    }
  }, []);

  return (
    <Stack
      sx={{
        margin: '0px 5px',
        overflow: 'hidden',
        transformOrigin: 'top',
        padding: isOpen ? '1vw' : '0px',
        height: isOpen ? 'auto' : '0px',
        borderRadius: '0px 0px 5px 5px',
        transition: 'transform 0.5s ease-out',
        borderLeft: isOpen ? '1px solid grey' : 'none',
        borderRight: isOpen ? '1px solid grey' : 'none',
        borderBottom: isOpen ? '1px solid grey' : 'none',
        transform: isOpen ? 'scale(1,1)' : 'scale(1,0)',
      }}>
      <Box>
        {showKeywordSelectionForm() && (
          <Box>
            <Typography sx={{ my: 2 }} variant="h5" fontWeight={600}>
              Select Keywords
            </Typography>
            <Autocomplete
              multiple
              value={keywords}
              disableCloseOnSelect
              options={keywordList}
              getOptionLabel={(option) => option.keyword}
              onChange={(event, value) => setValue('keywords', value)}
              isOptionEqualToValue={(option, value) => option.uid === value.uid}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    checked={selected}
                    style={{ marginRight: 8 }}
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                  />
                  {option.keyword}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Add more stores" sx={{ maxWidth: '600px' }} />
              )}
            />
          </Box>
        )}
        {showDarkStoreSelectionForm() && (
          <Box>
            <Typography sx={{ my: 2 }} variant="h5" fontWeight={600}>
              Select Stores
            </Typography>
            <Autocomplete
              multiple
              value={darkStores}
              disableCloseOnSelect
              options={darkStoreList}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => setValue('darkStores', value)}
              isOptionEqualToValue={(option, value) => option.uid === value.uid}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    checked={selected}
                    style={{ marginRight: 8 }}
                    checkedIcon={<CheckBox fontSize="small" />}
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Add more stores" sx={{ maxWidth: '600px' }} />
              )}
            />
          </Box>
        )}

        <Typography sx={{ my: 2 }} variant="h5" fontWeight={600}>
          Select Products
        </Typography>
        {errors?.products && (
          <Typography color="red" fontSize="14px">
            {errors?.products?.message}
          </Typography>
        )}
        <Box height="500px" mt="15px">
          {productList && (
            <BaseTable
              density="compact"
              loading={loading}
              columns={columns}
              rows={productList}
              disableSelectionOnClick
              checkboxSelection={true}
              selectionModel={selectionModel}
              getRowId={(row) => row.productCode}
              onSelectionModelChange={handleSelection}
              components={{ Pagination: null, Toolbar: GridToolbar }}
            />
          )}
        </Box>
        <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button variant="contained">Cancel</Button>
          <LoadingButton variant="contained" endIcon={<Save />} loadingPosition="end">
            Save
          </LoadingButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default ShopConfig;
