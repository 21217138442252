import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companies: [],
  selectedCompany: null,
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanies: (state, action) => {
      state.companies = action.payload.companies;
    },
    updateCompany: (state, action) => {
      state.companies = state.companies.map((item) =>
        item.uid === action.payload.company.uid ? { ...item, ...action.payload.company } : item,
      );
    },
    selectCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    removeSelectedCompany: (state) => {
      state.selectedCompany = null;
    },
  },
});

export const companyActions = companySlice.actions;
export default companySlice.reducer;
