import { Button, Stack, Typography } from '@mui/material';
// import { Box } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import Screen from '../../components/global/Screen';
import { colors } from '../../constants/colors';
import { DASHBOARD, SIGN_IN } from '../../constants/routerUrls';

const NotFound = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <Screen>
      <Stack justifyContent="center" alignItems="center" width="100%" height="100vh" spacing={2}>
        {/* <Box component="img" src="/assets/images/404-banner.png" width="300px" /> */}
        <iframe
          src="https://giphy.com/embed/LAKIIRqtM1dqE"
          width="480"
          height="358"
          frameBorder="0"
          className="giphy-embed"
          allowFullScreen></iframe>
        <p>
          <a href="https://giphy.com/gifs/giphyqa-LAKIIRqtM1dqE">via GIPHY</a>
        </p>
        <Typography variant="h1" fontSize="120px" fontWeight="bold" color={colors.black}>
          404
        </Typography>
        <Typography fontSize="18px" fontWeight="bold" color={colors.black}>
          Stop It !!! Get Some Help
        </Typography>
        {auth.isAuth ? (
          <Button variant="contained" component="a" href={DASHBOARD}>
            Dashboard
          </Button>
        ) : (
          <Button variant="contained" component="a" href={SIGN_IN}>
            SIGN IN
          </Button>
        )}
      </Stack>
    </Screen>
  );
};

export default NotFound;
