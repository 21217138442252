import React, { useState } from 'react';
import { colors } from '../../constants/colors';
import { drawerWidth } from '../../constants/mixins';

import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { styled } from '@mui/material/styles';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Avatar, Chip, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/slice/authReducer';
import Dropdown from './DropDown';
import SelectCompanyDialog from '../dashboard/SelectCompanyDialog';
import { companyActions } from '../../store/slice/companyReducer';
import { logout } from '../../api/auth';
import { feedbackActions } from '../../store/slice/feedbackReducer';
import { Link } from 'react-router-dom';
import { DASHBOARD } from '../../constants/routerUrls';

const Navbar = ({ show, setShow }) => {
  const company = useSelector((state) => state.company.selectedCompany);
  const [showCompanySelectionDialog, setShowCompanySelectionDialog] = useState(false);

  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setShow(true);
  };

  /**
   * Extended app bar design
   */
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const profileMenuOptions = [
    {
      label: 'Logout',
      callback: async () => {
        const isLoggedOut = await logout();
        if (isLoggedOut) {
          dispatch(authActions.LOGOUT());
          dispatch(companyActions.removeSelectedCompany());
          dispatch(feedbackActions.NOTIFY({ status: 'info', message: 'You have been logged out' }));
        } else {
          dispatch(
            // eslint-disable-next-line quotes
            feedbackActions.NOTIFY({ status: 'warning', message: "Sorry couldn't log you out" }),
          );
        }
      },
    },
  ];
  return (
    <AppBar position="fixed" open={show} sx={{ backgroundColor: colors.black }}>
      <SelectCompanyDialog
        show={showCompanySelectionDialog}
        toggle={setShowCompanySelectionDialog}
        secondSelect={true}
      />
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(show && { display: 'none' }),
          }}>
          <MenuIcon style={{ fill: colors.white }} />
        </IconButton>
        <Box flexGrow={1}>
          <Typography
            component={Link}
            to={DASHBOARD}
            sx={{
              color: 'white',
              fontSize: '14px',
              fontWeight: 'bold',
              textDecoration: 'none',
            }}>
            Pulse Manager
          </Typography>
        </Box>
        <Box
          marginRight="15px"
          sx={{ cursor: 'pointer' }}
          onClick={() => setShowCompanySelectionDialog(true)}>
          <Chip label={company?.name} variant="filled" color="warning" sx={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Dropdown options={profileMenuOptions} title={<Avatar />} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
