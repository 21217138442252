import { Box } from '@mui/system';
import React from 'react';

const Page = ({ children }) => {
  return (
    <Box width="100%" height="auto" minHeight="calc(100vh - 200px)">
      {children}
    </Box>
  );
};

export default Page;
