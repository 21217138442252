export const ROOT_URL = '/';
export const SIGN_IN = '/';
export const DASHBOARD = '/dashboard';

export const COMPANY = '/dashboard/company';
export const ADD_NEW_COMPANY = '/dashboard/company/new';
export const SHOP = '/dashboard/shop';

export const MASTER_LIST = '/dashboard/master-list';
export const ADD_SINGLE_PRODUCT = '/dashboard/master-list/add-single';
export const ADD_BULK_PRODUCT = '/dashboard/master-list/add-bulk';
export const UPDATE_STOCK = '/dashboard/master-list/update-stock';

export const TRACKING_REPORTS = '/dashboard/tracking-reports';
export const CREATE_NEW_REPORT = '/dashboard/tracking-reports/create';
export const REPORT_OVERVIEW = '/dashboard/tracking-reports/overview';
export const STOCK_STATUS = '/dashboard/stock-status';

export const USERS = '/dashboard/user';
export const NEW_USER = '/dashboard/user/new';

export const SCHEDULER = '/dashboard/schedule';
export const ADD_NEW_SCHEDULER = '/dashboard/schedule/new';

export const COMPANY_SHOPS = '/dashboard/stores';
export const ADD_NEW_COMPANY_SHOPS = '/dashboard/stores/new';
export const UPDATE_COMPANY_SHOPS = '/dashboard/stores/update';
