import * as yup from 'yup';
import { SLOTS } from '../constants/constant';

export const addScheduleSchema = [
  yup.object().shape({
    shop: yup.mixed().required('Must select a shop'),
    slot: yup.string().oneOf(SLOTS).required('Must Select a slot'),
    title: yup.string().required('Must provide a schedule title'),
  }),
  yup.object().shape({
    products: yup.mixed().required('Must select atleast one product'),
  }),
  yup.object().shape({
    shop: yup.mixed().required('Must select a shop'),
    slot: yup.string().oneOf(SLOTS).required('Must Select a slot'),
    title: yup.string().required('Must provide a schedule title'),
    products: yup.mixed().required('Must select atleast one product'),
  }),
];
