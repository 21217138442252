import { Cancel, Check, ContentCopy, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { GridRowModes, GridToolbar } from '@mui/x-data-grid';
import React from 'react';
import BaseTable from '../global/BaseTable';
import CustomTooltip from '../global/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { feedbackActions } from '../../store/slice/feedbackReducer';
import { addShopsToProduct, updateProductInfo, updateProductShopInfo } from '../../api/product';
import NoDataOverlay from '../global/NoDataOverlay';
import SubmitButton from '../global/SubmitButton';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { fetchCompanyShops } from '../../api/shop';
import { yupResolver } from '@hookform/resolvers/yup';
import { masterlistShopAddSchema } from '../../validation/masterlist';
import { convertCamelCaseToWord } from '../../utils/word';

const RenderShopInfo = ({ shops, productInfo, toggleDrawer }) => {
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [dataPatchPromise, setDataPatchPromise] = React.useState(null);
  const [allShops, setAllShops] = React.useState([]);
  const company = useSelector((state) => state.company.selectedCompany);

  React.useEffect(() => {
    (async () => {
      const allCompanyShops = await fetchCompanyShops({
        companyUid: company.uid,
      });
      const usedShopscode = shops.map((item) => item.shopCode);

      const filteredShopList = allCompanyShops.filter(
        (item) => !usedShopscode.includes(item.shopCode),
      );
      setAllShops(filteredShopList);
    })();
  }, [shops]);

  const dispatch = useDispatch();

  const columns = [
    {
      field: 'name',
      headerName: 'Shop Name',
      width: 200,
      renderCell: (params) => {
        return `${params.value} (${params.row.shopCode})`;
      },
    },
    {
      field: 'region',
      headerName: 'Region',
      width: 70,
    },
    {
      field: 'sku',
      headerName: 'SKU',
      editable: true,
      width: 120,
    },
    {
      field: 'link',
      headerName: 'Link',
      width: 300,
      editable: true,
      renderCell: (params) => (
        <Link href={params.value} target="_blank">
          {params.value}
        </Link>
      ),
    },
    {
      field: 'link-copy',
      headerName: 'Copy Link',
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              navigator.clipboard
                .writeText(params.row?.link)
                .then(() => {
                  dispatch(
                    feedbackActions.NOTIFY({
                      status: 'success',
                      message: 'Link coppied',
                    }),
                  );
                })
                .catch(() => {
                  alert('something went wrong');
                });
            }}>
            <ContentCopy />
          </IconButton>
        );
      },
    },
  ];

  const updateRow = React.useCallback(
    (newValue, oldValue) =>
      new Promise((resolve, reject) => {
        if (newValue !== oldValue) {
          setDataPatchPromise({ resolve, reject, newValue, oldValue });
        } else {
          resolve(oldValue);
        }
      }),
    [],
  );

  const handleDataPatchNoPress = async () => {
    const { oldValue, resolve } = dataPatchPromise;

    resolve(oldValue);
    setDataPatchPromise(null);
  };

  const handleDataPatchYesPress = async () => {
    const { newValue, oldValue, reject, resolve } = dataPatchPromise;
    const response = await updateProductShopInfo({
      ...newValue,
      ...productInfo,
    });
    if (response) {
      resolve({ ...newValue, ...productInfo, updatedAt: new Date() });
      setDataPatchPromise(null);
    } else {
      reject(oldValue);
      setDataPatchPromise(null);
    }
  };

  const resolver = yupResolver(masterlistShopAddSchema);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { shops: [] },
    mode: 'onChange',
    resolver,
  });
  const { append, remove, fields } = useFieldArray({ control, name: 'shops' });

  const newShops = useWatch({ control, name: 'shops' });

  const addShop = async (data) => {
    const { companyUid, productCode } = productInfo;
    const response = await addShopsToProduct(companyUid, productCode, data.shops);
    if (!response) {
      dispatch(
        feedbackActions.NOTIFY({
          status: 'error',
          message: 'Failed to add shops',
        }),
      );
    } else {
      dispatch(feedbackActions.NOTIFY({ status: 'success', message: 'Shops add' }));
      toggleDrawer(false);
    }
  };

  return (
    <>
      <Dialog open={!!dataPatchPromise}>
        <DialogTitle>Are You sure?</DialogTitle>
        <DialogContent>Are you sure you want save changes?</DialogContent>
        <DialogActions>
          <Button onClick={handleDataPatchNoPress}>No</Button>
          <SubmitButton onClick={handleDataPatchYesPress} variant="contained">
            YES
          </SubmitButton>
        </DialogActions>
      </Dialog>

      <Box width="100%" height="450px">
        <BaseTable
          columns={columns}
          rows={shops}
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={updateRow}
          onProcessRowUpdateError={() => {
            dispatch(
              feedbackActions.NOTIFY({
                status: 'error',
                message: 'Failed to update product info',
              }),
            );
          }}
          rowModesModel={rowModesModel}
          onRowEditStart={(e) => {
            setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.Edit });
          }}
          onRowEditStop={(e) => {
            setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.View });
          }}
          onRowEditCommit={(e) => {
            setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.View });
          }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              bgcolor: colors.grey[400],
            },
          }}
        />
      </Box>

      <Box my="20px" boxSizing="border-box" px="20px" py="20px">
        {fields.map((field, index) => (
          <Stack
            direction="row"
            key={index}
            width="100%"
            sx={{ mb: '15px' }}
            spacing={2}
            alignItems="center">
            <FormControl required sx={{ flexGrow: 1 }} error={!!errors?.shops?.[index]?.shopCode}>
              <InputLabel>Select Shop</InputLabel>
              <Select {...register(`shops.${index}.shopCode`)} label="Select Shop">
                {allShops?.length > 0 ? (
                  allShops.map((shop) => (
                    <MenuItem key={shop.shopCode} value={shop.shopCode}>
                      {shop.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>No option to select</MenuItem>
                )}
              </Select>
              {errors?.shops?.[index]?.shopCode && (
                <FormHelperText>{errors?.shops?.[0]?.shopCode?.message}</FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ flexGrow: 1 }} required error={!!errors?.shops?.[index]?.sku}>
              <InputLabel>SKU</InputLabel>
              <OutlinedInput
                {...register(`shops.${index}.sku`)}
                label="SKU"
                placeholder="Please enter product sku, ie - 15641"
              />
              {errors?.shops?.[index]?.sku && (
                <FormHelperText>{errors?.shops?.[index]?.sku?.message}</FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ flexGrow: 1 }}>
              <InputLabel>Link</InputLabel>
              <OutlinedInput
                {...register(`shops.${index}.link`)}
                label="Link"
                placeholder="Please enter product link"
              />
              <FormHelperText></FormHelperText>
            </FormControl>
            <IconButton sx={{ height: 'max-content' }} onClick={() => remove(index)}>
              <Delete />
            </IconButton>
          </Stack>
        ))}
        {errors?.shops && (
          <Typography fontSize="12px" fontWeight="bold" color={colors.red[300]}>
            {errors?.shops?.message}
          </Typography>
        )}
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button variant="outlined" onClick={() => append({ shopCode: '', sku: '', link: '' })}>
            Add new shop
          </Button>
          <Button
            disabled={newShops?.length <= 0}
            variant="contained"
            onClick={handleSubmit(addShop)}>
            Save
          </Button>
        </Stack>
      </Box>
    </>
  );
};

const MetaInfoTable = ({ productInfo }) => {
  const columns = [
    {
      field: 'key',
      headerName: 'Field Name',
      width: 200,
      renderCell: (params) => `${convertCamelCaseToWord(params.value)}`,
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1,
      editable: true,
    },
  ];
  const rows = () => {
    const keys = Object.keys(productInfo.meta);
    const valuePair = [];
    keys.map((item, index) =>
      valuePair.push({ id: index, key: item, value: productInfo.meta[item] }),
    );
    return valuePair;
  };
  return (
    <>
      <BaseTable
        columns={columns}
        rows={rows()}
        components={{ Pagination: null }}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            bgcolor: colors.grey[400],
          },
        }}
      />
      <Box boxSizing="border-box" px="15px" py="15px">
        <Stack
          width="100%"
          justifyContent="flex-end"
          sx={{ my: '15px' }}
          direction="row"
          spacing={2}>
          <Button variant="outlined" disabled>
            Add
          </Button>
          <Button variant="contained" disabled>
            Save
          </Button>
        </Stack>
      </Box>
    </>
  );
};

const AllProductTable = ({ products, loading }) => {
  const [showShopsDrawer, setShowShopsDrawer] = React.useState(false);
  const [selectedShop, setSelectedShop] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(20);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [dataPatchPromise, setDataPatchPromise] = React.useState(null);
  const [selectedProductInfo, setSelectedProductInfo] = React.useState(null);
  const [showMetaInfo, setShowMetaInfo] = React.useState(false);

  const dispatch = useDispatch();

  const columns = () => {
    const items = [
      {
        field: 'productCode',
        headerName: 'Product Code',
        width: 130,
      },
      {
        field: 'brandCode',
        headerName: 'Brand Code',
        width: 130,
      },
      {
        field: 'name',
        headerName: 'Product Name',
        width: 400,
        editable: true,
        renderCell: (params) => (
          <CustomTooltip title={params.value}>
            <Typography>{params.value.substring(0, 40)}</Typography>
          </CustomTooltip>
        ),
      },
      {
        field: 'variant',
        headerName: 'Variant',
        width: 100,
        editable: true,
      },
      {
        field: 'brand',
        headerName: 'Brand',
        width: 100,
        editable: true,
      },
      {
        field: 'businessUnit',
        headerName: 'Business Unit',
        width: 150,
        editable: true,
      },
      {
        field: 'category',
        headerName: 'Category',
        width: 150,
        editable: true,
      },
      {
        field: 'stockQuantity',
        headerName: 'Distributor Stock Quantity',
        width: 150,
        type: 'number',
        editable: true,
      },
      {
        field: 'size',
        headerName: 'Size',
        width: 80,
        editable: true,
      },
      {
        field: 'packageQuantity',
        headerName: 'Package Quantity',
        width: 140,
        editable: true,
      },
      {
        field: 'packageType',
        headerName: 'Package Type',
        width: 150,
        editable: true,
        type: 'singleSelect',
        valueOptions: ['BUNDLE', 'SINGLE', 'CARTOON'],
      },
      {
        field: 'listingStatus',
        headerName: 'Listing Status',
        width: 150,
        type: 'singleSelect',
        valueOptions: ['LISTED', 'DELISTED'],
        editable: true,
        renderCell: (params) => {
          if (params.value === 'LISTED') {
            return <Chip label="Listed" color="success" icon={<Check />} size="small" />;
          } else {
            return <Chip label="Delisted" color="error" icon={<Cancel />} size="small" />;
          }
        },
      },
      {
        editable: false,
        selectable: false,
        field: 'shopInfo',
        headerName: 'Shops',
        renderCell: (params) => (
          <Button
            onClick={() => {
              setShowShopsDrawer(true);
              setSelectedShop(params.value);
              setSelectedProductInfo({
                companyUid: params.row.companyUid,
                productCode: params.row.productCode,
              });
            }}>
            Shops ({params.value?.length})
          </Button>
        ),
      },
      {
        field: 'meta',
        headerName: 'Other Info',
        width: 200,
        renderCell: (params) => {
          if (Object.keys(params.row?.meta).length > 0) {
            return (
              <Button
                onClick={() => {
                  setSelectedProductInfo(params.row);
                  setShowMetaInfo(true);
                }}>
                View
              </Button>
            );
          } else {
            return <></>;
          }
        },
      },
    ];

    return items;
  };

  const checkDif = (oldValue, newValue) =>
    oldValue.name !== newValue.name ||
    oldValue.brand !== newValue.brand ||
    oldValue.businessUnit !== newValue.businessUnit ||
    oldValue.variant !== newValue.variant ||
    oldValue.category !== newValue.category ||
    oldValue.stockQuantity !== newValue.stockQuantity ||
    oldValue.size !== newValue.size ||
    oldValue.packageQuantity !== newValue.packageQuantity ||
    oldValue.packageType !== newValue.packageType ||
    oldValue.listingStatus !== newValue.listingStatus;

  const validate = (item) =>
    item.name.length > 0 &&
    item.brand.length > 0 &&
    item.businessUnit.length > 0 &&
    item.category.length > 0 &&
    !isNaN(parseFloat(item.stockQuantity)) &&
    !isNaN(parseFloat(item.packageQuantity));

  const updateRow = React.useCallback(
    (newValue, oldValue) =>
      new Promise((resolve, reject) => {
        if (checkDif(oldValue, newValue) && validate(newValue)) {
          setDataPatchPromise({ resolve, reject, newValue, oldValue });
        } else {
          resolve(oldValue);
        }
      }),
    [],
  );

  const handleDataPatchNoPress = async () => {
    const { oldValue, resolve } = dataPatchPromise;

    resolve(oldValue);
    setDataPatchPromise(null);
  };

  const handleDataPatchYesPress = async () => {
    const { newValue, oldValue, reject, resolve } = dataPatchPromise;
    const response = await updateProductInfo(newValue);
    if (response) {
      resolve({ ...newValue, updatedAt: new Date() });
      setDataPatchPromise(null);
      dispatch(feedbackActions.NOTIFY({ status: 'success', message: 'Changes saved' }));
    } else {
      reject(oldValue);
      setDataPatchPromise(null);
    }
  };

  return (
    <Box width="100%" height="500px" minHeight="60vh">
      <Dialog open={!!dataPatchPromise}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>Are your sure want save your changes?</DialogContent>
        <DialogActions>
          <Button onClick={handleDataPatchNoPress}>No</Button>
          <SubmitButton onClick={handleDataPatchYesPress} variant="contained" autoFocus>
            YES
          </SubmitButton>
        </DialogActions>
      </Dialog>
      {/* SHOP DRAWER */}
      <Drawer
        open={showShopsDrawer}
        onClose={() => {
          setShowShopsDrawer(false);
          setSelectedShop(null);
          setSelectedProductInfo(null);
        }}
        variant="temporary"
        anchor="right">
        <Box
          width="max(60vw, 290px)"
          height="100%"
          bgcolor="white"
          zIndex={500}
          boxSizing="border-box">
          {selectedShop && (
            <RenderShopInfo
              shops={selectedShop}
              productInfo={selectedProductInfo}
              toggleDrawer={setShowShopsDrawer}
            />
          )}
        </Box>
      </Drawer>

      {/* META INFO DRAWER */}

      <Drawer
        open={showMetaInfo}
        anchor="right"
        onClose={() => {
          setSelectedProductInfo(null);
          setShowMetaInfo(false);
        }}>
        <Box
          width="max(50vw, 290px)"
          height="100%"
          bgcolor="white"
          zIndex={500}
          boxSizing="border-box"
          borderRadius="10px"
          overflow="hidden">
          <Box width="100%" height="350px">
            {selectedProductInfo && selectedProductInfo?.meta && (
              <MetaInfoTable productInfo={selectedProductInfo} />
            )}
          </Box>
        </Box>
      </Drawer>
      <BaseTable
        columns={columns()}
        rows={products}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: NoDataOverlay,
          NoResultsOverlay: NoDataOverlay,
        }}
        density="compact"
        page={page}
        pageSize={pageSize}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        rowsPerPageOptions={[10, 20, 35, 45, 75, 100]}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={updateRow}
        onProcessRowUpdateError={() => {
          dispatch(
            feedbackActions.NOTIFY({
              status: 'error',
              message: 'Failed to update product info',
            }),
          );
        }}
        rowModesModel={rowModesModel}
        onRowEditStart={(e) => {
          setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.Edit });
        }}
        onRowEditStop={(e) => {
          setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.View });
        }}
        onRowEditCommit={(e) => {
          setRowModesModel({ ...rowModesModel, [e.id]: GridRowModes.View });
        }}
        loading={loading}
      />
    </Box>
  );
};

export default AllProductTable;
