import { Box, Stack } from '@mui/material';
import { GridToolbar, GridToolbarQuickFilter } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import BaseTable from './BaseTable';
import NoDataOverlay from './NoDataOverlay';

import CustomTooltip from './Tooltip';

const CustomToolBar = ({ searchFieldLabel, queryCallback, quickSearchParamName }) => {
  const quickSearch = async (value) => {
    if (value?.length > 2) {
      await queryCallback({ [quickSearchParamName]: value });
    } else if (!value || value?.length === 0) {
      await queryCallback();
    }
  };
  return (
    <Box width="100%" py="15px" px="10px">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CustomTooltip title={searchFieldLabel}>
          <GridToolbarQuickFilter
            variant="outlined"
            placeholder={searchFieldLabel}
            quickFilterParser={quickSearch}
            debounceMs={200}
          />
        </CustomTooltip>
        <GridToolbar />
      </Stack>
    </Box>
  );
};
const Table = ({
  columns,
  rows,
  total,
  queryCallback,
  loading,
  searchFieldLabel,
  quickSearchParamName,
  rowModesModel,
  onRowEditStop,
  processRowUpdate,
  checkboxSelection,
  ...rest
}) => {
  const [page, setPage] = useState(undefined);
  const [pageSize, setPageSize] = useState(10);

  // Update data on page or page size change
  useEffect(() => {
    (async () => {
      await queryCallback({ page, pageSize });
    })();
  }, [page, pageSize]);

  const filterData = React.useCallback((model) => {
    (async () => {
      const { items } = model;
      if (items.length > 0) {
        const { columnField, value } = items[0];

        if (value.length > 2) {
          await queryCallback({ page, pageSize, [columnField]: value });
        } else if (!value || value?.length === 0) {
          await queryCallback();
        }
      }
    })();
  }, []);

  return (
    <Box display="flex" height="550px" bgcolor="#fff" borderRadius="10px">
      <Box flexGrow={1}>
        <BaseTable
          checkboxSelection={checkboxSelection}
          rows={rows}
          columns={columns}
          components={{
            Toolbar: CustomToolBar,
            NoRowsOverlay: NoDataOverlay,
            NoResultsOverlay: NoDataOverlay,
          }}
          rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          pageSize={pageSize}
          page={page}
          paginationMode="server"
          filterMode="server"
          onFilterModelChange={filterData}
          rowCount={total ? total : 0}
          loading={loading}
          componentsProps={{
            toolbar: {
              searchFieldLabel: searchFieldLabel,
              queryCallback,
              quickSearchParamName,
            },
          }}
          editMode="row"
          experimentalFeatures={{ newEditingApi: true }}
          rowModesModel={rowModesModel}
          onRowEditStop={onRowEditStop}
          processRowUpdate={processRowUpdate}
          {...rest}
        />
      </Box>
    </Box>
  );
};

export default Table;
