import { Breadcrumbs, Divider, Typography } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import { colors } from '../../constants/colors';
import linkMapping from '../../constants/linkMapping';

const Shortcuts = () => {
  const location = useLocation();
  const paths = location.pathname.split('/').filter((x) => x);

  if (location.pathname === '/dashboard') {
    return <></>;
  } else {
    return (
      <Box>
        <Breadcrumbs color={colors.black} separator="/">
          {paths.map((path, index) => {
            const last = index === paths.length - 1;
            const to = `/${paths.slice(0, index + 1).join('/')}`;
            return last ? (
              <Typography
                fontWeight="bold"
                fontSize="14px"
                color={colors.black}
                key={to}
                component={Link}
                to={to}
                style={{ textDecoration: 'none' }}>
                {linkMapping[to]}
              </Typography>
            ) : (
              <Typography
                fontWeight="bold"
                fontSize="14px"
                underline="hover"
                color="gray"
                to={to}
                key={to}
                component={Link}
                style={{ textDecoration: 'none' }}>
                {linkMapping[to]}
              </Typography>
            );
          })}
        </Breadcrumbs>
        <Typography color={colors.black} fontSize="22px" fontWeight="bold">
          {linkMapping[location.pathname]}
        </Typography>
        <Box my="5px" />
        <Divider variant="fullWidth" />
      </Box>
    );
  }
};

export default Shortcuts;
