import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addSchedule } from '../../api/schedules';
import { feedbackActions } from '../../store/slice/feedbackReducer';
import BaseTable from '../global/BaseTable';
import { useNavigate } from 'react-router-dom';
import { SCHEDULER } from '../../constants/routerUrls';

const VerifyInformation = ({ toggleStep, submit, control }) => {
  const [showScheduleCreationDialog, setShowScheduleCreationDialog] = React.useState(false);
  const company = useSelector((state) => state.company.selectedCompany);
  const navigate = useNavigate();

  const shop = useWatch({ control, name: 'shop' });
  const products = useWatch({ control, name: 'products' });
  const title = useWatch({ control, name: 'title' });
  const description = useWatch({ control, name: 'description' });
  const slot = useWatch({ control, name: 'slot' });
  const keywords = useWatch({ control, name: 'keywords' });
  const darkStores = useWatch({ control, name: 'darkStores' });

  const dispatch = useDispatch();

  const addNewSchedule = async (data) => {
    const { slot, shop, title, description, products } = data;

    const response = await addSchedule({
      slot,
      shop,
      title,
      description,
      products,
      companyUid: company.uid,
      keywords,
      darkStores,
    });

    if (!response) {
      dispatch(feedbackActions.NOTIFY({ status: 'error', message: 'Failed to add schedule' }));
      setShowScheduleCreationDialog(false);
    } else {
      dispatch(feedbackActions.NOTIFY({ status: 'success', message: 'Schedule saved' }));
      navigate(SCHEDULER);
      setShowScheduleCreationDialog(false);
    }
  };

  const handleNext = () => {
    setShowScheduleCreationDialog(true);
  };

  const slots = [
    {
      label: 'Morning 7:00 AM - 1:00 PM',
      value: 'MORNING',
    },
    {
      label: 'Evening 2:00 PM - 10:00 PM',
      value: 'EVENING',
    },
    {
      label: 'Night 11:00 PM - 6:00 AM',
      value: 'NIGHT',
    },
  ];

  const columns = [
    {
      field: 'productCode',
      headerName: 'Product Code',
      width: 120,
    },
    {
      field: 'name',
      headerName: 'Product Name',
      flex: 1,
    },
    {
      field: 'sku',
      flex: 1,
      headerName: 'SKU',
    },
    {
      field: 'link',
      headerName: 'Link',
      flex: 1,
    },
  ];

  return (
    <Box>
      <Dialog
        open={showScheduleCreationDialog}
        onClose={() => setShowScheduleCreationDialog(false)}>
        <DialogTitle>Are You Sure?</DialogTitle>
        <DialogContent>Are you sure you want to add &quot;{title}&quot; ?</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowScheduleCreationDialog(false);
            }}>
            NO
          </Button>
          <Button variant="contained" onClick={submit(addNewSchedule)}>
            YES
          </Button>
        </DialogActions>
      </Dialog>
      <Stack spacing={2}>
        <Card>
          <CardContent>
            <Stack direction="row" flexWrap="wrap" spacing={2} width="100%">
              <Typography fontSize="20px" fontWeight="bold">
                {title}
              </Typography>
              <Chip label={slots.find((item) => item.value === slot).label} />
              <Chip label={`${shop.name} - ${shop.shopCode}`} />
            </Stack>
          </CardContent>
        </Card>
        {description?.length > 0 && (
          <Card>
            <CardContent>
              <Typography>{description}</Typography>
            </CardContent>
          </Card>
        )}

        {keywords && keywords?.length > 0 && (
          <Card>
            <CardContent>
              <Stack spacing={3}>
                <Typography fontSize="20px" fontWeight="bold" sx={{ width: 'max-content' }}>
                  Selected Keywords
                </Typography>
                <Stack flexWrap="wrap" direction="row">
                  {keywords?.map((item) => (
                    <Chip
                      key={item.uid}
                      label={item.keyword}
                      color="info"
                      size="small"
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )}

        {darkStores && darkStores?.length > 0 && (
          <Card>
            <CardContent>
              <Stack spacing={3}>
                <Typography fontSize="20px" fontWeight="bold" sx={{ width: 'max-content' }}>
                  Selected Stores
                </Typography>
                <Stack flexWrap="wrap" direction="row">
                  {darkStores?.map((item) => (
                    <Chip
                      key={item.uid}
                      label={item.name}
                      color="info"
                      size="small"
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )}
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography fontSize="20px" fontWeight="bold" sx={{ width: 'max-content' }}>
                  Selected Products
                </Typography>
                <Chip label={products?.length} size="small" color="warning" />
              </Stack>
              <Box height="350px">
                <BaseTable
                  columns={columns}
                  rows={products}
                  disableSelectionOnClick
                  components={{ Pagination: null }}
                />
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
        <Button onClick={() => toggleStep(1)} variant="contained">
          Previous
        </Button>
        <Button onClick={handleNext} variant="contained">
          Save Schedule
        </Button>
      </Stack>
    </Box>
  );
};

export default VerifyInformation;
